export const getNumberFormatSeperator = (type) => {
    return (type === "number-format" && {
        thousandsGroupStyle: "thousand",
        thousandSeparator: ',',
        decimalSeparator: '.'
    }) || {};
};

export const updateObject = (originalObj, updatedObj, keysToCheck = []) => {
    return keysToCheck?.reduce((result, key) => {
        if (Array.isArray(key)) {
            result[key[1]] = updatedObj[key[0]];
        } else {
            result[key] = updatedObj[key];
        }
        return result;
    }, originalObj);
}

export const generateKeyValuePair = (rows = [], key, value) => {
    return rows?.reduce((result, element) => { result[element?.[key]] = value ? (element?.[value] || "") : element || {}; return result; }, {});
};