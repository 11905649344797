import { useState, useEffect } from "react";
import React from "react";
import { from, useApolloClient } from '@apollo/client';
import { toast } from "react-toastify";
import Loader from "../elements/Loader";
import Formsy, { addValidationRule } from "formsy-react";
import FormsyInputField from "../elements/FormsyInputField";
import { executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import { RAISE_DISPUTE_QUERY } from "../../services/leads.gql";
import { useDispatch } from "react-redux";
import { getLoanDetail } from "../../store/action/allAction";
import { MARK_AS_DISPUTE_FORM_FIELDS } from "./formFields/MarkAsDisputeFormFields.json";

const MarkDispute = ({ leadDetail, tranch_id, payment_type, UserInfo, setShowDisputeCreate, amount_paid }) => {
    addValidationRule("lessThanAmount", (values, value) => {
        if (value >= dispute?.amount) {
            return `Amount should be less than ${dispute?.amount}!`;
        }else if (1 > value) {
            return `Amount should be greater than 0!`;
        }
        return true;
    });
    const [loading, setLoading] = useState(false)
    const client = useApolloClient()
    const [allowSubmit, setAllowSubmit] = useState(true);
    const [dispute, setDispute] = useState({
        amount: Number(amount_paid),
        amount_paid: 0,
        amount_recovered: 0,
        amount_to_be_recovered: 0,
        remarks: ''
    })

    const dispatch = useDispatch()

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name === 'amount_paid' && data.value !== undefined) {
            setDispute((currentValue) => ({
                ...currentValue,
                [field_name]: data.value,
                amount_to_be_recovered: dispute.amount - data.value
            }));
           
        }
    };

    const handleChange = (event) => {
        if (event.target.name) {
            setDispute((currentValue) => ({
                ...currentValue,
                [event.target.name]: event.target.value,
            }));
        }

    };

    const createDispute = async()=>{
        setLoading(true)
        let variables = {
            user_id: UserInfo?.id,
            createDisputeInput:{
                lead_id: leadDetail?.id,
                dealer_id: leadDetail?.dealer_id,
                tranch_id,
                payment_type,
                amount: dispute?.amount.toString(),
                amount_paid: dispute?.amount_paid.toString(),
                amount_recovered: dispute?.amount_recovered.toString(),
                amount_to_be_recovered: dispute?.amount_to_be_recovered.toString(),
                remarks: dispute?.remarks
            }
        }
        executeGraphQLMutation(RAISE_DISPUTE_QUERY, variables, client)
        .then((res)=>{
            if(res?.data?.raise_payment_dispute){
                toast.success(res.data.raise_payment_dispute.message)
            }
            dispatch(getLoanDetail(leadDetail?.id,client))
            setShowDisputeCreate(false)
        })
        .catch(e => {
            toast.error(e)
        })
        .finally(()=>{
            setLoading(false)
        })
    }

    return (
        <>
            {loading && <Loader />}
            <Formsy
                className="lead-form-filed"
                autoComplete="off"
                onValid={() => setAllowSubmit(true)}
                onInvalid={() => setAllowSubmit(false)}
                aria-label="lead form field basic"
                onSubmit={() => createDispute()}
            >
                 <div className='row'>
                    {
                        MARK_AS_DISPUTE_FORM_FIELDS?.map((field, index) => {
                            if (["number-format"].includes(field?.type)) {
                                return (<>
                                        <fieldset className="form-filed col-md-6 p-lg-b">
                                            <div className="material">
                                                <FormsyInputField
                                                    id={field?.id}
                                                    name={field?.name}
                                                    type="number-format"
                                                    placeholder=""
                                                    className="form-input"
                                                    label={field?.label}
                                                    value={dispute?.[field?.name]}
                                                    onChange={(data) => handlePatternFormatChange(data, field?.name)}
                                                    required={field?.required}
                                                    disabled={field?.disabled}
                                                    validations={field?.validations || null}
                                                    validationError={
                                                        dispute?.[field?.name] ? field?.validationError : ""
                                                    }
                                                />
                                            </div>
                                        </fieldset>
                                    </>
                                );
                            }
                            else if (["text"].includes(field?.type)) {
                                return (
                                    <fieldset className="form-filed col-md-12">
                                        <div className="material">
                                            <textarea
                                                id={field?.id}
                                                name={field?.name}
                                                value={dispute?.[field?.name]}
                                                onChange={handleChange}
                                                className="form-input"
                                                placeholder=" "
                                            />
                                            <label data-label="Remarks" className="form-label"></label>
                                        </div>
                                    </fieldset>);
                            }
                        })
                    }
                </div>

                <div className="btn-save-remarks p-lg-t">
                    {!allowSubmit ? (
                        <span className="span-disabled">Submit</span>
                    ) : (
                        <button aria-label="save naxt" type="submit" className="btn-primary">
                            Submit
                        </button>
                    )}
                </div>
            </Formsy>
        </>
    );
}

export default MarkDispute