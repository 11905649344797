import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DocumentGallery from './DocumentGallery';
import Formsy from 'formsy-react';
import FormsyInputField from '../elements/FormsyInputField';
import FormsyDatePicker from '../elements/FormsyDatePicker';
import DealerFinancierPaymentExchangeJson from './formFields/dealerFinancierPaymentExchange.json';
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import Modal from '../elements/Modal';
import { useOutletContext } from 'react-router-dom';
import { gql, useApolloClient } from '@apollo/client';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { getLoanDetail } from '../../store/action/allAction';
import { GET_DISPUTE_DETAILS, LEADS_REMARK_QUERY_FOR_RECEIVED_AMT } from '../../services/leads.gql';
import dateFormat from "dateformat";
import { DISPUTE_STATUS, getKeysData, PAYMENT_TYPE_LABEL, PAYMENT_TYPES } from '../../config/constants';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MarkDispute from './MarkDispute';
import { UPDATE_PAYMENT_DISPUTE } from '../../services/dispute.gql';


const now = new Date();
const currhour = Number(now.getHours());
const currmin = now.getMinutes();
const DealerFinancierPaymentExchange = (props) => {
    const client = useApolloClient();
    let tab=props.tab;
    //is_completed=false;
    const dispatch = useDispatch();
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const [dealerFinancierExchangeData, setDealerFinancierExchangeData] = useState({});
    const [dateSelected, setDateSelected] = useState(true);
    let [ maxTime, setMaxTime ] = useState(now.setHours(currhour, currmin, 0));
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information)
    const formFields = DealerFinancierPaymentExchangeJson[tab];
    let current_page_fields = formFields.form_fields || [];
    const [showDisputeCreate, setShowDisputeCreate] = useState(false);
    const [showMarkDispute, setShowMarkDispute] = useState(false);

    const { leadDetail, remarksHistory, userDetail } = useSelector(({ lead, user }) => {
        return {
            leadDetail: lead.leadDetail,
            remarksHistory: lead.remarksHistory || [],
            userDetail: (user?.user_information && JSON.parse(user?.user_information)) || {},
        };
    });
    let [remarks, setRemarks] = useState([]);
    let [remarksToShow, setRemarksToShow] = useState([]);

    const [disputeDetails, setDisputeDetails] = useState({})
    const [initialDisputeDetails, setInitialDisputeDetails] = useState({})
    
    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || [];
    let form_read_only = (leadDetail?.lead_status_dates?.main_dealer_paid && ['main_dealer_payment'].includes(tab)) || false;
    let onhold_read_only = (leadDetail?.lead_status_dates?.onhold_dealer_paid && ['onhold_dealer_payment'].includes(tab)) || false;
    let split1_paid = (leadDetail?.lead_status_dates?.split1_paid && ['split_dealer1_payment'].includes(tab)) || false;
    let split2_paid = (leadDetail?.lead_status_dates?.split2_paid && ['split_dealer2_payment'].includes(tab)) || false;
    
    let mainDealerFlag = (leadDetail?.lead_computation_details?.adp_type === "Yes" && true) || leadDetail?.lead_computation_details?.loan_amount_pass_through === 'yes' ? false : true;
    let onHoldDealerFlag = true;
    let splitPaymentFlag = leadDetail?.lead_computation_details?.di_amount_pass_through === 'yes' ? false : true;
    let showSplit2Date = !mainDealerFlag ? (!!leadDetail?.split_payment?.filter(data => data.payment_type === 'split_dealer2' || data.payment_type === 'split_dealer')?.length || leadDetail?.lead_computation_details?.dealer_incentive_net_of_tax === 0 ): leadDetail?.lead_computation_details?.loan_amount_pass_through === 'yes' ? false : true;
    useEffect(() => {
        const fetchData = async () => {
            await getDealerFinancierExchangeData(leadDetail);
            if (tab === 'dealer_incentive_recieved' || tab === 'loan_value_recieved') await getLeadRemarks(leadDetail)
        };
        fetchData();
        setDateSelected(true)
    }, [leadDetail,tab]);


    // if(tab==='loan_value_recieved'){
    //     is_completed=leadDetail?.tab_status?.payment_received_from_financier?.laon_value_recieved?.is_completed;
    // }else if(tab==='paid_to_dealer'){
    //     is_completed=leadDetail?.tab_status?.payment_to_dealer?.is_completed;
    // }else if(tab==='dealer_incentive_recieved'){
    //     is_completed=leadDetail?.tab_status?.payment_received_from_financier?.dealer_incentive_received?.is_completed;
    // }


    const getDealerFinancierExchangeData = (data) => {
        const { amount_paid, payment_type } = getTranchIdAndPaymentType();
        let lead_detail_data = { amount: amount_paid || 0, payment_type };
        current_page_fields.forEach(obj => {
            if(obj.type==="date-picker" && data.lead_status_dates && data.lead_status_dates.hasOwnProperty(obj.name))
            {
                lead_detail_data = {
                    ...lead_detail_data,
                    [obj.name]: data.lead_status_dates && data.lead_status_dates[obj.name] ? new Date(data.lead_status_dates && data.lead_status_dates[obj.name]) : null
                }
            }
            else if (data.lead_details && data.lead_details.hasOwnProperty(obj.name)) {
                lead_detail_data = {
                    ...lead_detail_data,
                    [obj.name]: data.lead_details && data.lead_details[obj.name]
                }
            }
        })
        setDealerFinancierExchangeData(lead_detail_data);
    }
    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add("overflow-hidden");
      };
    
      const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove("overflow-hidden");
      };
    let sectionInfo = {section : "" , sub_section: ""}

    if(tab === 'dealer_incentive_recieved' || tab === 'loan_value_recieved'){
        sectionInfo = {section : "Payment Recieved From Financier" , sub_section: props.name}
    }else{
        sectionInfo = {section : props.name , sub_section: ""}
    }

    const getLeadRemarks = async (leadDetail) => {
        try {
            let variables = { lead_id: leadDetail?.id, remark_type: [3, 4] }
            executeGraphQLQuery(LEADS_REMARK_QUERY_FOR_RECEIVED_AMT, client, variables).then((response) => {
                if (response && response.data && response.data.get_remarks) {
                    let data = response.data.get_remarks;
                    if (data?.length) {
                        setRemarks(data);
                        setRemarksToShow([data?.[0]]);
                    }
                }
            })
        } catch (err) {
            console.log(err)
        }
    }
    const showMoreLessRemarks = async (action) => {
        if(action === "more") setRemarksToShow([...remarks]);
        if(action === "less") remarks?.length && setRemarksToShow([remarks?.[0]]);
    }

    const handleDateChange = (target_name, field_type, event) => {
        if(field_type === "date-picker" ){
            if(compareDate(event)) setMaxTime(now.setHours(23, 59, 59))
            else setMaxTime(now.setHours(currhour, currmin, 0))
            event ? setDateSelected(false) : setDateSelected(true);
            setDealerFinancierExchangeData(currentValue => ({
                ...currentValue,
                [target_name]: event
            }))
        } 
    }

    function compareDate(selectedDate) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        let d = new Date(selectedDate); 
        d.setHours(0, 0, 0, 0);
        if(d < today){ 
            return true;
        }
        return false;
    }

    const handleSubmit = async() => {
        let mutation = gql`
        mutation paidtodelaer($paidtodealer: [PaidtoDealer!]!) {
            savepaidtodealer(paidtodealer: $paidtodealer) {
                successids
                failedids
            }
        }
        `;
        let variables ={ paidtodealer:[{
            lead_id:leadDetail?.id,
            main_dealer_paid: !mainDealerFlag ? dealerFinancierExchangeData?.main_dealer_paid : null,
            split1_paid: !splitPaymentFlag ? dealerFinancierExchangeData?.split1_paid : null,
            split2_paid: !splitPaymentFlag ? dealerFinancierExchangeData?.split2_paid : null,
            user_id:UserInfo?.id,
            updated_by_source: 'web'
        }]}
        try {
            await executeGraphQLMutation(mutation, variables, client);
            dispatch(getLoanDetail(leadDetail?.id,client))
            toast.success("Success");
        } catch (error) {
            toast.error("Error");
        }
    }

    const get_dispute_details = () =>{
        let { tranch_id, payment_type } = getTranchIdAndPaymentType();
        if (!tranch_id) {
            setShowMarkDispute(false);
            return;
        }
        let varaibles = {
            tranch_id: tranch_id
        }
        executeGraphQLMutation(GET_DISPUTE_DETAILS, varaibles, client)
        .then((res)=> {
            if(res?.data?.get_dispute_details){
                setDisputeDetails({
                    ...disputeDetails,
                    ...getKeysData(res?.data?.get_dispute_details, [ "id", "amount", "amount_paid", "amount_to_be_recovered", "amount_recovered", "dispute_status", "recovery", "remarks"])
                })
                setInitialDisputeDetails({
                    ...getKeysData(res?.data?.get_dispute_details, [ "id", "amount", "amount_paid", "amount_to_be_recovered", "amount_recovered", "dispute_status", "recovery", "remarks"])
                })
                setShowMarkDispute(false);
            }
        }).catch((e) => {
            const tranchData = leadDetail?.split_payment?.find(item => item?.payment_type === payment_type);
            if(tranchData?.tranche_status === "paid"){
                setShowMarkDispute(true)
            }
            else {
                setShowMarkDispute(false)
            }
        })
    }

    const updatePaymentDispute = () => {
        let varaibles = {    
            user_id: userDetail?.id || 0,
            role_id: userDetail?.role_id?.[0] || 0,
            updateDisputeInput: {
                dispute_id: disputeDetails?.id,
                amount_paid: +disputeDetails?.amount_paid || 0,
                remarks: disputeDetails?.remarks || ""
            }
        }
        props?.setIsLoading(true)
        executeGraphQLMutation(UPDATE_PAYMENT_DISPUTE, varaibles, client)
        .then((res)=> {
            if(res?.data?.updatePaymentDispute){
                toast.success(res?.data?.updatePaymentDispute?.message || ``);
                get_dispute_details();
            }
            else {
                toast.error(`Error while updating dispute!`)
            }
        }).catch((e) => {
            toast.error(`Error while updating dispute!`)
        }).finally(() => {
            props?.setIsLoading(false)
        })
    }
    
    const getTranchIdAndPaymentType = () =>{
        let tranch_id =null, amount_paid = 0;
        let payment_type = PAYMENT_TYPES.MAIN_DEALER
        if(props.tab === 'main_dealer_payment') 
            payment_type = PAYMENT_TYPES.MAIN_DEALER
        else if(props.tab === 'split_dealer1_payment') 
            payment_type = PAYMENT_TYPES.SPLIT_DEALER
        else if(props.tab === 'split_dealer2_payment') 
            payment_type = PAYMENT_TYPES.SPLIT_DEALER_2
        else if(props.tab === 'onhold_dealer_payment')
            payment_type = PAYMENT_TYPES.ON_HOLD_DEALER

        let payment = leadDetail?.split_payment.find((split) => split?.payment_type === payment_type)
        tranch_id = payment?.id
        amount_paid = payment?.is_tranche_marked_for_recovery ? (payment?.amount_to_be_paid || 0) : (payment?.amount || 0);
        return {tranch_id, payment_type, amount_paid}
    }
    const handleAmountShouldBePaid = (e) => {
        if (e?.target?.id) {
            if (e?.target?.id === "amount_paid") {
                const amountToBeRecovered = initialDisputeDetails?.amount - (+e?.target?.value);
                if (amountToBeRecovered < +initialDisputeDetails?.amount_recovered) {
                    toast.error(`Amount to be recovered can't be less than the Amount recovered`);
                    return;
                }                
            }
            setDisputeDetails(prev => ({ ...prev, [e?.target?.id]: e?.target?.value }));
        }
    }

    useEffect(() => {
        if (leadDetail?.split_payment?.length && [ "main_dealer_payment", "onhold_dealer_payment", "split_dealer1_payment", "split_dealer2_payment"].includes(tab)) {
            setDisputeDetails({})
            get_dispute_details();
        }
    }, [leadDetail, tab])
    const allowDisputeUpdate = useMemo(() => {
        if (Object.keys(disputeDetails)?.length && Object.keys(initialDisputeDetails)?.length) {
            return initialDisputeDetails?.dispute_status !== DISPUTE_STATUS.RESOLVED && (disputeDetails?.amount_paid !== initialDisputeDetails?.amount_paid || disputeDetails?.remarks !== initialDisputeDetails?.remarks);
        }
        return false;
    }, [disputeDetails, initialDisputeDetails])

    return (<React.Fragment >
        <div className="image-form-outer" key={`${tab}`}>
            {/* {loading ? <Loader /> : null} */}
            <div className="image-tab-panel">
            <DocumentGallery documentCategoryList={documentCategoryList}  />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className={" switch-toggle"}>
                        {tab === 'dealer_incentive_recieved' || tab === 'loan_value_recieved' ? <div className="lead-form-heading">
                            <h2>Payment Recieved From Financier</h2>
                        </div> : null}
                        <div className="lead-form-heading">
                            <h2>{props.name}</h2>
                            {disputeDetails.dispute_status === DISPUTE_STATUS.RAISED ? <span className='pending-tag'> Dispute Raised </span> :
                            disputeDetails.dispute_status === DISPUTE_STATUS.IN_PROGRESS ?<span className='pending-tag'> Dispute in progress </span> :
                            disputeDetails.dispute_status === DISPUTE_STATUS.RESOLVED ?<span className='resolved-tag'> Dispute resolved </span> : <></>
                            }
                        </div>
                        <div className="soft-approvel-sec">
                            <Formsy
                                className="lead-form-filed radio-btn"
                                autoComplete="off"
                                onValid={() => ""}
                                onInvalid={() => ""}
                            >
                                <div className="row">
                                {current_page_fields.map((field) => (
                                    ["number-format", "pattern-format"].includes(field.type) ? (
                                        <fieldset className={`form-filed ${field?.is_half ? 'col-md-6':'col-md-12'}`}>
                                            <div className="material">
                                                <FormsyInputField
                                                    id={field.id}
                                                    name={field.name}
                                                    type={field.type}
                                                    value={(dealerFinancierExchangeData && (dealerFinancierExchangeData[field.name] || ""))}
                                                    placeholder=" "
                                                    thousandsGroupStyle="thousand"
                                                    thousandSeparator={','}
                                                    decimalSeparator={'.'}
                                                    label={ (["amount"].includes(field?.name) && PAYMENT_TYPE_LABEL?.[dealerFinancierExchangeData?.payment_type])|| field.label}
                                                    onChange={e => console.log(e)}
                                                    format={field.ApplyNumberformat ? field.format : ""}
                                                    required={field.required}
                                                    readOnly={field.readOnly}
                                                    disabled={field.disabled}
                                                />
                                            </div>
                                        </fieldset>
                                    ) :
                                        field.type === "date-picker" ? (
                                            <fieldset className={`form-filed ${field?.is_half ? 'col-md-6':'col-md-12'}`}>
                                                <div className="material">
                                                    <FormsyDatePicker
                                                        col='col-md-12'
                                                        name={field.name}
                                                        selectedDate={dealerFinancierExchangeData && dealerFinancierExchangeData[field.name] && new Date(dealerFinancierExchangeData[field.name])}
                                                        value={(dealerFinancierExchangeData && (dealerFinancierExchangeData[field.name] && (Date.parse(dealerFinancierExchangeData[field.name]) || "")))}
                                                        placeholder={field.label}
                                                        maxDate={new Date()}
                                                        minTime={now.setHours(0, 0, 0)}
                                                        maxTime={maxTime}
                                                        onDateSelect={(date) => handleDateChange(field.name, field.type, date)}
                                                        dateFormat="dd MMM, yyyy h:mm aa"
                                                        showTimeSelect={true}
                                                        validations={field.validations}
                                                        validationError={
                                                            "Please Fill the Details"
                                                        }
                                                        required={field.required}
                                                        readOnly={(mainDealerFlag && field.name === 'main_dealer_paid') || (onHoldDealerFlag && field.name === 'onhold_dealer_paid' ) || (splitPaymentFlag && field.name === 'split1_paid') || (field.name === 'split2_paid' && showSplit2Date) || form_read_only || onhold_read_only || split1_paid || split2_paid}
                                                        disabled={(mainDealerFlag && field.name === 'main_dealer_paid') || (onHoldDealerFlag && field.name === 'onhold_dealer_paid' ) || (splitPaymentFlag && field.name === 'split1_paid') || (field.name === 'split2_paid' && showSplit2Date) || form_read_only || onhold_read_only || split1_paid || split2_paid}
                                                        hasError={''}
                                                        clearDate={false}
                                                    />
                                                </div>
                                            </fieldset>
                                        ) : ''
                                ))}
                                </div>

                                {remarksToShow?.length ? <div className="timeline-history-tabel incentive-received-remarks">
                                    <table>
                                        <tbody>
                                            {remarksToShow?.map((remark) =>
                                            (
                                                <tr>
                                                    <td width="25%">
                                                        <span className="date remark-text">{dateFormat(remark?.created_date, 'd mmm')}</span>&nbsp;
                                                        <span className="time">{dateFormat(remark?.created_date, 'hh:MM TT')}</span></td>
                                                    <td>
                                                        <span className="heading remark-text" title={remark?.remark}>{remark?.remark}</span>&nbsp;
                                                        <span className="sectiondiv remark-by">By {remark?.user_details?.added_by_name}</span>
                                                    </td>
                                                </tr>
                                            )
                                            )}
                                        </tbody>
                                    </table>
                                    {remarks?.length > remarksToShow?.length ? <div className='read-more-option' onClick={e => showMoreLessRemarks("more")}><i className='ic-add'></i> Read More</div>: <div className='read-more-option' onClick={e => showMoreLessRemarks("less")}><i className='ic-remove'></i> Read Less</div>}
                                </div> : ''}

                                {Object.keys(disputeDetails || {})?.length && <>
                                    <Accordion allowZeroExpanded>
                                        <div
                                            className="dispute-amp-breakup-outer"
                                        >
                                            <AccordionItem className='acc-item-financer'>
                                            <AccordionItemHeading className='acc-heading-financer'>
                                                <AccordionItemButton>
                                                    <div className='acc-heading'>
                                                    <h4>Disputed Amount Break Up</h4>
                                                    </div>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <ul className="amt-breakup-list">
                                                    <li className='amt-paid-txt'>
                                                        <label>Amount</label>
                                                        <span>{parseFloat(disputeDetails?.amount).toFixed(2)}</span>
                                                    </li>
                                                    <li>
                                                        <label>Amount Should Have Been Paid</label>
                                                        <input
                                                            className='input-field'
                                                            id={"amount_paid"}
                                                            name={"amount_paid"}
                                                            type={"number-format"}
                                                            value={disputeDetails?.amount_paid || ""}
                                                            label={""}
                                                            onChange={e => handleAmountShouldBePaid(e)}
                                                            disabled={getContext.markAsFreezed || initialDisputeDetails?.dispute_status === DISPUTE_STATUS.RESOLVED}    
                                                        />
                                                    </li>
                                                    <li className='amt-to-be-recovered'>
                                                        <label>Amount to be recoverd</label>
                                                        <span>{parseFloat(Number(disputeDetails?.amount) - Number(disputeDetails?.amount_paid)).toFixed(2)}</span>
                                                    </li>
                                                    <li>
                                                        <label>Amount recovered</label>
                                                        <span>{parseFloat(Number(disputeDetails?.amount_recovered)).toFixed(2)}</span>
                                                    </li>
                                                    <li className="amt-pending-txt">
                                                        <label>Amount pending</label>
                                                        <span>{parseFloat(Number(disputeDetails?.amount) - Number(disputeDetails?.amount_paid) - Number(disputeDetails?.amount_recovered)).toFixed(2)}</span>
                                                    </li>
                                                </ul>
                                              
                                                        <div className='remark-outer'>
                                                        <div className="material">
                                                            <textarea
                                                                name={"remarks"}
                                                                id={"remarks"}
                                                                value={disputeDetails?.remarks}
                                                                className='form-input'
                                                                placeholder=' '
                                                                disabled={getContext.markAsFreezed || initialDisputeDetails?.dispute_status === DISPUTE_STATUS.RESOLVED}
                                                                onChange={e => handleAmountShouldBePaid(e)}
                                                            />
                                                            <label data-label="Remarks" className="form-label"></label>
                                                            </div>
                                                </div>
                                            </AccordionItemPanel>
                                            </AccordionItem>
                                        </div>
                                    </Accordion>
                                    {
                                        disputeDetails?.recovery?.length &&
                                        <Accordion allowZeroExpanded>
                                        <div
                                            className="dispute-amp-breakup-outer"
                                        >
                                            <AccordionItem className='acc-item-financer'>
                                            <AccordionItemHeading className='acc-heading-financer'>
                                                <AccordionItemButton>
                                                <div className='acc-heading'>
                                                    <h4>Amount Recoverd Breakup</h4>
                                                    </div>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                            {
                                                disputeDetails?.recovery?.map((recovery)=> 
                                                    recovery.groupName === 'bank_transfer' ? 
                                                        <>
                                                     
                                                        <span className='recovery-title'>Bank Transfer</span>
                                                        <div className='amt-recovery-breakup-list'>
                                                            <ul>
                                                        {recovery.bank_transfer.map((bank) =>
                                                            <li>
                                                                <label>{bank?.utr_number || "-"}</label>
                                                                <span>{bank?.amount || "-"}</span>
                                                                <span className='date-filed-txt'>{(bank?.created_date && dateFormat(bank.created_date, "dd/mm/yyyy")) || ""}</span>
                                                                </li>
                                                                
                                                            )}
                                                            <li className='total-amt-txt'>
                                                            <label>Total</label>
                                                            <span>{recovery?.total_amount}</span>
                                                            <span className='date-filed-txt'>&nbsp;</span>
                                                            </li>
                                                            </ul>
                                                            </div>
                                                        </>
                                                    : recovery.groupName === 'loan' ? 
                                                        recovery.loan.map((loan)=>
                                                            <>
                                                                
                                                                <span className='recovery-title'>Loan ID: UCF{loan?.recovery_lead_id}</span>
                                                                <div className='amt-recovery-breakup-list'>
                                                            <ul>
                                                                {loan?.disputes?.map((dispute)=> <>
                                                                <li>
                                                                    <label>{PAYMENT_TYPE_LABEL?.[dispute?.recovery_payment_type] || ""}</label>
                                                                    <span>{dispute.amount}</span>
                                                                    <span className='date-filed-txt'>{dateFormat(dispute.created_date,'dd/mm/yyyy')}</span>
                                                                </li>
                                                                   
                                                                </>)}
                                                                <li className='total-amt-txt'>
                                                                    <label>Total</label>
                                                                    <span> {loan?.total_amount}</span>        
                                                                    <span className='date-filed-txt'>&nbsp;</span>
                                                                </li>
                                                                </ul>
                                                            </div>
                                                            </>
                                                        )
                                                : <></>
                                                )
                                            }
                                            </AccordionItemPanel>
                                            </AccordionItem>
                                        </div>
                                    </Accordion> || <></>
                                    }
                                </> || <></>}                         
                                

                                <br></br>
                                <div className="btn-save-remarks">
                                    {
                                        allowDisputeUpdate ? <button type="submit" className="btn-primary" onClick={updatePaymentDispute}>Save</button> :
                                        dateSelected ? <span className="span-disabled">Save</span> :
                                        <button type="submit" className="btn-primary" onClick={handleSubmit} disabled={dateSelected}>Save</button>
                                    }
                                    {(showMarkDispute && (+dealerFinancierExchangeData?.amount > 0) && <button type="button" className="btn-primary" onClick={()=> setShowDisputeCreate(true)}>Mark as Dispute</button>) || ""}

                                    <button type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                                        <span className="m-xs-l">({(remarksHistory && (remarksHistory.length || 0))})</span>
                                    </button>
                                 </div>
                            </Formsy>
                        </div>
                    </div>
                </div >           
            </div>
            <div className="view-timeline-popup">
                <div className="view-timeline-popup">
                    <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
                        <div className="modal-header">
                            <h2>Remark History</h2>
                        </div>
                        <LeadRemarkHistory  showremarkform={showremarkform} sectionInfo={sectionInfo}/>
                    </Modal>
                </div>
            </div>
            <div className="view-timeline-popup">
                <div className="view-timeline-popup">
                    <Modal show={showDisputeCreate} handleClose={()=>setShowDisputeCreate(false)} >
                        <div className="modal-header">
                            <h2>Mark Dispute</h2>
                        </div>
                        <MarkDispute leadDetail={leadDetail} tranch_id={getTranchIdAndPaymentType().tranch_id} payment_type={getTranchIdAndPaymentType().payment_type} UserInfo={UserInfo} setShowDisputeCreate={setShowDisputeCreate} amount_paid={getTranchIdAndPaymentType().amount_paid}/>
                    </Modal>
                </div>
            </div>
        </div>
    </React.Fragment>
    )
}

export default DealerFinancierPaymentExchange;