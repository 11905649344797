import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DocumentGallery from './DocumentGallery';
import GeneralService from '../../services/generalService';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { getLoanDetail } from '../../store/action/allAction';
import Formsy from 'formsy-react';
import FormsyInputField from '../elements/FormsyInputField';
import FormsyTextareaAutosize from "../elements/FormsyTextAreaAutosize";
import FormsyDatePicker from '../elements/FormsyDatePicker';
import VehicleDetailAndSoftCreditJson from './formFields/vehicleDetailAndSoftCredit.json'
import { gql, useApolloClient } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';
import Loader from '../elements/Loader';
import { toast } from "react-toastify";
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import { useNavigate } from 'react-router-dom';
import Modal from '../elements/Modal';
import { CHECK_ASSIGN_USER, FIELDS_TO_DISABLE_IF_EXIST, IS_USER_ACCESS_PAGE, isNeedsToDisable, ORCR_VERIFICATION_STATUS, TITLE_BOX_ACTION} from '../../../src/config/constants';
import crypto from '../../config/crypto'
import acceptImg from "../../webroot/images/acceptImg.svg"
import rejectImg from "../../webroot/images/rejectImg.svg"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import dateFormat from "dateformat";
import { UPDATE_LEAD_STATUS } from '../../services/leads.gql';
const { CREDIT_ADVICE_RECEIVED } = FIELDS_TO_DISABLE_IF_EXIST;

const now = new Date();
const currhour = Number(now.getHours());
const currmin = now.getMinutes();

const VehicleDetailAndSoftCredit = (props) => {
    let current_page = props.current_page,current_page_fields = [],is_completed=false;
    const dispatch = useDispatch();
    const {markAsFreezed} = useOutletContext();
    const client = useApolloClient();
    const params = useParams();
    let lead_id = crypto.decode(params.lead_id)
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [isDocRequired, setIsDocRequired] = useState(false);
    const [vehicleAndSoftCreditData, setVehicleAndSoftCreditData] = useState({});
    const [vehicleAndSoftCreditDataExisted, setVehicleAndSoftCreditDataExisted] = useState({});
    const [softApprovalData, setSoftApprovalData] = useState([]);
    const [vehicleAndSoftCreditDocs, setVehicleAndSoftCreditDocs] = useState([]);
    const [vehicleAndSoftCreditDocuments, setVehicleAndSoftCreditDocuments] = useState([]);
    const [softApprovalDocs, setSoftApprovalDocs] = useState([]);
    const [softApprovalDocList, setSoftApprovalDocsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const formFieldsAndDocs = VehicleDetailAndSoftCreditJson[current_page];
    let [ maxTime, setMaxTime ] = useState(now.setHours(currhour, currmin, 0));

    const FinancingFromMinDate = new Date().setMonth(new Date().getMonth() - 2); // Set minDate to 2 months ago
    const FinancingFromMaxDate = new Date().setMonth(new Date().getMonth() + 2); // Set maxDate to 2 months from now
    
    current_page_fields = formFieldsAndDocs.form_fields || [];
    let current_docs = formFieldsAndDocs.upload_docs || [];
    let current_doc_reuired=0;
    const { leadDetail,remarksHistory,userInfo } = useSelector(({ lead,user }) => {
        return {
            leadDetail: lead.leadDetail,
            remarksHistory: lead.remarksHistory || [],
            userInfo: user.user_information
        }
    });

    const adp_type = leadDetail?.lead_computation_details?.title_transfer_type === "Yes" || false;
    let form_read_only = false;

    if(current_page==='soft_approval'){
        is_completed = leadDetail?.tab_status?.credit_advice_received?.contract_signing_received?.is_completed;
        form_read_only = is_completed ? true : false;
    }else if(current_page==='credit_advice_received'){
        is_completed=leadDetail?.tab_status?.payment_verification?.is_completed
        form_read_only = is_completed ? true : false;
    }else if(current_page==='contract_signed'){
        is_completed=leadDetail?.tab_status?.credit_advice_received?.contract_signing_received?.is_completed
        form_read_only = is_completed ? true : false;
    }else if(current_page==='car_handover'){
        is_completed=leadDetail?.tab_status?.vehicle_details?.car_handover?.is_completed
        form_read_only = is_completed ? true : false;
    }else if(current_page==='orcr_received'){
        is_completed=leadDetail?.tab_status?.vehicle_details?.orcr_recieved_from_dealer?.is_completed;
        form_read_only = leadDetail?.tab_status?.vehicle_details?.orcr_submission_to_financier?.is_completed ? true : false;
    }else if(current_page==='orcr_verified'){
        is_completed=leadDetail?.tab_status?.vehicle_details?.orcr_verified_by_financier?.is_completed
        form_read_only = is_completed ? true : false;
    }else if(current_page==='orcr_sent_for_title_transfer'){
        is_completed=leadDetail?.tab_status?.vehicle_details?.orcr_sent_for_title_transfer?.is_completed;
        form_read_only = is_completed ? true : false;
    }else if(current_page==='orcr_received_after_title_transfer'){
        is_completed=leadDetail?.tab_status?.vehicle_details?.orcr_received_after_title_transfer?.is_completed
        form_read_only = is_completed ? true : false;
    }else if(current_page==='orcr_sent_for_verification'){
        is_completed=leadDetail?.tab_status?.vehicle_details?.orcr_sent_for_verification?.is_completed
        form_read_only = is_completed ? true : false;
    }else if(current_page==='orcr_verification_status'){
        is_completed=leadDetail?.tab_status?.vehicle_details?.orcr_verification_status?.is_completed
        form_read_only = is_completed ? true : false;
    }

    let accessCondition = !IS_USER_ACCESS_PAGE(current_page, current_page)?.is_edit_access;
    if(!accessCondition){
        accessCondition=CHECK_ASSIGN_USER(leadDetail?.assign_to);
    }
    let userDetail=userInfo && JSON.parse(userInfo)
    let user_id = userDetail && (userDetail.id || 0);
    const getContext = useOutletContext();
    let navigate = useNavigate();
    const documentCategoryList = (getContext && getContext.docList) || []
    useEffect(() => {
        setIsFileUploaded(false);
        let doc_array = [],uploaded_doc=0;
        if(["orcr_sent_for_verification"].includes(current_page)){
            const docs_to_filter = [];
            if(leadDetail?.lead_computation_details?.title_transfer_type === "Yes" && leadDetail?.lead_computation_details?.orcr_docs_verification_done === "Yes"){
                docs_to_filter.push(138, 139)
            }
            else if(leadDetail?.lead_computation_details?.title_transfer_type === "No" && leadDetail?.lead_computation_details?.orcr_docs_verification_done === "Yes"){
                docs_to_filter.push(104, 137);
            }
            current_docs = current_docs.filter(doc => docs_to_filter.includes(doc?.doc_id))
        }
        current_docs && current_docs.map((field) => {
            if(field.required===true) current_doc_reuired++;
            documentCategoryList.map(obj => { return obj.child && obj.child.map(child => child.id === field.doc_id ? doc_array.push(child) : doc_array.push({})) })
            return current_doc_reuired;
        })
        doc_array = doc_array.filter(obj => Object.keys(obj).length !== 0);
        let lead_docs_new = [];
        doc_array.forEach((obj)=>{
            let lead_doc_arr = leadDetail.leaddocs && leadDetail.leaddocs.filter(lead_doc_obj => lead_doc_obj.doc_id === obj.id);
            lead_doc_arr.forEach(element => {
                if(element!==undefined) {
                    lead_docs_new.push(element);
                }
            });
        })
       
        if(current_page=== "soft_approval"){
            let softApprovalDocsArr = []
            leadDetail?.financier?.forEach((v)=>{
                doc_array.forEach((obj)=>{
                    lead_docs_new = leadDetail.leaddocs && leadDetail.leaddocs.filter(lead_doc_obj => lead_doc_obj.doc_id === obj.id);
                    softApprovalDocsArr.push({...obj,financier_id:v.financier_id,lead_doc_count:0})
                })
            })
            
            softApprovalDocsArr.forEach((obj)=>{
                let count = 0
                lead_docs_new?.forEach((v)=>{
                    if(v.financier_id === obj.financier_id){
                        count++ 
                    }
                })
                obj.lead_doc_count = count
            })
              
            setSoftApprovalDocs(softApprovalDocsArr)
        }

        if (["credit_advice_received"].includes(current_page)) {
            const docsPresent = [...lead_docs_new?.map(doc => doc.doc_id)];
            handleEitherDocCondition(doc_array, docsPresent);
        }
        const updatedDocs = doc_array.map(obj => {
            let leadCatWiseDoc = lead_docs_new.filter(v=>v.doc_id===obj.id)
            if(["orcr_sent_for_verification", "orcr_received"].includes(current_page)) {
                if(leadDetail?.lead_computation_details?.title_transfer_type === "Yes" && leadDetail?.lead_computation_details?.orcr_docs_verification_done === "Yes" && [104, 137, 138, 139].includes(obj?.id)){
                    obj.is_required = ([138, 139].includes(obj?.id) && "1") || ([104, 137].includes(obj?.id) && "0");
                }
                else if(leadDetail?.lead_computation_details?.title_transfer_type === "No" && leadDetail?.lead_computation_details?.orcr_docs_verification_done === "Yes" && [104, 137, 138, 139].includes(obj?.id)){
                    obj.is_required = ([138, 139].includes(obj?.id) && "0") || ([104, 137].includes(obj?.id) && "1");
                }
            } 
            if (leadCatWiseDoc && leadCatWiseDoc.length > 0) {
                current_docs && current_docs.map((field) => {
                    if(field.doc_id===obj.id && field.required===true)
                    uploaded_doc++;
                    return uploaded_doc;
                })
                return { ...obj, "lead_doc_count": leadCatWiseDoc.length };
            } else {
                return { ...obj, "lead_doc_count": 0 };
            }
        });

        if (uploaded_doc === current_doc_reuired) setIsFileUploaded(true);
        
        setVehicleAndSoftCreditDocuments(updatedDocs);
    }, [getContext]);

    useEffect(() => {
        setVehicleAndSoftCreditDocs([]);
        const fetchData = async () => {
            await getVehicleDetailAndSoftCredit(leadDetail);
        };
        fetchData();
    }, [leadDetail,props]);

      const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add("overflow-hidden");
      };
    
      const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove("overflow-hidden");
      };


    const getVehicleDetailAndSoftCredit = (data) => {
        if(current_page === "soft_approval"){
            let soft_approval_data_arr = [];
            data?.soft_approval_ci_data?.forEach((soft_app_obj)=>{
            let soft_approval_data_obj = {}
                current_page_fields.forEach(obj => {
                    if (soft_app_obj && soft_app_obj.hasOwnProperty(obj.name)) {
                        soft_approval_data_obj = {
                            ...soft_approval_data_obj,
                            [obj.name]: obj.type === "date-picker" ? (soft_app_obj && soft_app_obj[obj.name] ?  new Date(soft_app_obj && soft_app_obj[obj.name]) : new Date()): soft_app_obj && soft_app_obj[obj.name]
                        }
                    }
                })
                soft_approval_data_obj.financier_id = soft_app_obj.financier_id
                let financier_name = data.financier.filter((v)=>v.financier_id === soft_app_obj.financier_id)[0].financier.financierlang.financier_name
                soft_approval_data_obj.financier_name = financier_name
                soft_approval_data_obj.send_to_financier = soft_app_obj.send_to_financier
                soft_approval_data_obj.org_is_soft_approval_received = soft_approval_data_obj.is_soft_approval_received
                soft_approval_data_obj.org_custom_soft_approval_received = soft_app_obj["custom_soft_approval_received_date"] ?  new Date(soft_app_obj && soft_app_obj["custom_soft_approval_received_date"]) : ""
                soft_approval_data_arr.push(soft_approval_data_obj)
            })
            setSoftApprovalData(soft_approval_data_arr)
        }else if(current_page === "credit_investigation_recieved"){
            let ci_data_arr = [];
            data?.soft_approval_ci_data?.filter((saci)=>saci.credit_investigation!==null)?.forEach((soft_app_obj)=>{
                let ci_data_obj = {};
                current_page_fields.forEach(obj => {
                        ci_data_obj[obj.name]=  soft_app_obj && soft_app_obj[obj.name]
                })

                ci_data_obj.financier_id = soft_app_obj.financier_id
                let financier_name = data.financier.filter((v)=>v.financier_id === soft_app_obj.financier_id)[0].financier.financierlang.financier_name
                ci_data_obj.financier_name = financier_name
                ci_data_obj.send_to_financier = soft_app_obj.credit_investigation_recieved_date;
                ci_data_obj.credit_investigation_recieved_date=soft_app_obj?.credit_investigation_recieved_date;
                ci_data_obj.org_is_credit_investigation_received = soft_app_obj?.is_credit_investigation_received

                ci_data_arr.push(ci_data_obj)
            })
            setSoftApprovalData(ci_data_arr)
        }
        else{
            let lead_detail_data = {};
            current_page_fields.forEach(obj => {
                if (data.lead_details && data.lead_details.hasOwnProperty(obj.name)) {
                    lead_detail_data = {
                        ...lead_detail_data,
                        [obj.name]: data.lead_details && data.lead_details[obj.name] || ""
                    }
                }
                if (data.lead_status_dates && data.lead_status_dates.hasOwnProperty(obj.name)) {
                    lead_detail_data = {
                        ...lead_detail_data,
                        [obj.name]: data.lead_status_dates && data.lead_status_dates[obj.name] ?  new Date(data.lead_status_dates && data.lead_status_dates[obj.name]) : ''
                    }
                }
            })
            lead_detail_data.orcr_verification_status = data?.lead_details?.orcr_verification_status;
        if(!lead_detail_data?.financing_from_date) lead_detail_data.financing_from_date = null;
        if(!lead_detail_data?.financing_till_date) lead_detail_data.financing_till_date = null;
        setVehicleAndSoftCreditDataExisted(lead_detail_data);
        setVehicleAndSoftCreditData(lead_detail_data);
        }
    }
let required_counter=0;

    const handleFileChange = async (event, doc_id, parent_id, max_upload, lead_doc_count, financier_id) => {
        let selectedImage = event?.target?.files?.[0]?.name || "";
        let publicUrl = '', doc_path = '';
        if (!selectedImage) {
            toast.error("Please upload an image.");
            return;
        }

        if (selectedImage) {
            if (errors['document_required'])
                delete errors.document_required;
            setLoading(true);
            if ((max_upload - lead_doc_count) > 0) {
                const formData = new FormData();
                formData.append('upload_type', 'finance_docs');
                formData.append('lead_id', lead_id);
                formData.append('images', event.target.files[0]);

                try {
                    publicUrl = await GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' });
                    doc_path = publicUrl && publicUrl.data && publicUrl.data.data && publicUrl.data.data.length && (publicUrl.data.data[0].file_url || '');
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    console.error('Error uploading file:', error);
                }
                if(current_page==="soft_approval"){
                    const updatedDocs = softApprovalDocs.map((obj,i)=>{
                        if (obj.id === doc_id && obj.financier_id === financier_id) {
                            // counter = i+1;
                            if(obj.is_required==="1") required_counter++;
                            return { ...obj, "lead_doc_count": lead_doc_count + 1 };
                        }     
                        return obj;
                    })
                    setSoftApprovalDocs(updatedDocs)
                    let lead_detail_docs = [...softApprovalDocList];
                    lead_detail_docs.push({
                        lead_id: Number(lead_id),
                        financier_id:financier_id,
                        doc_id: doc_id,
                        doc_status: "2",
                        parent_doc_id: parent_id,
                        doc_path: doc_path,
                        doc_source: "web",
                        user_id: user_id,
                        status: "1"
                    })
                    setSoftApprovalDocsList(lead_detail_docs);
                }
                else{
                    //updating doc count of each document in array
                    if (["credit_advice_received"].includes(current_page)) {
                        const docsPresent = [doc_id, ...vehicleAndSoftCreditDocs?.map(doc => doc.doc_id)];
                        handleEitherDocCondition(vehicleAndSoftCreditDocuments, docsPresent);
                    }
                const updatedDocs = vehicleAndSoftCreditDocuments.map((obj,i) => {
                    if (obj.id === doc_id) {
                        if(obj.is_required==="1") required_counter++;
                        return { ...obj, "lead_doc_count": lead_doc_count + 1 };
                    }     
                    return obj;
                });
                setVehicleAndSoftCreditDocuments(updatedDocs);
                let lead_detail_docs = [...vehicleAndSoftCreditDocs];
                lead_detail_docs.push({
                    lead_id: Number(lead_id),
                    financier_id:leadDetail?.financier?.filter(v=>v.is_proceed === "yes")[0].financier_id,
                    doc_id: doc_id,
                    doc_status: "2",
                    parent_doc_id: parent_id,
                    doc_path: doc_path,
                    doc_source: "web",
                    user_id: user_id,
                    status: "1"
                })
                setVehicleAndSoftCreditDocs(lead_detail_docs);
            }
            }
        }
        vehicleAndSoftCreditDocuments.filter((v)=>v.is_required==="1").length === required_counter && setIsFileUploaded(true);
        setIsDocRequired(false)
    }
    const handleEitherDocCondition = (arrayObj, docsPresent = []) => {
        arrayObj?.map(doc => {
            if ([96, 136].includes(doc?.id)) {
                if (doc?.id === 96 && docsPresent.includes(136)) doc.is_required = '0';
                if (doc?.id === 136 && docsPresent.includes(96)) doc.is_required = '0';
            }
            return doc;
        })
        return arrayObj;
    }
    useEffect(() => {
        if (["credit_advice_received"].includes(current_page)) {
            const docsPresent = [...vehicleAndSoftCreditDocs?.map(doc => doc.doc_id)];
            handleEitherDocCondition(vehicleAndSoftCreditDocuments, docsPresent);
        }
    },[vehicleAndSoftCreditDocs, vehicleAndSoftCreditDocs?.length])
    const isValidFileSubmit = useMemo(() => {
        if (["credit_advice_received"].includes(current_page)) {
            const existsMandatoryDoc = vehicleAndSoftCreditDocuments?.some(doc=>[96, 136].includes(doc?.id) && doc?.lead_doc_count > 0)
            return (existsMandatoryDoc) || (!existsMandatoryDoc && vehicleAndSoftCreditDocs?.some(doc=>[96, 136].includes(doc?.doc_id)))
        }
        if (["orcr_received"].includes(current_page)) {
            return vehicleAndSoftCreditDocuments?.every(doc=> (doc?.is_required==="1" && doc?.lead_doc_count > 0) || (doc?.is_required==="0" && doc?.lead_doc_count >= 0));
        }
        if (["orcr_sent_for_title_transfer"].includes(current_page)) {
            return vehicleAndSoftCreditDocuments?.every(doc=> (doc?.is_required==="1" && doc?.lead_doc_count > 0) || (doc?.is_required==="0" && doc?.lead_doc_count >= 0));
        }
        if (["orcr_received_after_title_transfer"].includes(current_page)) {
            return vehicleAndSoftCreditDocuments?.every(doc=> (doc?.is_required==="1" && doc?.lead_doc_count > 0) || (doc?.is_required==="0" && doc?.lead_doc_count >= 0)) && leadDetail?.lead_computation_details?.title_transfer_type === 'Yes' && ![TITLE_BOX_ACTION.lead_created].includes(leadDetail?.titlebox?.transfer_status);
        }
        return true
    },[vehicleAndSoftCreditDocuments, vehicleAndSoftCreditDocs])
    const isValidDateSubmit = useMemo(() => {
        if (["credit_advice_received"].includes(current_page) && current_page_fields?.length) {
            current_page_fields = current_page_fields?.map(field => {
                if (["custom_credit_advice_received_date"].includes(field?.name) && vehicleAndSoftCreditData?.pre_approval_received_date) {
                    field.required = false;
                }
                if (["pre_approval_received_date"].includes(field?.name) && vehicleAndSoftCreditData?.custom_credit_advice_received_date) {
                    field.required = false;
                }
                return field;
            })
            return (vehicleAndSoftCreditData?.custom_credit_advice_received_date || vehicleAndSoftCreditData?.pre_approval_received_date) && true || false;
        }
        return true
    },[vehicleAndSoftCreditData?.custom_credit_advice_received_date, vehicleAndSoftCreditData?.pre_approval_received_date])
    const handleInputChange = (target_name, field_type, event, financier_id = 0) => {
        if (event && ["credit_advice_received"].includes(current_page)) {
            let lead_detail_data = { ...vehicleAndSoftCreditData };
            if (errors[target_name])
                delete errors.target_name;
            lead_detail_data[target_name] = field_type === "date-picker" ? event : event.target.value;
            setVehicleAndSoftCreditData(lead_detail_data);
        }
        else if(event && current_page === "soft_approval"){
            let updatedSoftApprovalData = softApprovalData?.map((obj)=>{ 
                let obj1 = {...obj};
                if(obj.financier_id === financier_id){
                    obj1[target_name] = field_type === "date-picker" ? event : event.target.value;
                }
                return obj1;
            })
            setSoftApprovalData(updatedSoftApprovalData)
        }
        else if(event && current_page === "credit_investigation_recieved"){
                let softApprovalDataTemp=softApprovalData;
                let obj=softApprovalDataTemp?.filter((sa)=> sa.financier_id===financier_id)[0];
                if(obj.financier_id === financier_id ){
                    obj[target_name] = field_type === "date-picker" ? event : event.target.value;
                    if(field_type=== "date-picker" ){
                        obj['new_texarea'] =1;
                    }
                }
                setSoftApprovalData([...softApprovalData])
        }
        else if(event && current_page !== "soft_approval") {
            let lead_detail_data = { ...vehicleAndSoftCreditData };
            if (errors[target_name])
                delete errors.target_name;
            lead_detail_data[target_name] = field_type === "date-picker" ? event : event.target.value;
            if(current_page === "contract_signed" && target_name === "financing_from_date")
                delete lead_detail_data["financing_till_date"];
            setVehicleAndSoftCreditData(lead_detail_data);
        }
        if(field_type === "date-picker" ){
            if(compareDate(event)) setMaxTime(now.setHours(23, 59, 59))
            else setMaxTime(now.setHours(currhour, currmin, 0))
        }
    }

    const handleRadioChange=(target_name,e,financier_id)=>{
        let lead_docs = leadDetail?.leaddocs?.filter((obj) => Number(obj.financier_id) === Number(financier_id) && obj.doc_id === 88)?.length;
        let softApprovalDataTemp=softApprovalData;
        let ci_data_arr=softApprovalDataTemp?.filter((sa)=> sa.financier_id===financier_id)[0];
        if(!ci_data_arr?.org_is_credit_investigation_received || ci_data_arr?.org_is_credit_investigation_received===null){
            ci_data_arr[target_name]=e.target.value;
            setSoftApprovalData([...softApprovalData]);
            setIsDataChanged(true)
        }
        if(target_name === "is_soft_approval_received" && e.target.value === "yes" && !lead_docs){
            setIsDocRequired(true)
        }
        else  setIsDocRequired(false)
        if(current_page==="soft_approval")
        setIsDataChanged(true)
        const softApprovalDocListReset = softApprovalDocList.filter((obj) => obj.financier_id !== financier_id);
        setSoftApprovalDocsList(softApprovalDocListReset);
        let softApprovalDocsReset = softApprovalDocs.forEach((obj)=>{
            obj.lead_doc_count = leadDetail?.leaddocs?.filter((doc) => Number(doc.financier_id) === Number(obj.financier_id) && doc.doc_id === 88)?.length || softApprovalDocListReset.filter(docs => docs.financier_id === obj.financier_id)?.length || 0
        })
        if(softApprovalDocsReset && softApprovalDocs.length)setSoftApprovalDocs(softApprovalDocsReset)
    }

    function compareDate(selectedDate) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        let d = new Date(selectedDate); 
        d.setHours(0, 0, 0, 0);
        if(d < today){ 
            return true;
        }
        return false;
    }

    const validateFormData = () => {
        let errorsObj = { ...errors };
        let hasError = false;
        if (current_page_fields.length) {
            current_page_fields.map(fields => {
                if (fields.is_required && !vehicleAndSoftCreditData[fields.name])
                    errorsObj = { ...errorsObj, [fields.name]: true }
                    return fields;
            })
        } else if (vehicleAndSoftCreditDocuments.length) {
            vehicleAndSoftCreditDocuments.map(field => {
                if (field.is_required === true && field.lead_doc_count === 0) {
                    errorsObj = { ...errorsObj, document_required: true }
                }
                return field;
            })
        }
        setErrors(errorsObj);
        Object.keys(errorsObj).forEach(key => {
            if (errorsObj[key] === true) {
                hasError = true;
            }
        });
        if(is_completed){
            setAllowSubmit(false);
        }else{
        if (hasError)
            setAllowSubmit(false);
        else
            setAllowSubmit(true);
        }
        return hasError ? false : true;
    }

    const saveFormDetails = async () => {
        let variables = {}, mutation = {}
        let finalSoftApprovalArr = softApprovalData.map((obj)=>{
            const { financier_name,send_to_financier,org_is_soft_approval_received,org_custom_soft_approval_received, ...newObj } = obj;
            newObj.lead_id = Number(lead_id)
            return newObj;
        })
        if (validateFormData()) {
            setLoading(true);
            let obj = {
                lead_id: Number(lead_id),
                tab_name: props.current_page,
                user_id: user_id
            };
            current_page_fields.forEach(fields => {
                obj = {
                    ...obj,
                    [fields.name]: vehicleAndSoftCreditData[fields.name]
                };
            });
            let redirecturl='';
            switch (props.current_page) {
                case 'car_handover':
                    variables = {
                        carHandoverInput: {
                            ...obj,
                            car_handover_docs: vehicleAndSoftCreditDocs || [],
                            adp_type: adp_type
                        }
                    };
                    mutation = gql`
                        mutation saveCarHandover($carHandoverInput: CarHandoverInput!) {
                            car_handover_save(carHandoverInput: $carHandoverInput) {
                                success
                            }
                        }
                    `;
                    redirecturl=`/lead-detail/orcr-recieved/${params.lead_id}`
                    //navigate(`/lead-detail/orcr-recieved/${lead_id}`)
                    break;
                case 'soft_approval':
                    variables = {
                        softApprovalInput: {
                            lead_id: Number(lead_id),
                            tab_name: props.current_page,
                            user_id: user_id,
                            soft_approval_data : finalSoftApprovalArr,
                            soft_approval_docs: softApprovalDocList || []
                        }
                    };
                    mutation = gql`
                        mutation saveSoftApproval($softApprovalInput: SoftApprovalInput!) {
                            soft_approval_save(softApprovalInput: $softApprovalInput) {
                                success
                            }
                        }
                    `;
                    setSoftApprovalDocsList([]);
                   // navigate(`/lead-detail/credit-investigation/${lead_id}`)
                    break;
                case 'credit_advice_received':
                    variables = {
                        creditDocumentInput: {
                            ...obj,
                            credit_document_docs: vehicleAndSoftCreditDocs || []
                        }
                    };
                    mutation = gql`
                        mutation saveCreditDocumentReceived($creditDocumentInput: CreditDocumentInput!) {
                            credit_document_received_save(creditDocumentInput: $creditDocumentInput) {
                                success
                            }
                        }
                    `;
                  //  navigate(`/lead-detail/conract-signing-received/${lead_id}`)
                  redirecturl=`/lead-detail/conract-signing-received/${params.lead_id}`;
                    break;
                case 'contract_signed':
                    variables = {
                        contractSigningInput: {
                            ...obj,
                            contract_signing_docs: vehicleAndSoftCreditDocs || []
                        }
                    };
                    mutation = gql`
                        mutation saveContractSigned($contractSigningInput: ContractSigningInput!) {
                            contract_signing_save(contractSigningInput: $contractSigningInput) {
                                success
                            }
                        }
                    `;
                    // navigate(`/lead-detail/computation/${lead_id}`)
                    redirecturl=`/lead-detail/computation-generated/${params.lead_id}`;
                    break;
                case 'orcr_received':
                    variables = {
                        orcrReceivedInput: {
                            ...obj,
                            adp_type : adp_type,
                            orcr_received_docs: vehicleAndSoftCreditDocs || []
                        }
                    };
                    mutation = gql`
                        mutation saveOrcrReceived($orcrReceivedInput: OrcrReceivedInput!) {
                            orcr_received_save(orcrReceivedInput: $orcrReceivedInput) {
                                success
                            }
                        }
                    `;
                    //navigate(`/lead-detail/orcr-submission/${lead_id}`)
                    if(leadDetail?.lead_computation_details?.orcr_docs_verification_done === "Yes")
                        redirecturl=`/lead-detail/orcr-sent-for-verification/${params.lead_id}`
                    else if(leadDetail?.lead_computation_details?.title_transfer_type === "Yes")
                        redirecturl=`/lead-detail/orcr-sent-for-title-transfer/${params.lead_id}`
                    else
                        redirecturl=`/lead-detail/orcr-submission/${params.lead_id}`
            break;
                case 'orcr_verified':
                    variables = {
                        orcrVerifiedInput: {
                            ...obj,
                            orcr_verified_docs: vehicleAndSoftCreditDocs || [],
                            adp_type
                        }
                    };
                    mutation = gql`
                        mutation saveOrcrVerified($orcrVerifiedInput: OrcrVerifiedInput!) {
                            orcr_verified_save(orcrVerifiedInput: $orcrVerifiedInput) {
                                success
                            }
                        }
                    `;
                    // navigate(`/lead-detail/orcr-verfied/${lead_id}`)
                    redirecturl=`/lead-detail/orcr-verfied/${params.lead_id}`;
                    break;
                case 'credit_investigation_recieved':
                    let softApprovalData1=softApprovalData.map((sa)=>{
                        delete sa?.new_texarea;
                        delete sa?.org_is_credit_investigation_received
                        return sa;
                    })
                        variables = {
                            creditReceivedInput: {
                                lead_id: Number(lead_id),
                                tab_name: props.current_page,
                                user_id: user_id,
                                ci_received_data : softApprovalData1,
                                
                            }
                        };
                        mutation = gql`
                            mutation saveCrditInvestigationReceived($creditReceivedInput: CreditReceivedInput!) {
                                save_credit_investigation(creditReceivedInput: $creditReceivedInput) {
                                    success
                                }
                            }
                        `;
                        let checkyes=softApprovalData?.filter((cy)=>cy.is_credit_investigation_received==="yes");
                        if(checkyes.length)
                            redirecturl=``;
                        else
                            redirecturl=`/lead-detail/credit-investigation/${params.lead_id}`;
                       // navigate(`/lead-detail/credit-investigation/${lead_id}`)
                        break;
                    case 'orcr_sent_for_title_transfer':
                        variables = {
                            orcrSentforTransferInput: {
                                ...obj,
                                adp_type : adp_type,
                                orcr_sent_for_transfer_docs: vehicleAndSoftCreditDocs || []
                            }
                        };
                        mutation = gql`
                            mutation saveOrcrSentforTransfer($orcrSentforTransferInput: OrcrSentforTransferInput!) {
                                orcr_sent_for_transfer_save(orcrSentforTransferInput: $orcrSentforTransferInput) {
                                    success
                                }
                            }
                        `;
                        redirecturl=`/lead-detail/orcr-recieved-after-title-transfer/${params.lead_id}`
                        //navigate(`/lead-detail/orcr-recieved/${lead_id}`)
                        break;
                    case 'orcr_received_after_title_transfer':
                        variables = {
                            orcrReceivedAfterTransferInput: {
                                ...obj,
                                adp_type : adp_type,
                                orcr_received_after_transfer_docs: vehicleAndSoftCreditDocs || []
                            }
                        };
                        mutation = gql`
                            mutation saveOrcrReceivedAfterTransfer($orcrReceivedAfterTransferInput: OrcrReceivedAfterTransferInput!) {
                                orcr_received_after_transfer_save(orcrReceivedAfterTransferInput: $orcrReceivedAfterTransferInput) {
                                    success
                                }
                            }
                        `;
                        redirecturl=`/lead-detail/orcr-submission/${params.lead_id}`
                        //navigate(`/lead-detail/orcr-recieved/${lead_id}`)
                        break;
                    case 'orcr_sent_for_verification':
                        variables = {
                            orcrSentforVerificationInput: {
                                ...obj,
                                orcr_sent_for_verification_docs: vehicleAndSoftCreditDocs || []
                            }
                        };
                        mutation = gql`
                                    mutation saveOrcrSentforVerification($orcrSentforVerificationInput: OrcrSentforVerificationInput!) {
                                        orcr_sent_for_verification_save(orcrSentforVerificationInput: $orcrSentforVerificationInput) {
                                            success
                                        }
                                    }
                                `;
                        redirecturl = `/lead-detail/orcr-verification-status/${params.lead_id}`
                        //navigate(`/lead-detail/orcr-recieved/${lead_id}`)
                        break;
                    case 'orcr_verification_status':
                        variables = {
                            orcrVerificationStatusInput: {
                                lead_id: obj?.lead_id,
                                tab_name: obj?.tab_name,
                                user_id: obj?.user_id,
                                orcr_verification_status_reference_notes: obj?.orcr_verification_status_reference_notes,
                                orcr_verification_status_docs: vehicleAndSoftCreditDocs || []
                            }
                        };
                        mutation = gql`
                                    mutation saveOrcrVerificationStatus($orcrVerificationStatusInput: orcrVerificationStatusInput!) {
                                        orcr_verification_status_save(orcrVerificationStatusInput: $orcrVerificationStatusInput) {
                                            success
                                        }
                                    }
                                `;

                        redirecturl = 
                        leadDetail?.lead_computation_details?.title_transfer_type === "Yes" && leadDetail?.lead_details?.orcr_verification_status === ORCR_VERIFICATION_STATUS.VERIFIED && `/lead-detail/orcr-sent-for-title-transfer/${params.lead_id}` || `/lead-detail/orcr-submission/${params.lead_id}`
                        //navigate(`/lead-detail/orcr-recieved/${lead_id}`)
                        break;

                    default:

                        
            }
            try {
                await executeGraphQLMutation(mutation, variables, client);
                dispatch(getLoanDetail(lead_id, client))
                toast.success("Success");
                setLoading(false);
                if(redirecturl!=='')
                navigate(redirecturl)
                setIsDataChanged(false)
            } catch (error) {
                setLoading(false);
                toast.error(error.toString().replace('ApolloError:',''));
                console.error(error);
            }
        }
    }


    const updatestatus=()=>{
        setLoading(true);
        let variables = {
          updateleadstatus: {page_type:current_page,user_id:user_id,lead_id:leadDetail?.id}
        };
        executeGraphQLMutation(UPDATE_LEAD_STATUS, variables, client).then((result) => {
          //toast.success("Success");
          dispatch(getLoanDetail(leadDetail.id, client));
          setLoading(false);
          let redirecturl = `/lead-detail/credit-document-received/${params.lead_id}`;
          if (redirecturl !== '')
            navigate(redirecturl)
      });
      }
      
    let sectionInfo = {section : "" , sub_section:""}

    if(props.current_page === 'credit_advice_received' || props.current_page === 'contract_signed') {
        sectionInfo = {section : "Credit advice received" , sub_section:props.name}
    }
    else if(props.current_page === 'soft_approval'){
        sectionInfo = {section : "Soft Approval Received" , sub_section:""}
    }else{
        sectionInfo = {section : "Vehicle Transfer" , sub_section:props.name}
    }

    const isLeadHistoryExists = leadDetail?.lead_status_history.some((data) => data.sub_status_id === 4);
    
    return (<React.Fragment key={`${props.current_page}`}>
        <div className="image-form-outer">
            <div className="image-tab-panel">
            <DocumentGallery documentCategoryList={documentCategoryList}  />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    {props.current_page === "soft_approval" || props.current_page === "credit_investigation_recieved" ? 
                    <div className={" switch-toggle"}>
                    <div className="lead-form-heading">
                        {props.current_page === "credit_investigation_recieved" && <h2>Credit Investigation</h2>}
                        <h2>{props.name}</h2>
                    </div>
                    <Formsy
                            className="lead-form-filed soft-approval-filled-outer"
                            autoComplete="off"
                            onValid={() => setAllowSubmit(true)}
                            onInvalid={() => setAllowSubmit(false)}
                        >
                    {softApprovalData?.map((tab)=>(
                        
                    <Accordion allowZeroExpanded>
                      <div
                        className=""
                        
                      >
                        <AccordionItem className='acc-item-financer'>
                          <AccordionItemHeading className='acc-heading-financer'>
                            <AccordionItemButton>
                                <div className='financer-name-no-date financer-name-date financer-name-no-date'>
                                    <img alt='' src={props.current_page === "soft_approval" ? (tab['is_soft_approval_received']==="yes" ? acceptImg : tab['is_soft_approval_received']==="no" ? rejectImg : "") : (tab['is_credit_investigation_received']==="yes" ? acceptImg : tab['is_credit_investigation_received']==="no" ? rejectImg : "") } className="check-uncheck-img" />
                                    <h3 title= {tab.financier_name}>  {tab.financier_name}</h3>
                                   {tab.org_custom_soft_approval_received && <span className='date-time-txt'>{dateFormat(tab.org_custom_soft_approval_received, 'd mmm,yyyy, h:MM:ss TT')}</span> }

                                   {tab.credit_investigation_recieved_date && <span className='date-time-txt'>{dateFormat(tab.credit_investigation_recieved_date, 'd mmm,yyyy, h:MM:ss TT')}</span> }
                                </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className='acc-panel-finaner'>
                            <div className="soft-approvel-sec">
                                    <div className="row">
                                    {current_page_fields.map((field) => (
                                        ["text", "pattern-format"].includes(field.type) ? (
                                            <fieldset class="form-filed col-md-12 ">    
                                                <div className="material">

                                                    <FormsyInputField
                                                        id={field.id}
                                                        name={field.name}
                                                        type={field.type}
                                                        value={tab && tab[field.name]}
                                                        placeholder=" "
                                                        label={field.label}
                                                        onChange={e => handleInputChange(field.name, field.type, e , tab.financier_id)}
                                                        format={field.ApplyNumberformat ? field.format : ""}
                                                        required={field.required}
                                                        readOnly={tab.org_is_soft_approval_received || field.readOnly || (tab.org_is_soft_approval_received==="yes" &&isLeadHistoryExists) || form_read_only}
                                                        col={"col-md-12"}
                                                        disabled={!tab.is_soft_approval_received}
                                                    />
                                                </div>
                                            </fieldset>
                                        ) :
                                            field.type === "date-picker" && ((current_page==="credit_investigation_recieved" && tab['is_credit_investigation_received']==="yes") || current_page==="soft_approval") ? (
                                                <fieldset class="form-filed col-md-12">
                                                    <div className="material date-filed">
                                                        <FormsyDatePicker
                                                            name={field.name}
                                                            selectedDate={tab && tab[field.name] && new Date(tab[field.name])}
                                                            value={(tab?.[field.name] && Date.parse(tab[field.name])) || ''}
                                                            placeholder={field.label}
                                                            maxDate={new Date()}
                                                            onDateSelect={(e) => !form_read_only ? handleInputChange(field.name, field.type, e, tab.financier_id) : e.preventDefault()}
                                                            dateFormat="dd MMM, yyyy h:mm aa"
                                                            showTimeSelect={true}
                                                            minTime={now.setHours(0, 0, 0)}
                                                            maxTime={maxTime}
                                                            validations={field.validations}
                                                            validationError={
                                                                current_page_fields[field.name] ? field.validationError : ""
                                                            }
                                                            required={field.name === "custom_soft_approval_received_date" && tab.is_soft_approval_received==="yes" ? true: field.required}
                                                            hasError={(current_page_fields.hasOwnProperty(field.name) && !current_page_fields[field.name]) ? true : false}
                                                            readOnly={field.readOnly || form_read_only || (field.name !== "custom_soft_approval_received_date" && tab.org_is_credit_investigation_received)  || (tab.org_is_soft_approval_received==="yes" && isLeadHistoryExists) || tab.org_is_soft_approval_received}
                                                            onBlur={(e)=> console.log(e)}
                                                            col={"col-md-12"}
                                                            disabled={field.name === "custom_soft_approval_received_date" && !tab.is_soft_approval_received}
                                                        />
                                                    </div>
                                                </fieldset>
                                            ) :
                                                field.type === "textarea" ? (
                                                    <fieldset className="form-filed text-area-filed col-md-12">
                                                        <div className="material">
                                                            <FormsyTextareaAutosize
                                                                name={field.name}
                                                                value={(tab && tab[field.name]) || ""}
                                                                placeholder=" "
                                                                label={field.label}
                                                                onChange={e => handleInputChange(field.name, field.type, e, tab.financier_id)}
                                                                format={field.ApplyNumberformat ? field.format : ""}
                                                                required={current_page==="credit_investigation_recieved" ? (field.name === "ci_reference_notes" && tab.is_credit_investigation_received==="no" ? true: field.required):(field.name === "soft_approval_reference_notes" && tab.is_soft_approval_received==="no" ? true: field.required)}
                                                                readOnly={(tab.org_is_soft_approval_received || field.readOnly || form_read_only  || tab.org_is_soft_approval_received==="yes") && isLeadHistoryExists}
                                                                disabled={current_page === "credit_investigation_recieved" ? tab?.credit_investigation_recieved_date && tab?.new_texarea!==1 : false || 
                                                                !tab.is_soft_approval_received}
                                                            />
                                                            <label data-label={(field.name === "soft_approval_reference_notes" && tab.is_soft_approval_received==="no" ? `${field.label}*`: field.label)} className="form-label"></label>
                                                     </div>
                                                       </fieldset>
                                                ) : 
                                                field.type === 'radio'? (
                                                <>
                                                    <fieldset className="form-filed col-md-12 radio-btn radio-type-txt">
                                                    <label className="form-label">{field.label}</label>
                                                    {field.options.map((option,key) => (
                                                        <div className="custom-control custom-checkbox" key={option.value}>
                                                        <input
                                                            aria-label={`for ${option.label}`}
                                                            type="radio"
                                                            className="custom-control-input"
                                                            name={`${option.name}${tab.financier_id}`}
                                                            value={option.value}
                                                            checked={tab[`${field.name}`]?.toString() === option.value}
                                                            onChange={(e)=>handleRadioChange(field.name,e,tab.financier_id)}
                                                            disabled={tab.org_is_soft_approval_received || (tab.org_is_soft_approval_received==="yes" && isLeadHistoryExists && props.current_page === "soft_approval") || (form_read_only && props.current_page === "soft_approval")}
                                                            required={current_page==="credit_investigation_recieved"}
                                                        />
                                                        <label htmlFor= {option.id} className="custom-control-label">{option.label}</label>
                                                        </div>
                                                    ))}
                                                    </fieldset>
                                                </>
                                                ):null          
                                    ))}
                                    </div>
                                { current_page!=="credit_investigation_recieved" &&
                                    <>        
                                    <div className="lead-form-heading">
                                        <h3><b>Documents Upload</b></h3>
                                    </div>
                                    <div className="acco-doc-list">
                                    { loading ? <Loader/> : ''} 
                                        <ul>
                                            {
                                                softApprovalDocs.filter((v)=>v.financier_id === tab.financier_id).map((fields) => (
                                                    <li>
                                                        <div className='doc-count-txt'>
                                                            {fields.lead_doc_count === fields.max_upload ? <span className="stepprogress"><i className="sprite ic-check"></i></span> : ""}
                                                            {fields.doclang && fields.doclang.name}{fields.is_required==="1" && tab.is_soft_approval_received==="yes"? '*' : ''}
                                                            <span className="lead-doc-count">{fields.lead_doc_count || 0} / {fields.max_upload}</span>
                                                        </div>  

                                                            <div className="more-file-upload doc-upload-btn">
                                                            {fields.lead_doc_count === fields.max_upload || form_read_only || markAsFreezed || tab.org_is_soft_approval_received === "yes" || tab.org_is_soft_approval_received? 
                                                            <button type="button" className="btn-line" disabled={true}>
                                                                <i className='ic-upload'></i> Upload
                                                            </button> : 
                                                            <>
                                                            <input aria-label="img upload" type="file" name="" accept=".pdf,image/*" disabled={fields.lead_doc_count === fields.max_upload || !tab.is_soft_approval_received} onChange={e => handleFileChange(e, fields.id,fields.parent_id,fields.max_upload, fields.lead_doc_count, tab.financier_id)} required={fields.is_required==="1" && !(fields.lead_doc_count >= fields.min_upload) && tab.is_soft_approval_received === "yes"} />
                                                                <button aria-label="img upload btn" type="button" className="btn-line">
                                                                   <i className='ic-upload'></i> Upload
                                                                </button>
                                                            </>
                                                            }
                                                            </div>
                                                        
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    </>
                                }
                                 
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                                        
                      </div>
                    </Accordion> 
                    ))}
            { current_page && current_page!=="credit_investigation_recieved" &&
            <div className="btn-save-remarks">
                {(form_read_only || !allowSubmit || markAsFreezed || accessCondition || isDocRequired || !isDataChanged) ? <span className="span-disabled">Save</span> : 
                <button type="submit" className="btn-primary" disabled={form_read_only || !allowSubmit || markAsFreezed || accessCondition || isDocRequired || !isDataChanged} onClick={saveFormDetails}>Save</button>
                }
                {(!isLeadHistoryExists || form_read_only || (leadDetail?.lead_status_history?.filter((ls)=> ls.sub_status_id===28).length>0)) ? <span className="span-disabled">Next</span> : 
                <button type="submit" className="btn-primary" disabled={!isLeadHistoryExists || form_read_only || (leadDetail?.lead_status_history?.filter((ls)=> ls.sub_status_id===28).length>0) }onClick={()=>navigate(`/lead-detail/credit-investigation/${params.lead_id}`)}>Next</button>
                }
                <button type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                    <span className="m-xs-l">({remarksHistory?.length || 0})</span>
                </button>
            </div>
            }

        { current_page && current_page==="credit_investigation_recieved" &&
            <div className="btn-save-remarks">
            {(form_read_only || !allowSubmit  || markAsFreezed || accessCondition || (leadDetail?.lead_status_history?.filter((ls)=> ls.sub_status_id===28).length>0) || !isDataChanged) ? <span className="span-disabled">Save</span> :
                <button type="submit" className="btn-primary" disabled={form_read_only || !allowSubmit  || markAsFreezed || accessCondition || (leadDetail?.lead_status_history?.filter((ls)=> ls.sub_status_id===28).length>0) || !isDataChanged } onClick={saveFormDetails}>  Save</button>
            }
            {(form_read_only || !allowSubmit  || markAsFreezed || accessCondition || (leadDetail?.lead_status_history?.filter((ls)=> ls.sub_status_id===28).length>0) || !softApprovalData?.filter((sf)=>sf?.org_is_credit_investigation_received==="yes")?.length) ? <span className="span-disabled">Next</span> :
                <button type="submit" className="btn-line" disabled={form_read_only || !allowSubmit  || markAsFreezed || accessCondition || (leadDetail?.lead_status_history?.filter((ls)=> ls.sub_status_id===28).length>0) || !softApprovalData?.filter((sf)=>sf?.org_is_credit_investigation_received==="yes")?.length} onClick={updatestatus}>  Next</button>
            }

                <button type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                    <span className="m-xs-l">({remarksHistory?.length || 0})</span>
                </button>
            </div>
            }
            </Formsy>
                </div>
                : 
                <div className={" switch-toggle"}>
                {/* {props.current_page === 'credit_advice_received' || props.current_page === 'contract_signed' ? 
                <div className="lead-form-heading">
                    <h2>Credit Advice Received</h2>
                </div> : null} */}
                <div className="lead-form-heading">
                    <h2>{props.name}</h2>
                </div>
                <div className="soft-approvel-sec">
                    <Formsy
                        className="lead-form-filed"
                        autoComplete="off"
                        onValid={() => setAllowSubmit(true)}
                        onInvalid={() => setAllowSubmit(false)}
                        onValidSubmit={saveFormDetails}
                    >
                        <div className="row">
                        {current_page_fields.map((field) => (
                            ["text", "pattern-format"].includes(field.type) ? (
                                <fieldset class="form-filed col-md-12 ">
                                    <div className="material">
                                        <FormsyInputField
                                            id={field.id}
                                            name={field.name}
                                            type={field.type}
                                            value={vehicleAndSoftCreditData && vehicleAndSoftCreditData[field.name]}
                                            placeholder=" "
                                            label={field.label}
                                            onChange={e => handleInputChange(field.name, field.type, e)}
                                            format={field.ApplyNumberformat ? field.format : ""}
                                            required={field.required}
                                            readOnly={(["credit_advice_received"].includes(current_page) && isNeedsToDisable(field?.name, CREDIT_ADVICE_RECEIVED, vehicleAndSoftCreditDataExisted?.[field?.name])) || field.readOnly }
                                            col={"col-md-12"}
                                        />
                                    </div>
                                </fieldset>
                            ) :
                                field.type === "date-picker" ? (
                                    <fieldset class="form-filed col-md-12">
                                        <div className="material date-filed">
                                            <FormsyDatePicker
                                                name={field.name}
                                                selectedDate={vehicleAndSoftCreditData && vehicleAndSoftCreditData[field.name] && new Date(vehicleAndSoftCreditData[field.name])}
                                                value={(vehicleAndSoftCreditData && vehicleAndSoftCreditData[field.name] && Date.parse(vehicleAndSoftCreditData[field.name])) || ''}
                                                placeholder={field.label}
                                                minDate={field.name === "financing_from_date" ? FinancingFromMinDate : field.name === "financing_till_date" ? new Date(vehicleAndSoftCreditData["financing_from_date"]).setMonth(new Date(vehicleAndSoftCreditData["financing_from_date"]).getMonth() + 6) : ""}
                                                maxDate={field.name === "financing_from_date" ? FinancingFromMaxDate : field.name === "financing_till_date" ? "" : new Date()}
                                                onDateSelect={(e) => !form_read_only ? handleInputChange(field.name, field.type, e) : e.preventDefault()}
                                                dateFormat="dd MMM, yyyy h:mm aa"
                                                showTimeSelect={true}
                                                minTime={now.setHours(0, 0, 0)}
                                                maxTime={["financing_from_date", "financing_till_date"].includes(field.name) ? now.setHours(23, 59, 59, 999) : maxTime}
                                                validations={field.validations}
                                                validationError={
                                                    current_page_fields[field.name] ? field.validationError : ""
                                                }
                                                required={field.required}
                                                hasError={(current_page_fields.hasOwnProperty(field.name) && !current_page_fields[field.name]) ? true : false}
                                                readOnly={(["credit_advice_received"].includes(current_page) && isNeedsToDisable(field?.name, CREDIT_ADVICE_RECEIVED, vehicleAndSoftCreditDataExisted?.[field?.name])) || ((field.name === "financing_till_date" && !vehicleAndSoftCreditData["financing_from_date"]) || field.readOnly || form_read_only)}
                                                onBlur={(e)=> console.log(e)}
                                                col={"col-md-12"}
                                            />
                                        </div>
                                    </fieldset>
                                ) :
                                    field.type === "textarea" ? (
                                        <fieldset className="form-filed text-area-filed col-md-12">
                                            <div className="material">
                                                <FormsyTextareaAutosize
                                                    name={field.name}
                                                    value={vehicleAndSoftCreditData && vehicleAndSoftCreditData[field.name]}
                                                    placeholder=" "
                                                    label={field.label}
                                                    onChange={e => handleInputChange(field.name, field.type, e)}
                                                    format={field.ApplyNumberformat ? field.format : ""}
                                                    required={field.required}
                                                    readOnly={ (["credit_advice_received"].includes(current_page) && isNeedsToDisable(field?.name, CREDIT_ADVICE_RECEIVED, vehicleAndSoftCreditDataExisted?.[field?.name])) || field.readOnly || form_read_only}
                                                />
                                                <label data-label={field.label} className="form-label"></label>
                                            </div>
                                        </fieldset>
                                    ) : null

                        ))}
                        </div>

                        <div className="lead-form-heading">
                            {vehicleAndSoftCreditDocuments?.length && <h3><b>Documents Upload</b></h3> || null}
                        </div>
                        <div className="acco-doc-list">
                        { loading ? <Loader/> : ''} 
                            <ul>
                                {
                                    vehicleAndSoftCreditDocuments.map((fields) => (
                                        <li>
                                            <div className='doc-count-txt'>
                                                {fields.lead_doc_count === fields.max_upload ? <span className="stepprogress"><i className="sprite ic-check"></i></span> : ""}
                                                {fields.doclang && fields.doclang.name}{fields.is_required==="1" ? '*' : ''}
                                                <span className="lead-doc-count">{fields.lead_doc_count || 0} / {fields.max_upload}</span>
                                            </div>  
                                            
                                                <div className="more-file-upload doc-upload-btn">
                                                {fields.lead_doc_count === fields.max_upload || form_read_only || markAsFreezed ? 
                                                <button aria-label="disabled btn" type="button" className="btn-line" disabled={true}>
                                                    <i className='ic-upload'></i> Upload
                                                </button> : 
                                                <>
                                                <input aria-label="image upload" type="file" name="" accept=".pdf,image/*" disabled={fields.lead_doc_count === fields.max_upload} onChange={e => handleFileChange(e, fields.id,fields.parent_id,fields.max_upload, fields.lead_doc_count)} required={fields.is_required==="1" && !(fields.lead_doc_count >= fields.min_upload)} />
                                                    <button aria-label="btn image upload" type="button" className="btn-line">
                                                       <i className='ic-upload'></i> Upload
                                                    </button>
                                                </>
                                                }
                                                </div>
                                            
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <br></br>
                        
                        <div className="btn-save-remarks">
                        {(form_read_only || 
                        (["credit_advice_received"].includes(current_page) ? !isValidDateSubmit : !allowSubmit) || (["credit_advice_received", "orcr_received", "orcr_sent_for_title_transfer", "orcr_received_after_title_transfer"].includes(current_page) ? !isValidFileSubmit : !isFileUploaded) || markAsFreezed || accessCondition) ? <span className="span-disabled">Save & Next</span> :
                            <button type="submit" className="btn-primary" disabled={form_read_only || (["credit_advice_received"].includes(current_page) ? !isValidDateSubmit : !allowSubmit) || (["credit_advice_received", "orcr_received", "orcr_sent_for_title_transfer", "orcr_received_after_title_transfer"].includes(current_page) ? !isValidFileSubmit : !isFileUploaded) || markAsFreezed || accessCondition}>Save & Next</button>
                        }
                            <button type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                                <span className="m-xs-l">({remarksHistory?.length || 0})</span>
                            </button>
                        </div>
                    </Formsy>
                </div>
            </div>
            }
                </div >
            </div>
            <div className="view-timeline-popup">
                <div className="view-timeline-popup">
                    <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
                        <div className="modal-header">
                            <h2>Remark History</h2>
                        </div>
                        <LeadRemarkHistory  showremarkform={showremarkform} markAsFreezed={markAsFreezed} accessCondition={accessCondition} sectionInfo={sectionInfo}/>
                    </Modal>
                </div>
            </div>
        </div>
    </React.Fragment>
    )
}

export default VehicleDetailAndSoftCredit;