import Formsy from 'formsy-react';
import React, { useState } from 'react';
import { FINANCIER_DECIMAL_LIMIT, FINANCIER_ID } from '../../../config/constants';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import FormsyInputField from '../../elements/FormsyInputField';
import payment_verification_fields from '.././formFields/paymentVerification.json';
import SplitPaymentFormTwo from './SplitPaymentFormTwo';
import { useOutletContext } from 'react-router-dom';
const FORM_FIELDS = payment_verification_fields?.payment_verification_details || [];

const PaymentVerificationTwo = (props) => {
  const {
    financierId, accessCondition, leadDetail,
    paymentVerificationDetails, setPaymentVerificationDetails,
    splitPaymentDetails, setSplitPaymentDetails,
    tranchData, setTranchData,
    recoveryData, setRecoveryData, showConfirmation
  } = props;

  const getContext = useOutletContext();
  const propsToPass = {
    leadDetail,
    paymentVerificationDetails, setPaymentVerificationDetails,
    splitPaymentDetails, setSplitPaymentDetails,
    tranchData, setTranchData,
    recoveryData, setRecoveryData,
    getContext,
    showConfirmation
  };
  const [allowSubmit, setAllowSubmit] = useState(false);

  const handleCheckboxChange = (event, index) => {
    splitPaymentDetails[index].is_tranche_marked = !splitPaymentDetails?.[index]?.is_tranche_marked;
    setSplitPaymentDetails([...splitPaymentDetails]);
  }

  return (
    <>
      <div className="image-form-outer">
        <div className="lead-detail-from-outer">
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={showConfirmation}
          >
            <div className="row">
              {FORM_FIELDS.map((field) => (
                ["text", "pattern-format", "number-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={paymentVerificationDetails?.[field?.name]}
                      placeholder=" "
                      {...(field.type === "number-format") ? {
                        thousandsGroupStyle: "thousand",
                        thousandSeparator: ',',
                        decimalSeparator: '.'
                      } : {}}
                      label={field.label}
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={field.type === "number-format" ? field.name === "total_dealer_incentive_of_tax" ? 'isNegativeValue' : 'isZeroValue' : field.validations}
                      validationError={field.validationError}
                      required={field.required}
                      disabled={getContext.markAsFreezed || ["tin_number", "total_payment_to_dealer", "loan_value", "total_dealer_incentive_of_tax"].includes(field.name) || true}
                      decimalScale={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_DECIMAL_LIMIT.JACCS_FINANCE) || FINANCIER_DECIMAL_LIMIT.DEFAULT}
                    />
                  </fieldset>
                ) :
                  field.type === "date-picker" ? (
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyDatePicker
                        name={field.name}
                        selectedDate={paymentVerificationDetails && paymentVerificationDetails[field.name] && new Date(paymentVerificationDetails[field.name])}
                        placeholder={field.label}
                        maxDate={new Date()}
                        value={paymentVerificationDetails && paymentVerificationDetails[field.name]}
                        dateFormat="yyyy-mm-dd"
                        validations={field.validations}
                        validationError={field.validationError}
                        required={field.required}
                        hasError={(paymentVerificationDetails.hasOwnProperty(field.name) && !paymentVerificationDetails[field.name]) ? true : false}
                        disabled={getContext.markAsFreezed || ["accreditation_date"].includes(field.name) ? true : false}
                      />
                    </fieldset>
                  ) : null
              ))}
            </div>
            {splitPaymentDetails?.map((split_trance, index) => <SplitPaymentFormTwo {...propsToPass} index={index} split_trance={split_trance} handleCheckboxChange={handleCheckboxChange} />)}
            <div className="btn-save-remarks">
              <br></br>
              {(getContext.markAsFreezed || !allowSubmit || accessCondition || !splitPaymentDetails?.filter(item => item?.is_tranche_marked)?.length) ? <span className="span-disabled">Send & Next</span> :
                <button type="submit" className="btn-primary" disabled={(getContext.markAsFreezed || !allowSubmit || accessCondition)}>
                  Send & Next
                </button>
              }
              {/* <button type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                  <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                </button> */}
            </div>
          </Formsy>
        </div>
      </div>
    </>
  );
}
export default PaymentVerificationTwo;