import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gql, useApolloClient } from '@apollo/client';
import { getLoanDetail } from '../../store/action/allAction';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { Nav, Tab } from 'react-bootstrap';
import Loader from '../elements/Loader';
import LoanVehicleDetails from './LoanVehicleDetails';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { NO_TOUCH_POLICY_FINANCIER } from '../../config/constants';

const LoanQuoteTabs = (props) => {
    let { allOptions, lead_id } = props;
    const [loanVehicleDetails, setLoanVehicleDetails] = useState({});
    const [quotesArr, setQuotesArr] = useState({});
    const [selectedTab, setSelectedTab] = useState("first");
    const [selectedFinanciers, setSelectedFinanciers] = useState([]);
    const [selectedQuotes, setSelectedQuotes] = useState([]);
    const [activeFinancier, setActiveFinancier] = useState('finance-0');
    const [activeFinancierQuote, setActiveFinancierQuote] = useState([]);
    const [addRequiredDp, setAddRequiredDp] = useState([]);
    const [requiredDpOptions, setRequiredDpOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isMaxDpAmount, SetIsMaxDpAmount] = useState(false);
    const user_information = useSelector((state) => state.user.user_information);
    const userInfo = JSON.parse(user_information);
    const client = useApolloClient();
    const dispatch = useDispatch();

    const getQuotes = async()=>{     
        setLoading(true);  
        let input_type = 'getQuoteInput', api_called = 'quotes';
        let getQuoteInput = {
            make_year: loanVehicleDetails?.yearofmanufacturing,
            tenure_in_month: loanVehicleDetails?.tenure,
            out_of_town_charge: loanVehicleDetails?.out_of_town_charges || 0,
            car_fmv: loanVehicleDetails?.car_fmv || 0, 
            vehicle_type: loanVehicleDetails?.vehicle_type,
            other_vehicle_type: loanVehicleDetails?.other_vehicle_type || "",   
            make_id: loanVehicleDetails?.brand,
            model_id: loanVehicleDetails?.model,
            variant_id: loanVehicleDetails?.variant,
            make_name: Number(loanVehicleDetails?.make_id)===0 ? loanVehicleDetails?.make_name : allOptions?.["brand"]?.find(({ value }) => value === loanVehicleDetails?.["brand"])?.label || "",
            model_name: Number(loanVehicleDetails?.model_id)===0 ? loanVehicleDetails?.model_name : allOptions["model"].find(({ value }) => value === loanVehicleDetails?.["model"])?.label || "",
            variant_name: Number(loanVehicleDetails?.variant_id)===0 ? loanVehicleDetails?.variant_name : allOptions["variant"].find(({ value }) => value === loanVehicleDetails?.["variant"])?.label || "",        
            api_called_by: "web"
        }

        let quote_arr=[];
        Object.keys(getQuoteInput).forEach((key) => {
            if(typeof getQuoteInput[key] === "string")
                quote_arr.push(`${key}: "${getQuoteInput[key]}"`)
            else if(key==="financier_ids")
                quote_arr.push(`${key}: "${getQuoteInput[key]}"`)
            else
                quote_arr.push(`${key}: ${getQuoteInput[key]}`)
        })
        const query = gql`{${api_called}(${input_type}: {${quote_arr.join(', ')}}){
                make_id
                model_id
                variant_id
                make_name
                model_name
                variant_name
                tenure_in_month
                vehicle_type
                orcr_region_id
                dp_type
                dp_value
                car_fmv
                out_of_town_charge
                make_year
                quotes{
                    financier_id
                    name
                    logo
                    installment_amount
                    interest_rate
                    chattel_percent
                    dp_amount
                    loan_amount
                    chattel_fee
                    processing_fee
                    dealer_incentive
                    dealer_incentive_percent
                    carmudi_top_up_percent
                    total_payment_by_customer
                    dealer_incentive_from_financier
                    dealer_incentive_from_carmudi
                    total_dealer_incentive
                    dealer_incentive_tax_percentage
                    total_dealer_incentive_net_of_tax
                    total_payment_from_financier
                    total_payment_to_dealer
                    dealer_incentive_from_financier_net_of_tax
                    tax
                    chattel_fee_info{
                        interest_rate
                        chattel_fee
                        chattel_percent
                    }
                }
            }
          } `

        try {
            await executeGraphQLQuery(query, client).then((res) =>{
                let response = res?.data?.quotes || {};
                setQuotesArr(response);
                setSelectedTab("second");
                setSelectedFinanciers([]);
                setLoading(false);
            })
        } catch (error) {
            setLoading(false);
            toast.error("Failed");
            console.error(error);
        }
    }

    const calculateLoan = async() =>{
        setLoading(true);  
        let input_type = 'calculateLoanInput', api_called = 'calculate_loan';
        let calculateLoanInput = {
            api_called_by: "web",
            make_year: loanVehicleDetails?.yearofmanufacturing,
            tenure_in_month: loanVehicleDetails?.tenure,
            out_of_town_charge: loanVehicleDetails?.out_of_town_charges || 0,
            car_fmv: loanVehicleDetails?.car_fmv, 
            vehicle_type: loanVehicleDetails?.vehicle_type,
            financier_id: activeFinancierQuote?.[0]?.financier_id,
            dp_type: requiredDpOptions.find((options) => options.financier_id === activeFinancierQuote?.[0]?.financier_id)?.option_selected === 'per_'+activeFinancierQuote?.[0]?.financier_id ? "per" : "fixed",
            dp_value: requiredDpOptions.find((options) => options.financier_id === activeFinancierQuote?.[0]?.financier_id)?.required_per || 0,
            dp_amount: requiredDpOptions.find((options) => options.financier_id === activeFinancierQuote?.[0]?.financier_id)?.required_amt || 0
        }

        let quote_arr=[];
        Object.keys(calculateLoanInput).forEach((key) => {
            if(typeof calculateLoanInput[key] === "string")
                quote_arr.push(`${key}: "${calculateLoanInput[key]}"`)
            else if(key==="financier_ids")
                quote_arr.push(`${key}: "${calculateLoanInput[key]}"`)
            else
                quote_arr.push(`${key}: ${calculateLoanInput[key]}`)
        })
        const query = gql`{${api_called}(${input_type}: {${quote_arr.join(', ')}}){
                dp_type
                dp_value
                dp_amount
                make_id
                model_id
                variant_id
                make_name
                model_name
                variant_name
                tenure_in_month
                vehicle_type
                orcr_region_id
                dp_type
                dp_value
                car_fmv
                out_of_town_charge
                make_year
                quotes{
                    financier_id
                    name
                    logo
                    installment_amount
                    interest_rate
                    chattel_percent
                    dp_amount
                    loan_amount
                    chattel_fee
                    processing_fee
                    dealer_incentive
                    dealer_incentive_percent
                    carmudi_top_up_percent
                    total_payment_by_customer
                    dealer_incentive_from_financier
                    dealer_incentive_from_carmudi
                    total_dealer_incentive
                    dealer_incentive_tax_percentage
                    total_dealer_incentive_net_of_tax
                    total_payment_from_financier
                    total_payment_to_dealer
                    dealer_incentive_from_financier_net_of_tax
                    tax
                    chattel_fee_info{
                        interest_rate
                        chattel_fee
                        chattel_percent
                    }
                }
            }
        } `

        try {
            await executeGraphQLQuery(query, client).then((res) =>{
                let response = res?.data?.calculate_loan || {};
                setActiveFinancierQuote([...activeFinancierQuote, {...response?.quotes?.[0], is_checked: true, dp_type: response?.dp_type, dp_value: response?.dp_value}]);

                let selected_quotes = [...selectedQuotes];
                selected_quotes.push({...response?.quotes?.[0], is_checked: true, dp_type: response?.dp_type, dp_value: response?.dp_value});
                setSelectedQuotes(selected_quotes);
                
                let required_dp_options = [...requiredDpOptions];
                required_dp_options = required_dp_options?.filter(options => options.financier_id !== activeFinancierQuote?.[0]?.financier_id);
                setRequiredDpOptions(required_dp_options);
                let add_required_dp = [...addRequiredDp];
                add_required_dp = add_required_dp?.filter(dp => dp !== activeFinancierQuote?.[0]?.financier_id);
                setAddRequiredDp(add_required_dp);
                setLoading(false);
            })
        } catch (error) {
            setLoading(false);
            toast.error("Failed");
            console.error(error);
        }
    }

    const createLeadQuote = async() =>{
        setLoading(true); 
        let quote_data = selectedFinanciers?.map((financier_id) => {
            let quote = selectedQuotes?.filter((quote) => quote.financier_id === financier_id);
            let objtoPush = quote?.length > 1 ? quote?.filter((data) => data?.is_checked === true)?.[0] : quote?.[0];
            let finalObj = {
                tenure_in_month: loanVehicleDetails?.tenure,
                vehicle_usage: loanVehicleDetails?.vehicle_usage,
                vehicle_type: loanVehicleDetails?.vehicle_type,
                other_vehicle_type: loanVehicleDetails?.other_vehicle_type || "",
                car_fmv: loanVehicleDetails?.car_fmv,
                out_of_town_charge: loanVehicleDetails?.out_of_town_charges || 0,
                dp_type: objtoPush?.dp_type || "fixed",
                dp_value: objtoPush?.dp_value || 0,
                dp_amount: objtoPush?.dp_amount || 0,
                interest_rate: objtoPush?.interest_rate,
                dealer_incentive: objtoPush?.dealer_incentive,
                chattel_fee: objtoPush?.chattel_fee || 0,
                processing_fee: objtoPush?.processing_fee,
                loan_amount: objtoPush?.loan_amount,
                installment_amount: objtoPush?.installment_amount,
                financier_id: objtoPush?.financier_id || 0,
                chattel_percent: objtoPush?.chattel_percent,
                dealer_incentive_percent: objtoPush?.dealer_incentive_percent,
                carmudi_top_up_percent: objtoPush?.carmudi_top_up_percent,
                total_payment_by_customer: objtoPush?.total_payment_by_customer,
                dealer_incentive_from_financier: objtoPush?.dealer_incentive_from_financier,
                dealer_incentive_from_carmudi: objtoPush?.dealer_incentive_from_carmudi,
                total_dealer_incentive: objtoPush?.total_dealer_incentive,
                dealer_incentive_tax_percentage: objtoPush?.dealer_incentive_tax_percentage,
                total_dealer_incentive_net_of_tax: objtoPush?.total_dealer_incentive_net_of_tax,
                total_payment_from_financier: objtoPush?.total_payment_from_financier,
                total_payment_to_dealer: objtoPush?.total_payment_to_dealer,
                dealer_incentive_from_financier_net_of_tax: objtoPush?.dealer_incentive_from_financier_net_of_tax,
                tax: objtoPush?.tax
            }
            return finalObj;
        })
        let createLeadInput = {
            quote_data : quote_data,
            lead_details : {
                make_id: loanVehicleDetails?.brand || 0,
                model_id: loanVehicleDetails?.model || 0 ,
                variant_id: loanVehicleDetails?.variant || 0,
                make_name: loanVehicleDetails?.brand===0 ? loanVehicleDetails?.make_name : allOptions['brand'].find(({ value }) => value === loanVehicleDetails?.['brand'])?.label || "",
                model_name: loanVehicleDetails?.model===0 ? loanVehicleDetails?.model_name : allOptions['model'].find(({ value }) => value === loanVehicleDetails?.['model'])?.label || "",
                variant_name: loanVehicleDetails.variant===0 ? loanVehicleDetails?.variant_name : allOptions['variant'].find(({ value }) => value === loanVehicleDetails?.['variant'])?.label || "",
                make_year: loanVehicleDetails?.yearofmanufacturing,
            }
        }
        let variables = {
            user_id : userInfo?.id,
            lead_id : parseInt(lead_id),
            is_partial_submission:true,
            role_id : 6,
            api_called_by: "web",
            createLeadInput: createLeadInput
        }

        let mutation = gql`mutation($role_id: Float!, $api_called_by: API_CALL_BY!, $user_id: Float!, $lead_id: Float! $createLeadInput: CreateLeadInput!, $is_partial_submission: Boolean) {
            createLead(createLeadInput: $createLeadInput,api_called_by: $api_called_by,user_id: $user_id,role_id: $role_id, lead_id: $lead_id, is_partial_submission: $is_partial_submission,lead_next_step:"personal_details") {
                id
                message
            }
        }`;
  
        try {
        await executeGraphQLMutation(mutation, variables, client);
            dispatch(getLoanDetail(lead_id, client));
            toast.success("Loan Quote Generated Successfully!");
        } catch (error) {
            toast.error("Failed");
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const handleCheckBoxChange = (e, financier_id) => {
        let financiers = [...selectedFinanciers];
        if (e.target.checked) {
            let anyValueExists = NO_TOUCH_POLICY_FINANCIER.some(nf => financiers.includes(nf));
            if (!anyValueExists) {
                financiers.push(financier_id);
            } else if (!NO_TOUCH_POLICY_FINANCIER.includes(financier_id)) {
                financiers.push(financier_id);
            }
        }
        else financiers = financiers?.filter(ids => ids !== financier_id);
        if (financiers.length <= 3) {
            setSelectedFinanciers(financiers);
        } else {
            toast.error("You cannot select more than three financiers.");
        }

    }

    const handleQuoteGeneration = () => {
        let selected_quotes = quotesArr?.quotes?.filter(quote => selectedFinanciers.includes(quote.financier_id));
        selected_quotes = selected_quotes?.map((data) => {
            return {...data,dp_type: quotesArr?.dp_type, dp_value: quotesArr?.dp_value}
        })
        setActiveFinancierQuote([selected_quotes[0]]);
        setSelectedQuotes(selected_quotes);
        setSelectedTab("third");
    }

    const onTabChange = async(tab, event) => {
        if (event.type === "click") { 
           setActiveFinancier(tab);
           let quote=selectedQuotes?.filter((quote)=> quote.financier_id===Number(event?.target?.attributes?.financeid?.value));
           setActiveFinancierQuote(quote);
        }
    }

    const onAddRequiredDpClick = () => {
        setAddRequiredDp([...addRequiredDp, activeFinancierQuote?.[0]?.financier_id]);
        setRequiredDpOptions([...requiredDpOptions, { 
            financier_id : activeFinancierQuote?.[0]?.financier_id, 
            required_per : 0,
            required_amt : 0,
            option_selected : 'per_'+activeFinancierQuote?.[0]?.financier_id
        }])
    }

    const handleDpOptionsChange = (event, type, financier_id, value = "") => {
        if(type === 'required_amt' && +event?.target?.value > +activeFinancierQuote?.[0]?.loan_amount) {
            SetIsMaxDpAmount(true); 
        } else SetIsMaxDpAmount(false);

        setRequiredDpOptions(requiredDpOptions.map(option => 
            option.financier_id === financier_id
                ? { ...option, [type]: value !== "" ? value : type === "required_per" ? parseInt(event?.value) : parseInt(event.target.value), ...(type === "option_selected" && { required_per: 0, required_amt: 0 }) }
                : option
        ));
    }

    const handleEditOptionsChange = (event, type, index) => {
        let activeQuote = [...activeFinancierQuote];
        activeQuote = activeQuote.map((quote, i) => 
            i === index
                ? { ...quote, [type]: event?.value, ...(type === "chattel_percent" && { interest_rate: quote?.chattel_fee_info?.filter(chattel => chattel?.chattel_percent === event?.value)?.[0]?.interest_rate }) }
                : { ...quote }
        )
        setActiveFinancierQuote(activeQuote);
        updateSelectedQuote(activeQuote);
    }

    const handleRadioChange = (index) => {
        let activeQuote = [...activeFinancierQuote];
        activeQuote = activeQuote.map((quote, i) => 
            i === index
                ? { ...quote, is_checked: true }
                : { ...quote, is_checked: false }
        )
        setActiveFinancierQuote(activeQuote);
        updateSelectedQuote(activeQuote);        
    }

    const updateSelectedQuote = (activeQuote) => {
        let selected_quotes = [...selectedQuotes];
        selected_quotes = selected_quotes?.filter(quotes => quotes.financier_id !== activeFinancierQuote?.[0]?.financier_id);
        selected_quotes = selected_quotes.concat(activeQuote);
        setSelectedQuotes(selected_quotes.flat());
    }

    const handleOutofTownChange = (event) => {
        setQuotesArr(currentValue => ({
            ...currentValue,
            "out_of_town_charge": parseInt(event.target.value) || ""
        }))
        setLoanVehicleDetails(currentValue => ({
            ...currentValue,
            "out_of_town_charges": parseInt(event.target.value) || ""
        }))
    }

    const resetTabData = () => {
        setQuotesArr({});
        setSelectedFinanciers([]);
        setSelectedQuotes([]);
        setActiveFinancierQuote([]);
    }

    // console.log("===========activeFinancierQuote", activeFinancierQuote)
    // console.log("========selected quotes ", selectedQuotes);

  return (
    <div className="">
      {loading ? <Loader /> : null}
      <div className="doument-gallery-tabs">
        <Tab.Container id="left-tabs-example" activeKey={selectedTab} defaultActiveKey="first" onSelect={(e) => setSelectedTab(e)}>
            <Nav variant="pills" className="flex-column">
                <div className="tab-list">
                    <Nav.Item>
                        <Nav.Link eventKey="first">Vehicle Details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item >
                        <Nav.Link eventKey="second">Quote Generation</Nav.Link>
                    </Nav.Item>
                    <Nav.Item >
                        <Nav.Link eventKey="third">Quote Selection</Nav.Link>
                    </Nav.Item>
                </div>
            </Nav>
            <Tab.Content>
                <Tab.Pane eventKey="first">
                    <LoanVehicleDetails loanVehicleDetails={loanVehicleDetails} setLoanVehicleDetails={setLoanVehicleDetails} allOptions={props.allOptions} setAllOptions={props?.setAllOptions} getMake={props.getMake} getModel={props.getModel} getVersion={props.getVersion} addValidationRule={props.addValidationRule} getQuotes={getQuotes} resetTabData={resetTabData}></LoanVehicleDetails>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                    <div className='financer-quote-outer'>
                        { 
                            Object.keys(quotesArr)?.length && quotesArr?.quotes?.length ? 
                            <>
                            {quotesArr?.quotes?.map((quote) => (
                                <div className='financer-quote-card'>
                                    <div className='financer-deatil'>
                                        <div className='financer-name-logo d-flex'>
                                            <div className='finanacer-logo'>
                                                {<img src={quote.logo} alt='brand logo' className="" />}
                                            </div>
                                            <div className='financer-car-detail'>
                                                <span>{quote?.name}</span>
                                                <label>{quotesArr?.make_name + " " + quotesArr?.model_name + " " + quotesArr?.variant_name + " " + quotesArr?.make_year}</label>
                                            </div>
                                        </div>
                                        <div className='check-approve-status'>
                                            <div class="material">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" name={quote.name} aria-label="sameas cra" value={quote.financier_id} onClick={(e) => handleCheckBoxChange(e, quote.financier_id)} checked={selectedFinanciers.includes(quote.financier_id)} />
                                                    <label data-label="form" for={quote.name} class="custom-control-label">
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='car-finance-detail quote-selection'>
                                        <ul>
                                            <li>
                                                <span>Fair Market Value of Car</span>
                                                <label>Rp {quotesArr.car_fmv}</label>
                                            </li>
                                            <li>
                                                <span>Loan Amount</span>
                                                <label>Rp {quote.loan_amount}</label>
                                            </li>
                                            <li>
                                                <span>Down Payment</span>
                                                <label>Rp {quote.dp_amount}</label>
                                            </li>
                                            <li>
                                                <span>Installment Amount</span>
                                                <label>Rp {quote.installment_amount}</label>
                                            </li>
                                            <li>
                                                <span>No. of Installments</span>
                                                <label>{quotesArr?.tenure_in_month}</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ))}
                            <div class="btn-save-remarks">
                                <button type="button" class="btn-primary" disabled={!selectedFinanciers?.length} onClick={handleQuoteGeneration}>Save & Continue</button>
                            </div>
                            </> : <span>No Quotes Available</span>
                        }
                    </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                    <>
                    <div className="">
                        <Tab.Container id="left-tabs-example" activeKey={activeFinancier} onSelect={(tab, e) => onTabChange(tab, e)} defaultActiveKey="finance-0">
                            <Nav variant="pills" className="flex-column financier-pills">
                            <div className="tab-list">
                                { selectedFinanciers?.length ? selectedFinanciers?.map((fn,key)=>(
                                    <Nav.Item>
                                        <Nav.Link eventKey={"finance-"+key} financeId={parseInt(fn)}>{selectedQuotes?.find(quotes => quotes.financier_id === fn)?.name || ""}</Nav.Link>
                                    </Nav.Item>
                                )) : null}
                            </div>
                            </Nav>
                        </Tab.Container>
                    </div>
                    <div className='financer-quote-outer qutote-section'>
                        {
                            activeFinancierQuote?.length ? activeFinancierQuote?.map((quote, index) => (
                            <div className='financer-quote-card'>
                                <div className='car-finance-detail'>
                                   <div className='quote-selectioin-checkbox' style={{display : (activeFinancierQuote?.length > 1) ? "block" : "none"}}>
                                            <div className="custom-control custom-checkbox check-box-small">
                                                <input
                                                    id="summary_radio"
                                                    type="radio"
                                                    onChange={e => handleRadioChange(index)}
                                                    name={"summary_radio"}
                                                    className="custom-control-input"
                                                    checked={quote?.is_checked === true ? true : false}
                                                    aria-label="summary radio"
                                                />{" "}
                                                <label className="custom-control-label"></label>
                                            </div>
                                        </div>
                                    <ul>                                       
                                        <li>
                                            <span>Loan Amount</span>
                                            <label>Rp {quote?.loan_amount}</label>
                                        </li>
                                        <li>
                                            <span>Down Payment</span>
                                            <label>Rp {quote?.dp_amount}</label>
                                        </li>
                                        <li>
                                            <span>Interest Rate</span>
                                            <label>
                                                <div className="single-select quote-select">
                                                <Select
                                                    options={[...new Set(
                                                    quote?.chattel_fee_info
                                                        ?.filter(data => data?.chattel_percent === quote?.chattel_percent)
                                                        ?.map(chattel => chattel?.interest_rate)
                                                    )].map(rate => ({
                                                    label: `${rate}%`,
                                                    value: rate
                                                    })) || []}                                                    
                                                    placeholder={""}
                                                    className='quote-single-select'
                                                    classNamePrefix={"quote-single-select"}
                                                    name={"interest_rate"}
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    value={{label: String(quote?.interest_rate)+"%", value: quote?.interest_rate}}
                                                    onChange={ (e) => handleEditOptionsChange(e, "interest_rate", index) }
                                                    aria-label="search type"
                                                />
                                            </div>
                                            </label>
                                        </li>                                        
                                        <li>
                                            <span>Installment Amount</span>
                                            <label>Rp {quote?.installment_amount}</label>
                                        </li>                                        
                                        <li>
                                            <span>No. of Installments</span>
                                            <label>{quotesArr?.tenure_in_month}</label>
                                        </li>
                                        
                                        
                                        <li>
                                            <span>Dealer's Incentive</span>
                                            <label>Rp {quote?.dealer_incentive}</label>
                                        </li>
                                        <li>
                                            <span>Chattel Fee %age</span>
                                            <label>
                                            <div className="single-select quote-select">
                                                <Select
                                                    options={ quote?.chattel_fee_info?.filter((obj, index, self) =>
                                                        index === self.findIndex((t) => t.chattel_percent === obj.chattel_percent))?.map(chattel => { return {label: String(chattel.chattel_percent)+"%", value: chattel?.chattel_percent }}) || []}
                                                    placeholder={""}
                                                    name={"chattel_percent"}
                                                    className='quote-single-select'
                                                    classNamePrefix={"quote-single-select"}
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    value={{label: String(quote?.chattel_percent)+"%", value: quote?.chattel_percent}}
                                                    onChange={ (e) => handleEditOptionsChange(e, "chattel_percent", index) }
                                                    aria-label="search type"
                                                />
                                            </div>
                                            </label>
                                        </li>
                                        <li>
                                            <span>Chattel Fee</span>
                                            <label>Rp {quote?.chattel_fee}</label>
                                        </li>
                                        <li>
                                            <span>Processing Fee</span>
                                            <label>Rp {quote?.processing_fee}</label>
                                        </li>
                                        <li>
                                            <span>Out of Town Charges</span>
                                            <label className="out-of-town-charge">Rp 
                                                <div class="material">
                                                    <input 
                                                        id="out_of_town" 
                                                        type="number" 
                                                        className="form-input" 
                                                        name="out_of_town" 
                                                        aria-label="handle change radio" 
                                                        value={quotesArr?.out_of_town_charge} 
                                                        onChange={(e) => handleOutofTownChange(e)}
                                                    />
                                                </div>
                                            </label>
                                        </li>
                                        <li>
                                            <span>FMV of Car</span>
                                            <label>Rp {quotesArr?.car_fmv}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            )) : <span>No Quotes Available</span>
                        }
                    </div>

                    { !addRequiredDp.includes(activeFinancierQuote?.[0]?.financier_id) && activeFinancierQuote?.length ? <div class="btn-save-remarks p-md-t p-md-b">
                        <button type="button" class="btn-line" onClick={onAddRequiredDpClick}>Add Required DP</button>
                    </div> : null }

                    { addRequiredDp.includes(activeFinancierQuote?.[0]?.financier_id) ?
                        <div className='amount-finance-outer m-md-t m-md-b'>
                            <h3>Add Required DP</h3>
                                <fieldset className="form-filed radio-btn radio-type-txt">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="1" 
                                            type="radio" 
                                            className="custom-control-input" 
                                            name="ongoing_promo" 
                                            aria-label="handle change radio" 
                                            value={`per_`+activeFinancierQuote?.[0]?.financier_id} 
                                            defaultChecked={requiredDpOptions.find((options) => options.financier_id === activeFinancierQuote?.[0]?.financier_id)?.option_selected === `per_`+activeFinancierQuote?.[0]?.financier_id} 
                                            onClick={(e) => handleDpOptionsChange(e, "option_selected", activeFinancierQuote?.[0]?.financier_id, "per_"+activeFinancierQuote?.[0]?.financier_id)}
                                        />
                                        <label className="custom-control-label">Percentage (%)</label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="0" 
                                            type="radio" 
                                            className="custom-control-input" 
                                            name="ongoing_promo" 
                                            aria-label="handle change radio" 
                                            value={`amount_`+activeFinancierQuote?.[0]?.financier_id} 
                                            defaultChecked={requiredDpOptions.find((options) => options.financier_id === activeFinancierQuote?.[0]?.financier_id)?.option_selected === `amount_`+activeFinancierQuote?.[0]?.financier_id} 
                                            onClick={(e) => handleDpOptionsChange(e, "option_selected", activeFinancierQuote?.[0]?.financier_id, "amount_"+activeFinancierQuote?.[0]?.financier_id)}
                                        />
                                        <label className="custom-control-label">Amount</label>
                                    </div>
                                </fieldset>

                                <div className='dp-amt-btn'>
                                    <fieldset class="single-select">
                                        { requiredDpOptions.find((options) => options.financier_id === activeFinancierQuote?.[0]?.financier_id)?.option_selected === `per_`+activeFinancierQuote?.[0]?.financier_id &&
                                        <div className="material">
                                            <Select
                                            options={allOptions?.['dp_value'] || []}
                                            placeholder={"Select DP %"}
                                            name={`percentage_`+activeFinancierQuote?.[0]?.financier_id}
                                            optionLabel="label"
                                            optionValue="value"
                                            value={ allOptions?.['dp_value']?.find((options) => parseInt(options?.value) === requiredDpOptions.find((options) => options.financier_id === activeFinancierQuote?.[0]?.financier_id)?.required_per)  || ""}
                                            onChange={ (e) => handleDpOptionsChange(e, "required_per", activeFinancierQuote?.[0]?.financier_id) }
                                            aria-label="search type"
                                            />
                                        </div>
                                        }
                                        { requiredDpOptions.find((options) => options.financier_id === activeFinancierQuote?.[0]?.financier_id)?.option_selected === `amount_`+activeFinancierQuote?.[0]?.financier_id &&
                                        <div class="material">
                                            <input 
                                                id={`amount_`+activeFinancierQuote?.[0]?.financier_id} 
                                                name={`amount_`+activeFinancierQuote?.[0]?.financier_id} 
                                                type="text" 
                                                placeholder=" " 
                                                label="Add Required Amount" 
                                                autocomplete="new-off" 
                                                class="form-input" 
                                                title="" 
                                                value={ requiredDpOptions.find((options) => options.financier_id === activeFinancierQuote?.[0]?.financier_id)?.required_amt || "" }
                                                onChange={ (e) => handleDpOptionsChange(e, "required_amt", activeFinancierQuote?.[0]?.financier_id) } 
                                            />

                                            <label data-label="Add Required Amount" class="form-label "></label>
                                        </div>
                                        }
                                    </fieldset>
                                    <button className='btn-primary' type="button" disabled={(isMaxDpAmount || !requiredDpOptions.find((options) => options.financier_id === activeFinancierQuote?.[0]?.financier_id)?.required_amt) && !requiredDpOptions.find((options) => options.financier_id === activeFinancierQuote?.[0]?.financier_id)?.required_per} onClick={calculateLoan}>Submit</button>
                                </div>
                                {isMaxDpAmount ? <div class="error-msg-txt">Amount must be less than FMV</div>: ""}
                        </div> : null
                    }
                    { activeFinancierQuote?.length ?
                        <div class="btn-save-remarks">
                            <button type="button" class="btn-primary" disabled={ isMaxDpAmount || !selectedFinanciers?.length || !selectedQuotes?.length} onClick={createLeadQuote}>Save & Continue</button>
                        </div> : null
                    }
                    </>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    </div>
    </div>
  );
}
export default LoanQuoteTabs;