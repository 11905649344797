import React, { useEffect, useState } from 'react';
import {  Tab, Nav } from 'react-bootstrap';
import dateFormat from "dateformat";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import { useApolloClient } from '@apollo/client';
import { DOCUMENT_LIST, SEND_DOC_TO_FINANCEIR, UPDATE_LEAD_STATUS } from '../../services/leads.gql';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../elements/Modal';
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import { getLoanDetail } from '../../store/action/allAction';
import FormsyInputField from '../elements/FormsyInputField';
import Formsy from 'formsy-react';
import Loader from '../elements/Loader';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, prepareTemplateDataIfExist, SUB_STATUS_CODES } from '../../../src/config/constants';
import { useParams } from "react-router-dom";
import crypto from '../../config/crypto'
import FormsySelect from '../elements/FormsySelect';
import { GET_REGION_CITY_LIST, GET_LOCALITY_LIST } from '../../services/config.gql';



const SendDoc = (props) => {
  const { docList, markAsFreezed } = useOutletContext();
  const [ciname] = useState("");
  const [cicontact] = useState("");
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [CityList, setCityList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [craBarangayList, setCraBarangayList] = useState([]);  
  const [paBrangayList, setPaBarangayList] = useState([]);
  const [cbCraBarangayList, setCbCraBarangayList] = useState([]); 
  const [cbPaBarangayList, setCbPaBarangayList] = useState([]);  
  
  let { lead_id } = useParams();
  let navigate = useNavigate();
  const leadDetail = useSelector(state => state.lead.leadDetail);
  let availabledoc = [];
   let first_submit_doc = docList && docList.filter((v) => v.tab_name === 'first_submit');
  leadDetail?.leaddocs?.length > 0 && leadDetail.leaddocs.forEach((leaddoc) => {
    availabledoc.push(leaddoc.doc_id)
  });

  docList && docList.forEach((doc, key) => {
    docList[key]['child_ids'] = doc.child.map((dc) => { return dc.id })
    docList[key]['child_ids_exists'] = availabledoc.some((v) => { return docList[key]['child_ids']?.indexOf(v) !== -1 })
  });

  const { remarksHistory } = useSelector(({ lead, masterdata }) => ({
    masterdata,
    remarksHistory: lead.remarksHistory || []
  }));

  const CI_SUB_STATUS_REASON=[
    { value: 'Details Sent to Financier for Credit Investigation', label: 'Details Sent to Financier for Credit Investigation' },
  ]

  const user_information = useSelector((state) => state.user.user_information);
  const bank_list = useSelector((state) => state.masterdata.data.bank_list);
  const UserInfo = JSON.parse(user_information)

  const [displayBlock, setDisplayBlock] = useState('DocumentList');
  const [FinancerEmail, SetFinancerEmail] = useState(false);
  const [FinancerWhatsApp, SetFinancerWhatsApp] = useState(false);
  const [FinancerViber, SetFinancerViber] = useState(false);
  const [templateList, SetTemplateList] = useState([]);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [financiers, setFinanciers] = useState([]);
  const [selectedfinanciers, setSelectedfinanciers] = useState([]);
  const [currentFinancer,setCurrentFinancer]=useState();
  let accessCondition = !IS_USER_ACCESS_PAGE(props.template_for, props.template_for)?.is_edit_access;
  let isApplicationCreated = leadDetail?.lead_status_history?.find(history => history.sub_status_id === SUB_STATUS_CODES["APPLICATION_CREATED"]);
  if (!accessCondition) {
    accessCondition = CHECK_ASSIGN_USER(leadDetail?.assign_to);
  }

  const showModalFinancerEmail = (financier_id) => {
    setCurrentFinancer(financier_id)
    SetFinancerEmail(true);
    document.body.classList.add("overflow-hidden");
  }
  const showModalFinancerWhatsApp = (financier_id) => {
    setCurrentFinancer(financier_id)
    SetFinancerWhatsApp(true);
    document.body.classList.add("overflow-hidden");
  }

  const showModalFinancerViber = (financier_id) => {
    setCurrentFinancer(financier_id)
    SetFinancerViber(true);
    document.body.classList.add("overflow-hidden");
  }

  const hideModalFinancerEmail = () => {
    SetFinancerEmail(false);
    document.body.classList.remove("overflow-hidden");
  }
  const hideModalFinancerWhatsApp = () => {
    SetFinancerWhatsApp(false);
    document.body.classList.remove("overflow-hidden");
  }

  const hideModalFinancerViber = () => {
    SetFinancerViber(false);
    document.body.classList.remove("overflow-hidden");
  }



  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const client = useApolloClient();
  const dispatch = useDispatch();

  const getRegionCityList = () => {
    executeGraphQLQuery(GET_REGION_CITY_LIST(), client)
    .then(resp => {
      if (resp && resp.data && resp.data.get_region_city_list) {
        let { region_list, city_list } = resp.data.get_region_city_list || [];
        setRegionList(region_list);
        setCityList(city_list);
      }
    })
    .catch(err => {
      console.log(err);
    })
  }

  const getLocalityList = (city_id, field_name) => {
    executeGraphQLQuery(GET_LOCALITY_LIST(city_id), client)
    .then(resp => {
      if (resp && resp.data && resp.data.get_locality_list) {
        let { locality_list } = resp.data.get_locality_list || [];
        if (field_name === "cra_city") {
          setCraBarangayList(locality_list)
        } else if (field_name === "pa_city") {
          setPaBarangayList(locality_list);
        } else if (field_name === "cb_cra_city") {
          setCbCraBarangayList(locality_list);
        }  else if (field_name === "cb_pa_city") {
          setCbPaBarangayList(locality_list);
        }
      }
    })
    .catch(err => {
      console.log(err);
    })
  }


  const selectdocument = (e, financier_id) => {
    let selectedFinancerTemp = selectedfinanciers;
    const findIndex = selectedFinancerTemp.findIndex((fn) => Number(fn.financier_id) === financier_id);
    let document_category_ids = selectedFinancerTemp[findIndex]?.document_category_ids;
    if (e.target.getAttribute('parent_id')) {
      if (e.target.checked) {
        if (document_category_ids) {
          const index = document_category_ids.indexOf(e.target.value);
          if (index === -1) {
            document_category_ids.push(Number(e.target.value));
          }
        } else {
          document_category_ids = [Number(e.target.value)];
        }
      } else {
        const index = document_category_ids.indexOf(Number(e.target.value));
        if (index > -1) {
          document_category_ids.splice(index, 1);
        }
      }

      let parent_doc_id = Number(e.target.getAttribute('parent_id'));
      let rowdata = docList.filter((data) => data.id === parent_doc_id);
      let allFounded = rowdata[0].child.every(ai => document_category_ids.includes(ai.id))
      if (allFounded) {
        document_category_ids.push(parent_doc_id);
      } else {
        const index = document_category_ids.indexOf(parent_doc_id);
        if (index > -1) {
          document_category_ids.splice(index, 1);
        }
      }
      let uniqueselectedTagImageIdsData = [...new Set(document_category_ids)];
      selectedFinancerTemp[findIndex].document_category_ids = uniqueselectedTagImageIdsData;
      setSelectedfinanciers([...selectedfinanciers]);
    }
    else {
      if (e.target.checked) {
        let rowdata = docList.filter((data) => data.id === Number(e.target.value));
        //document_category_ids.push(Number(e.target.value));

        if (document_category_ids) {
          const index = document_category_ids.indexOf(e.target.value);
          if (index === -1) {
            document_category_ids.push(Number(e.target.value));
          }
        } else {
          document_category_ids = [Number(e.target.value)];
        }

        rowdata[0].child.map((ch, i) => {
          return document_category_ids.push(ch.id);
        })
      } else {
        let rowdata = docList.filter((data) => data.id === Number(e.target.value));
        rowdata[0].child.map((ch, i) => {
          const index = document_category_ids.indexOf(ch.id);
          if (index > -1) {
            return document_category_ids.splice(index, 1);
          }
          return true;
        });
        const index = document_category_ids.indexOf(Number(e.target.value));
        if (index > -1) {
          document_category_ids.splice(index, 1);
        }
      }
      let uniqueselectedTagImageIdsData = [...new Set(document_category_ids)];
      selectedFinancerTemp[findIndex].document_category_ids = uniqueselectedTagImageIdsData;
      setSelectedfinanciers([...selectedfinanciers]);
    }
  }

  const selectFinancer = (e) => {
    if (e.target.checked) {
      if(props.template_for==="credit_investigation"){
        let selectedFinancerTemp = selectedfinanciers;
        let counter=0;
        for (const sf of selectedFinancerTemp){
          let soft_approval_ci_data=leadDetail?.soft_approval_ci_data?.filter((ci)=>ci.financier_id===sf.financier_id)?.[0];
          if(!soft_approval_ci_data?.credit_investigation_recieved_date && soft_approval_ci_data?.credit_investigation){
            toast.warning("Credit Investigation Not Received Yet");
            counter++;
            break;
          }
        }
        if(counter===0){
            let selectedFinancerTemp1=[];   
            let selectedfinanciersPast=selectedfinanciers.filter((sf)=>sf.check_unchecked);
            selectedFinancerTemp1.push({ financier_id: Number(e.target.value) });
            setSelectedfinanciers([...selectedfinanciersPast, ...selectedFinancerTemp1])
        }
      }else{
        let selectedFinancerTemp = selectedfinanciers;
        selectedFinancerTemp.push({ financier_id: Number(e.target.value) });
        setSelectedfinanciers([...selectedfinanciers])
      }
    } else {
      const findIndex = selectedfinanciers.findIndex((fn) => fn.financier_id === Number(e.target.value));
      findIndex !== -1 && selectedfinanciers.splice(findIndex, 1)
      setSelectedfinanciers([...selectedfinanciers])
    }
  }

  const replaceAll = (str, mapObj) => {
    var re = new RegExp(Object.keys(mapObj).join("|"), "gi");

    return str.replace(re, function (matched) {
      return mapObj[matched];
    });
  }


  useEffect(() => {
    
    getRegionCityList()
    if (leadDetail?.customer?.cra_city) {
      getLocalityList(leadDetail?.customer?.cra_city, "cra_city")
    }
    if (leadDetail?.customer?.pa_city) {
      getLocalityList(leadDetail?.customer?.pa_city, "pa_city")
    } 
    if (leadDetail?.co_borrower?.cb_cra_city) {
      getLocalityList(leadDetail?.co_borrower?.cb_cra_city, "cb_cra_city")
    } 
    if (leadDetail?.co_borrower?.cb_pa_city) {
      getLocalityList(leadDetail?.co_borrower?.cb_pa_city, "cb_pa_city")
    } 
    
    let allfinancer = leadDetail?.financier?.map((mp) => mp?.financier_id);
    executeGraphQLQuery(DOCUMENT_LIST(allfinancer, props.template_for), client).then((result) => {
      //  let allfinancer=leadDetail && leadDetail?.financier && leadDetail?.financier?.map((fn)=> {fn.financier_id});
      if(props.template_for==="send_to_financier")
        setFinanciers(leadDetail?.financier);
      else if(props.template_for==="credit_investigation"){
        let financers=leadDetail?.soft_approval_ci_data?.filter((sa)=> sa.is_soft_approval_received==="yes")?.map((newfn)=> {
          return leadDetail?.financier.filter((fl)=>fl.financier_id===newfn.financier_id)?.[0]
        });
        setFinanciers(financers);
      }
      // else{
      //   let financers=leadDetail?.financier?.filter((fn)=>fn?.is_proceed==="yes");
      //   setFinanciers(financers);
      // }
      /*Check template type*/
      let templates = result.data.financierListbyID;
      let send_mode_arr = [], send_mode_checked = {}, send_mode_disabled = {},templateInfo={},pastfinancersArr=[],pastfinancer={},document_category_ids=[];
      let loandetailsfield = {
        "customer_name": leadDetail?.customer?.name || "",
        "customer_mobile_number": leadDetail?.customer?.phone || "",
        "customer_alternative_mobile_number": leadDetail?.customer?.alt_phone || "",
        "customer_email_id": leadDetail?.customer?.email || "",
        "customer_dob": dateFormat(leadDetail?.customer?.dob, "dd mmm, yyyy") || "",
        "customer_gender": leadDetail?.customer?.gender || "",
        "customer_civil_status": leadDetail?.customer?.marital_status || "",
        "customer_nationality": leadDetail?.customer?.nationality || "",
        "customer_type": leadDetail?.customer?.customer_type || "",
        "customer_primary_doc_id_card_category": leadDetail?.customer?.primary_doc_id || "",
        "customer_primary_doc_id_card_number": leadDetail?.customer?.primary_card_no || "",
        "customer_secondary_doc_id_card_category": leadDetail?.customer?.secondary_doc_id || "",
        "customer_secondary_doc_id_card_number": leadDetail?.customer?.secondary_card_no || "",
        "customer_educational_attainment": leadDetail?.customer?.qualification || "",
        "number_of_dependents": leadDetail?.customer?.no_of_dependent || "",
        "customer_father_name": leadDetail?.customer?.father_name || "",
        "customer_mother_maiden_name": leadDetail?.customer?.mother_maiden_name || "",
        "customer_facebook_id": leadDetail?.customer?.facebook_url || "",
        "customer_ttn": leadDetail?.customer?.tin_number || "",
        "employee_type": "" || "",
        "company_name": leadDetail?.customer?.employment?.employer_name || "",
        "company_address": leadDetail?.customer?.employment?.business_address || "",
        "company_contact_no": leadDetail?.customer?.employment?.company_phone || "",
        "industry": leadDetail?.customer?.employment?.industry || "",
        "designation": leadDetail?.customer?.employment?.designation || "",
        "years_with_the_company": leadDetail?.customer?.employment?.year_with_company || "",
        "gross_monthly_income": leadDetail?.customer?.employment?.gross_monthly_income || "",
        "bank_name": leadDetail?.customer?.bank_details?.bank_id ? bank_list?.filter(v => +v.id === +leadDetail?.customer?.bank_details?.bank_id)?.map(v => v.label)?.[0] : "",
        "branch_name": leadDetail?.customer?.bank_details?.branch_name || "",
        "account_name": leadDetail?.customer?.bank_details?.account_name || "",
        "type_of_account": leadDetail?.customer?.bank_details?.account_type || "",
        "account_number": leadDetail?.customer?.bank_details?.account_number || "",
        "re_enter_account_number": leadDetail?.customer?.bank_details?.account_number || "",
        "house_number": leadDetail?.customer?.cra_house_number || "",
        "customer_street": leadDetail?.customer?.cra_street || "",
        "customer_barangay": craBarangayList?.filter(data => +data.id === +leadDetail?.customer?.cra_barangay).map(v=>v.label)[0] || "",
        "birthplace": CityList?.filter(data => +data.id === +leadDetail?.customer?.birthplace).map(v=>v.label)[0] || "",
        "customer_city": CityList?.filter(data => +data.id === +leadDetail?.customer?.cra_city).map(v=>v.label)[0] || "",
        "customer_region": regionList?.filter(data => +data.id === +leadDetail?.customer?.cra_region).map(v=>v.label)[0] || "",
        "customer_postal_code": leadDetail?.customer?.cra_zipcode || "",
        "customer_residence_type": leadDetail?.customer?.house_type || "",
        "customer_duration_of_stay": leadDetail?.customer?.duration_of_stay || "",
        "customer_permanent_house_number": leadDetail?.customer?.pa_house_number || "",
        "customer_permanent_street": leadDetail?.customer?.pa_street || "",
        "customer_permanent_barangay": paBrangayList?.filter(data => +data.id === +leadDetail?.customer?.pa_barangay).map(v=>v.label)[0] || "",
        "customer_permanent_city": CityList?.filter(data => +data.id === +leadDetail?.customer?.pa_city).map(v=>v.label)[0] || "",
        "customer_permanent_region": regionList?.filter(data => +data.id === +leadDetail?.customer?.pa_region).map(v=>v.label)[0] || "",
        "customer_permanent_postal_code": leadDetail?.customer?.pa_zipcode || "",
        "customer_charref_full_name": leadDetail?.character_ref?.full_name || "",
        "customer_charref_mobile_number": leadDetail?.character_ref?.mobile_number || "",
        "customer_charref_address": leadDetail?.character_ref?.address || "",
        "customer_charref_relationship": leadDetail?.character_ref?.relationship || "",
        "relationship_with_customer": leadDetail?.co_borrower?.relationship_with_customer || "",
        "co-borrower_name": leadDetail?.co_borrower?.cb_name || "",
        "co-borrower_mobile_number": leadDetail?.co_borrower?.cb_phone || "",
        "co-borrower_alternative_mobile_number": leadDetail?.co_borrower?.cb_alt_phone || "",
        "co-borrower_email_id": leadDetail?.co_borrower?.cb_email || "",
        "co-borrower_dob": dateFormat(leadDetail?.co_borrower?.cb_dob, "dd mmm, yyyy") || "",
        "co-borrower_gender": leadDetail?.co_borrower?.cb_gender || "",
        "co-borrower_civil_status": leadDetail?.co_borrower?.cb_marital_status || "",
        "co-borrower_nationality": leadDetail?.co_borrower?.cb_nationality || "",
        "co-borrower_type": leadDetail?.co_borrower?.cb_customer_type || "",
        "co-borrower_primary_doc_id_card_category": leadDetail?.co_borrower?.cb_primary_doc_id || "",
        "co-borrower_primary_doc_id_card_number": leadDetail?.co_borrower?.cb_primary_card_no || "",
        "co-borrower_secondary_doc_id_card_category": leadDetail?.co_borrower?.cb_secondary_doc_id || "",
        "co-borrower_secondary_doc_id_card_number": leadDetail?.co_borrower?.cb_secondary_card_no || "",
        "co-borrower_educational_attainment": leadDetail?.co_borrower?.cb_qualification || "",
        "co-borrower_of_dependents": leadDetail?.co_borrower?.cb_no_of_dependent || "",
        "co-borrower_father_name": leadDetail?.co_borrower?.cb_father_name || "",
        "co-borrower_mother_maiden_name": leadDetail?.co_borrower?.cb_mother_name || "",
        "co-borrower_facebook_id": leadDetail?.co_borrower?.cb_facebook_url || "",
        "co-borrower_ttn": leadDetail?.co_borrower?.cb_tin_number || "",
        "co-borrower_industry": leadDetail?.co_borrower?.employment_details?.cb_industry || "",
        "co-borrower_designation": leadDetail?.co_borrower?.employment_details?.cb_designation || "",
        "co-borrower_years_with_the_company": leadDetail?.co_borrower?.employment_details?.cb_year_with_company || "",
        "co-borrower_gross_monthly_income": leadDetail?.co_borrower?.employment_details?.cb_gross_monthly_income || "",
        "co-borrower_bank_name": leadDetail?.co_borrower?.bank_details?.cb_bank_id ? bank_list?.filter(v => +v.id === +leadDetail?.co_borrower?.bank_details?.cb_bank_id)?.map(v => v.label)?.[0] : "", //leadDetail?.co_borrower?.bank_details?.cb_branch_name || "",
        "co-borrower_branch_name": leadDetail?.co_borrower?.bank_details?.cb_branch_name || "",
        "co-borrower_account_name": leadDetail?.co_borrower?.bank_details?.cb_account_name || "",
        "co-borrower_type_of_account": leadDetail?.co_borrower?.bank_details?.cb_account_type || "",
        "co-borrower_account_number": leadDetail?.co_borrower?.bank_details?.cb_account_number || "",
        "co-borrower_re_enter_account_number": leadDetail?.co_borrower?.bank_details?.cb_account_number || "",
        "co-borrower_house_number": leadDetail?.co_borrower?.cb_pa_house_number || "",
        "co-borrower_street": leadDetail?.co_borrower?.cb_cra_street || "",
        "co-borrower_barangay": cbCraBarangayList?.filter(data => +data.id === +leadDetail?.co_borrower?.cb_cra_city).map(v=>v.label)[0] || "",
        "co-borrower_city":  CityList?.filter(data => +data.id === +leadDetail?.co_borrower?.cb_cra_city).map(v=>v.label)[0] || "",
        "co-borrower_region": regionList?.filter(data => +data.id === +leadDetail?.co_borrower?.cb_cra_region).map(v=>v.label)[0] || "",
        "co-borrower_postal_code": leadDetail?.co_borrower?.cb_cra_zipcode || "",
        "co_borrower_residence_type": leadDetail?.co_borrower?.cb_house_type || "",
        "co_borrower_duration_of_stay": leadDetail?.co_borrower?.cb_duration_of_stay || "",
        "co_borrower_permanent_house_number": leadDetail?.co_borrower?.cb_pa_house_number || "",
        "co_borrower_permanent_street": leadDetail?.co_borrower?.cb_pa_street || "",
        "co_borrower_permanent_barangay": cbPaBarangayList?.filter(data => +data.id === +leadDetail?.co_borrower?.cb_pa_barangay).map(v=>v.label)[0] || "",
        "co-borrower_permannent_city": CityList?.filter(data => +data.id === +leadDetail?.co_borrower?.cb_pa_city).map(v=>v.label)[0] || "",
        "co_borrower_permanent_region": regionList?.filter(data => +data.id === +leadDetail?.co_borrower?.cb_pa_region).map(v=>v.label)[0] || "",
        "co-borrower_permanaet_postal_code": leadDetail?.co_borrower?.cb_pa_zipcode || "",
        "brand": leadDetail?.lead_details?.make_name || "",
        "model": leadDetail?.lead_details?.modal_name || "",
        "variant": leadDetail?.lead_details?.variant_name || "",
        "make_year": leadDetail?.lead_details?.make_year || "",
        "color": leadDetail?.lead_details?.color || "",
        "plate_number": leadDetail?.lead_details?.plate_number || "",
        "ci_contact_person": leadDetail?.lead_details?.ci_name || "",
        "ci_contact_number": leadDetail?.lead_details?.ci_contact || "",
        "ci_notes": "" || "",
        "promo_ongoing": "" || "",
        "computation_fmv": leadDetail?.lead_computation_details?.fmv || "",
        "computation_tenure_in_months": leadDetail?.lead_computation_details?.tenure_in_months || "",
        "computation_downpayment": leadDetail?.lead_computation_details?.down_payment_amount || "",
        "compuatation_emi": leadDetail?.lead_computation_details?.installment || "",
        "compuatation_interest_rate": leadDetail?.lead_computation_details?.interest_rate || "",
        "computation_loan_value": leadDetail?.lead_computation_details?.loan_value || "",
        "computation_chattel_percentage": leadDetail?.lead_computation_details?.chattel_percentage || "",
        "computation_chattel_fee": leadDetail?.lead_computation_details?.chattel_fee || "",
        "computation_out_of_town_changes": leadDetail?.lead_computation_details?.out_of_town_charges || "",
        "computation_total_payment_by_customer": leadDetail?.lead_computation_details?.total_payment_by_customer || "",
        "computation_dealers_incentive_percentage": leadDetail?.lead_computation_details?.dealer_incentive_percentage || "",
        "computation_dealer_incentive_from_financier": leadDetail?.lead_computation_details?.dealer_incentive_from_financier || "",
        "computation_car_mudi_top_up_percentage": leadDetail?.lead_computation_details?.carmudi_top_up_percentage || "",
        "computation_dealer_incentive_from_carmudi": leadDetail?.lead_computation_details?.dealer_incentive_from_carmudi || "",
        "computation_total_dealer_incentive": leadDetail?.lead_computation_details?.total_dealer_incentive || "",
        "computation_dealer_incentive_tax_percentage": leadDetail?.lead_computation_details?.dealer_incentive_tax_percentage || "",
        // "computation_total_dealer_incentive_net_of_tax":leadDetail?.lead_computation_details?.total_dealer_incentive_net_of_tax || "",
        "computation_total_payment_to_dealer": leadDetail?.lead_computation_details?.total_dealer_payment || "",
        "contract_sign_date_time": leadDetail?.lead_status_dates?.custom_contract_signed_date || "",
        "financier_name": leadDetail?.financier?.financierlang?.financier_name || "",
        "dealer_tin": "",
        "dealer_address": "",
        "dealer_longitude": "",
        "dealer_latitude": "",
        "dealer_phone_number": leadDetail?.dealerData?.[0]?.dealership_contact || "",
        "dealer_email": leadDetail?.dealerData?.[0]?.dealership_email || "",
        "dealer_bank_name": leadDetail?.dealerData?.[0]?.bank_name || "",
        "dealer_bank_branch": leadDetail?.dealerData?.[0]?.branch_name || "",
        "dealer_account_name": leadDetail?.dealerData?.[0]?.account_holder_name || "",
        "dealer_account_number": leadDetail?.dealerData?.[0]?.account_number || "",
        "dealer_id_code": leadDetail?.dealerData?.[0]?.gcd_code || "",
        "soft_approval_notes": leadDetail?.lead_details?.soft_approval_reference_notes || "",
        "soft_approval_ref_number": leadDetail?.lead_details?.soft_approval_reference_number || "",
        "soft_approval_received_date_time": leadDetail?.lead_status_dates?.soft_approval_received || "",
        "credit_advise_notes": leadDetail?.lead_details?.credit_advice_reference_notes || "",
        "credit_advice_ref_number": leadDetail?.lead_details?.credit_advice_reference_number || "",
        "signed_contract_ref_number": leadDetail?.lead_details?.contract_signing_reference_number || "",
        "credit_advice_received_date_time": leadDetail?.lead_status_dates?.credit_advice_received || "",
        "car_handover_notes": leadDetail?.lead_details?.car_handover_reference_notes || "",
        "car_handover_date_time": leadDetail?.lead_status_dates?.car_handover || "",
        "orcr_transferred_date_time": leadDetail?.lead_status_dates?.orcr_verified_by_financier || "",
        "accreditation_date": leadDetail?.lead_details?.accreditation_date || "",
      };
      const quoteDetails = leadDetail?.quote_data?.reduce((result, quote)=>{
        const preparedData = prepareTemplateDataIfExist(quote, [["car_fmv", "fmv"], ["dp_amount", "down_payment"], ["tenure_in_month", "tenure_in_months"], "interest_rate", ["installment_amount", "emi"], ["loan_amount", "loan_value"], "chattel_fee", ["chattel_percent", "chattel_percentage"], "out_of_town_charges", "total_payment_by_customer", ["dealer_incentive_percent", "dealers_incentive_percenatge"], ["carmudi_top_up_percent", "carmudi_top_up_percenatge"], "dealer_incentive_from_carmudi", "total_dealer_incentive", ["dealer_incentive_tax_percentage", "dealer_incentive_tax_percenatage"], "total_payment_to_dealer", ["incentive_amount_received", "dealer_incentive_from_financier"], "total_dealer_incentive_net_of_tax"])
        result[quote?.financier_id] = preparedData;
        return result;
      },{});

      let mapObjeConstant = {}
      for (const property in loandetailsfield) {
        if (loandetailsfield[property] === undefined || loandetailsfield[property] === null) {
          mapObjeConstant[`{{${property}}}`] = "";
        } else {
          mapObjeConstant[`{{${property}}}`] = loandetailsfield[property];
        }
      }

      // let status_history = leadDetail?.lead_status_history?.filter((tsh) => (tsh.sub_status_id === 3 && "send_to_financier" === props.template_for) || (tsh.sub_status_id === 5 && "credit_investigation" === props.template_for) || (tsh.sub_status_id === 14 && "orcr_submission_to_financier" === props.template_for))?.length;
      let tempdata = [];
      for (const fn of leadDetail?.financier) { 
        templateInfo = {};send_mode_arr=[];send_mode_disabled={};send_mode_checked={};pastfinancer={};document_category_ids=[];
        templateInfo['financier_id'] = fn.financier_id;

        let soft_approval_ci_data=leadDetail?.soft_approval_ci_data?.filter((ci)=>ci.financier_id===fn.financier_id)?.[0];
        for (const element of templates.filter((temp) => temp.financier_id === fn.financier_id)) { 
          if (element.template_type === 'email') {
            templateInfo['email_enable'] = true;
            let pasttemplate = leadDetail?.lead_template_history?.filter((th) => th.template_type === 'email' && th.financier_id===fn.financier_id && th.page_type === props.template_for);

            if (pasttemplate && pasttemplate.length > 0)
              element.template_body = pasttemplate?.[0]?.send_html_template;
            else
              element.template_body = replaceAll(element.template_body, {...mapObjeConstant, ...(quoteDetails[fn?.financier_id] || {})});
            templateInfo['email'] = element;
            let temphistory =  leadDetail?.lead_template_history?.filter((th) =>(th.template_type === 'email' && th.financier_id===fn.financier_id && th.status === '1' && th.page_type === props.template_for)) ;

            if (temphistory.length>0 ) {
              pastfinancer['financier_id']=fn.financier_id;
              send_mode_checked.email = true;
              send_mode_disabled.email = true;
              document_category_ids=temphistory?.[0]?.doc_ids?.split(',');
              send_mode_arr.push("EMAIL");
            } else {
              send_mode_checked.email = false;
              send_mode_disabled.email = false;
            }
            setDisplayBlock("DocumentList");

          } else if (element.template_type === 'whatsapp') {
            element.template_body = replaceAll(element.template_body, {...mapObjeConstant, ...(quoteDetails[fn?.financier_id] || {})});
            templateInfo['whatsapp_enable'] = true;
            let pasttemplate = leadDetail?.lead_template_history?.filter((th) => th.template_type === 'whatsapp' && th.financier_id===fn.financier_id && th.page_type === props.template_for);
            if (pasttemplate && pasttemplate.length > 0)
              element.template_body = pasttemplate?.[0]?.send_html_template;
            else
              element.template_body = replaceAll(element.template_body, {...mapObjeConstant, ...(quoteDetails[fn?.financier_id] || {})});

            templateInfo['whatsapp'] = element;
            let temphistory = leadDetail?.lead_template_history?.filter((th) => th.template_type === 'whatsapp' && th.financier_id===fn.financier_id && th.status === '1' && th.page_type === props.template_for);

            if (temphistory.length>0 ) {
              pastfinancer['financier_id']=fn.financier_id;
              send_mode_checked.whatsapp = true;
              send_mode_disabled.whatsapp = true;
              send_mode_arr.push("WHATSAPP");
              document_category_ids=temphistory?.[0]?.doc_ids?.split(',');
            } else {
              send_mode_checked.whatsapp = false;
              send_mode_disabled.whatsapp = false;
            }

            setDisplayBlock("DocumentList")
          }
          else if (element.template_type === 'viber') {
            element.template_body = replaceAll(element.template_body, {...mapObjeConstant, ...(quoteDetails[fn?.financier_id] || {})});
            templateInfo['viber_enable'] = true;
            let pasttemplate = leadDetail?.lead_template_history?.filter((th) => th.template_type === 'viber' && th.financier_id===fn.financier_id && th.page_type === props.template_for);
            if (pasttemplate && pasttemplate.length > 0)
              element.template_body = pasttemplate?.[0]?.send_html_template;
            else
              element.template_body = replaceAll(element.template_body, {...mapObjeConstant, ...(quoteDetails[fn?.financier_id] || {})});

              templateInfo['viber'] = element;

            let temphistory = leadDetail?.lead_template_history?.filter((th) => th.template_type === 'viber' && th.financier_id===fn.financier_id && th.status === '1' && th.page_type === props.template_for);

            if (temphistory.length>0 ) {
              pastfinancer['financier_id']=fn.financier_id;
              send_mode_checked.viber = true;
              send_mode_disabled.viber = true;
              send_mode_arr.push("VIBER");
              document_category_ids=temphistory?.[0]?.doc_ids?.split(',');
            } else {
              send_mode_checked.viber = false;
              send_mode_disabled.viber = false;
            }

            setDisplayBlock("DocumentList")
          }
          
        }
       // })
        templateInfo['send_mode_checked']=send_mode_checked;
        templateInfo['send_mode_disabled']=send_mode_disabled;
        templateInfo['send_mode_arr']=send_mode_arr;
        document_category_ids=document_category_ids.map((dcid)=>Number(dcid));
        pastfinancer['send_mode_checked']=send_mode_checked;
        pastfinancer['send_mode_disabled']=send_mode_disabled;
        pastfinancer['send_mode_arr']=send_mode_arr;
        pastfinancer['document_category_ids']=[];
        pastfinancer['ci_name']=soft_approval_ci_data?.ci_name ? soft_approval_ci_data?.ci_name : "";
        pastfinancer['ci_contact']=soft_approval_ci_data?.ci_contact ? soft_approval_ci_data?.ci_contact : "";
        pastfinancer['ci_sub_status_reason']=soft_approval_ci_data?.ci_sub_status_reason ? soft_approval_ci_data?.ci_sub_status_reason : "";
        pastfinancer['save_button_disabled']=soft_approval_ci_data?.credit_investigation_recieved_date ? true : false;
        pastfinancer['ci_done']=leadDetail?.soft_approval_ci_data?.filter((ld)=>ld?.is_credit_investigation_received==="yes")?.length ? true : false;
        pastfinancer['save_disabled_sent_to_financer']=soft_approval_ci_data?.is_soft_approval_received==="no";
        if(props.template_for==="credit_investigation"){
          pastfinancer['datetimevalue']=soft_approval_ci_data?.credit_investigation ? `Sent on ${dateFormat(soft_approval_ci_data?.credit_investigation,'d mmm yyyy h:MM TT')}` : "";
          pastfinancer['is_completed']=((leadDetail?.lead_status_history?.filter((ls)=> ls.sub_status_id===28).length>0) || soft_approval_ci_data?.credit_investigation_recieved_date);
          pastfinancer['check_unchecked']=soft_approval_ci_data?.credit_investigation;
        }else{
          pastfinancer['is_completed']=((leadDetail?.lead_status_history?.filter((ls)=> ls.sub_status_id===28)).length>0 || soft_approval_ci_data?.credit_investigation_recieved_date);
          pastfinancer['datetimevalue']=soft_approval_ci_data?.send_to_financier ? `Sent on ${dateFormat(soft_approval_ci_data?.send_to_financier,'d mmm yyyy h:MM TT')}` : "";
        }
        tempdata.push(templateInfo);
        pastfinancersArr.push(pastfinancer);
      //})
      }
// console.log(pastfinancersArr,"tttttttttttttttttttttt",tempdata)
      SetTemplateList(tempdata);
      setSelectedfinanciers(pastfinancersArr);
      /*End check template type*/


    })
      .catch((errors) => {
        toast.error("Somethig went wrong ,please try again")
      });
  }, [leadDetail, CityList.length])

  const toggleswitch = (valueMode, event, financier_id) => {
    let selectedFinancerTemp = selectedfinanciers;
    const findIndex = selectedfinanciers.findIndex((fn) => Number(fn.financier_id) === Number(financier_id));
    let tempsSendModeName = selectedFinancerTemp[findIndex]?.send_mode_arr;
    let tempsSendModeChecked = selectedFinancerTemp[findIndex]?.send_mode_checked;
    if (event.target.checked)
      tempsSendModeName ? tempsSendModeName.push(valueMode) : tempsSendModeName = [valueMode];
    else
      selectedFinancerTemp[findIndex].send_mode_arr.splice(tempsSendModeName.indexOf(valueMode), 1);
    selectedFinancerTemp[findIndex].send_mode_arr = tempsSendModeName;

    if (event.target.checked) {
      if (tempsSendModeChecked)
        tempsSendModeChecked[valueMode.toLowerCase()] = true;
      else
        tempsSendModeChecked = { [valueMode.toLowerCase()]: true };
    }
    else tempsSendModeChecked[valueMode.toLowerCase()] = false;

    selectedFinancerTemp[findIndex].send_mode_checked = tempsSendModeChecked;
    setSelectedfinanciers([...selectedfinanciers])
  };

  const verifyuploadeddocument = () => {

    let checkuploaddoc = true;
    let doc = [];
    if ("credit_investigation" === props.template_for) {
      //doc = [90];
    } else if ("send_to_financier" === props.template_for) {
      first_submit_doc && first_submit_doc.forEach((v) => {
        let childdoc = v.child.filter((ch) => ch.tab_name === 'first_submit' && ch.is_required === '1');
        let childids = childdoc.map((chid) => chid.id)
        doc = doc.concat(childids);
      });
    }
    else {
      doc = [102, 96, 100];
    }
    checkuploaddoc = doc.every(v => availabledoc.includes(v));
    return checkuploaddoc;
  }

  const senddocumenttofinanceir = (financier_id=0) => {
    if (!verifyuploadeddocument()) {
      toast.warning("Please upload all required documents.");
      return;
    }

    if(!financier_id){
      toast.warning("No financier available for credit investigation sent");
       return;
    }

    let links = '';
    let docdata = { lead_id: leadDetail.id, user_email: [], page_type: props.template_for, user_id: UserInfo?.id, "template_type": [], "template_id": [],"financier_id":[], "send_html_template": [], "doc_ids": [], remarks: "test", status: "1", created_by: UserInfo?.id, updated_by: UserInfo?.id };

    let sf=selectedfinanciers.filter((sf)=>sf.financier_id===financier_id)?.[0];
    let financertemplate=templateList?.filter((tempdata)=>tempdata.financier_id===sf.financier_id);

    if (props.template_for === "credit_investigation") {
      if (!sf?.ci_sub_status_reason) {
        toast.warning("Fill required fields");
        return;
      }
      docdata.ci_name = sf?.ci_name;
      docdata.ci_contact = sf?.ci_contact;
      docdata.ci_sub_status_reason = sf?.ci_sub_status_reason;
    } else {
      docdata.ci_name = "";
      docdata.ci_contact = "";
      docdata.ci_sub_status_reason="";
    }

      const filteredArray = sf?.document_category_ids?.filter(value => availabledoc.includes(value));

      leadDetail?.leaddocs.forEach((v) => {
      if (filteredArray.includes(v.doc_id))
        links += v.doc_path + " ";
    });

    let whatsapplink = "", viberlink = "";
    if (sf.send_mode_arr.length > 0) {
      setLoading(true);
      if (sf.send_mode_arr.includes("EMAIL")) {
        docdata.financier_id.push(sf.financier_id);
        docdata.template_type.push("email");
        docdata.send_html_template.push(`${financertemplate?.[0]?.['email'].template_body.replaceAll("\n", "<br>")}`);
        docdata.template_id.push(`${financertemplate?.[0]?.['email'].id}`);
        docdata.user_email.push( financertemplate?.[0]?.['email'].financier_group_email);
        docdata.doc_ids.push(filteredArray.join());
      }
      if (sf.send_mode_arr.includes("WHATSAPP")) {
        docdata.financier_id.push(sf.financier_id);
        docdata.template_type.push("whatsapp");
        docdata.template_id.push(`${financertemplate?.[0]?.['whatsapp'].id}`);
        docdata.send_html_template.push(`${financertemplate?.[0]?.['whatsapp'].template_body.replaceAll("\n", "<br>")}`);
        whatsapplink = "https://wa.me?text=" + encodeURIComponent(financertemplate?.[0]?.['whatsapp'].template_body.replaceAll("<br>", "\n") + " " + links);
        docdata.user_email.push( financertemplate?.[0]?.['whatsapp']?.financier_group_email);
        docdata.doc_ids.push(filteredArray.join());
        // {WHATSAPP_URL + encodeURIComponent(templateData.whatsappTemplate.template_body.replaceAll("<br>", "\n") + whatsapp_image_message)}
      }
      if (sf.send_mode_arr.includes("VIBER")) {
        docdata.financier_id.push(sf.financier_id);
        docdata.template_type.push("viber");
        docdata.template_id.push(`${financertemplate?.[0]?.['viber'].id}`);
        docdata.send_html_template.push(`${financertemplate?.[0]?.['viber'].template_body.replaceAll("\n", "<br>")}`);
        docdata.user_email.push( financertemplate?.[0]?.['viber']?.financier_group_email);
        docdata.doc_ids.push(filteredArray.join());
        let viberphone = financertemplate?.[0]?.['viber']?.financier_group_mobile;

        viberphone = viberphone === "" ? "9178096510" : viberphone;
        viberlink = `https://msng.link/o/?+63${viberphone}=vi`;
      }
      // docdata.send_html_template=JSON.stringify(docdata.send_html_template);
    } 

    let variables = {
      sendTemplateNotificationinput: docdata
    };
    executeGraphQLMutation(SEND_DOC_TO_FINANCEIR, variables, client).then((result) => {
      toast.success("Success");
      dispatch(getLoanDetail(leadDetail.id, client));
      let redirecturl = ''
      if (props.template_for === 'send_to_financier') {
        redirecturl = `/lead-detail/soft-approval/${lead_id}`;
      } else if (props.template_for === 'credit_investigation') {
        redirecturl = `/lead-detail/credit-investigation-recieved/${lead_id}`;
      } else {
        redirecturl = `/lead-detail/orcr-verfied/${lead_id}`;
      }

      if (whatsapplink) window.open(whatsapplink);
      if (viberlink) window.open(viberlink);
      setLoading(false);
      if (redirecturl !== '' && props.template_for!=="send_to_financier")
        navigate(redirecturl)
    }).catch((err) => {
      setLoading(false);
      toast.error(err.toString().replace('ApolloError:', '')
      );
    });

  }

  const sendRepeatnotification = (type,financier_id) => {

    // if (!verifyuploadeddocument()) {
    //   toast.warning("Please upload all required documents.");
    //   return;
    // }

    let links = '';

    let docdata = { lead_id: leadDetail.id, user_email: [], page_type: props.template_for, user_id: UserInfo?.id, "template_type": [], "template_id": [],"financier_id":[], "send_html_template": [], "doc_ids": [], remarks: "test", status: "1", created_by: UserInfo?.id, updated_by: UserInfo?.id };

    let oldhistory = leadDetail?.lead_template_history?.filter((th) => th.template_type === type && th.page_type === props.template_for && th.financier_id===financier_id);

    oldhistory.sort(function (a, b) {
      return new Date(b.created_date) - new Date(a.created_date);
    });

    if (oldhistory && oldhistory.length > 0) {
      docdata.template_type.push(`${type}`);
      docdata.send_html_template.push(`${oldhistory?.[0]?.send_html_template}`);
      docdata.template_id.push(`${oldhistory?.[0]?.template_id}`);
      docdata.user_email.push(oldhistory?.[0]?.user_email);
      docdata.doc_ids.push(oldhistory?.[0]?.doc_ids);
      docdata.financier_id.push(oldhistory?.[0]?.financier_id);
      const filteredArray = docdata.doc_ids?.[0].split(',').filter(value => availabledoc.includes(Number(value)));

      leadDetail?.leaddocs.forEach((v) => {
        if (filteredArray.includes(String(v.doc_id)))
          links += v.doc_path + " ";
      })
    }
    let variables = {
      sendTemplateNotificationinput: docdata
    };


    setLoading(true);
    executeGraphQLMutation(SEND_DOC_TO_FINANCEIR, variables, client).then((result) => {
      toast.success("Success");
      dispatch(getLoanDetail(leadDetail.id, client));
      let redirecturl = ''
      if (props.template_for === 'send_to_financier') {
        redirecturl = `/lead-detail/soft-approval/${crypto.encode(leadDetail.id)}`;
      } else if (props.template_for === 'credit_investigation') {
        redirecturl = `/lead-detail/credit-document-received/${crypto.encode(leadDetail.id)}`;
      } else {
        redirecturl = `/lead-detail/orcr-verfied/${crypto.encode(leadDetail.id)}`;
      }

      if (type === 'whatsapp') {
        window.open("https://wa.me?text=" + encodeURIComponent(oldhistory?.[0]?.send_html_template.replaceAll("<br>", "\n") + " " + links));
      }
      if (type === 'viber') {
        docdata.user_email = docdata.user_email === "" ? "9178096510" : docdata.user_email;
        window.open(`https://msng.link/o/?+63${docdata.user_email}=vi`);
      }

      setLoading(false);
      if (redirecturl !== '' && props.template_for!=="send_to_financier")
         navigate(redirecturl)
    }).catch((err) => {
      setLoading(false);
      toast.error(err.toString().replace('ApolloError:', ''));
    });
  }

const updatestatus=()=>{
  setLoading(true);
  let variables = {
    updateleadstatus: {page_type:props.template_for,user_id:UserInfo?.id,lead_id:leadDetail?.id}
  };
  executeGraphQLMutation(UPDATE_LEAD_STATUS, variables, client).then((result) => {
    toast.success("Success");
    dispatch(getLoanDetail(leadDetail.id, client));
    let redirecturl = ''
    if (props.template_for === 'send_to_financier') {
      redirecturl = `/lead-detail/soft-approval/${lead_id}`;
    } else if (props.template_for === 'credit_investigation') {
      redirecturl = `/lead-detail/credit-investigation-recieved/${lead_id}`;
    } else {
      redirecturl = `/lead-detail/orcr-verfied/${lead_id}`;
    }

    setLoading(false);
    if (redirecturl !== '')
      navigate(redirecturl)
  }).catch(err => {
    setLoading(false);
    toast.error(err.toString().replace('ApolloError:', ''));
  });
}

  const handleChange = (e, name,financier_id) => {
    if(financier_id){
    let selectedFinancerTemp = selectedfinanciers;
    const findIndex = selectedFinancerTemp.findIndex((fn) => Number(fn.financier_id) === financier_id);
    if(findIndex!==-1){
        if (name === "ci_name" ) {
          selectedFinancerTemp[findIndex][`${name}`]=e.target.value;
        } else {
          if (e?.value)
            selectedFinancerTemp[findIndex][`${name}`]=e.value;
        }
      setSelectedfinanciers([...selectedfinanciers]);
      }
    } 
  }
  return (
    <div className="image-form-outer">
      <div className="image-tab-panel emailer-outer">
        <div className="email-templete-sec p-lg-t">
          <span className="sent_to_finance_title_left send-email-heading">
            {selectedfinanciers?.length > 0 && `Email | WhatsApp | Viber - ${props.template_title}`}

          </span>



          <Accordion allowZeroExpanded allowMultipleExpanded>
            {financiers?.map((fn, key) => {
              let financertemplate=templateList?.filter((tempdata)=>tempdata.financier_id===fn.financier_id);
              return (<AccordionItem key={key} className='acc-item-financer'>
                <AccordionItemHeading className='acc-heading-financer'>
                  <AccordionItemButton>
                    <div className="heading-icn">
                      <h3>{fn?.financier?.financierlang?.financier_name}</h3>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='acc-panel-finaner'>
                  {!selectedfinanciers?.filter((selfn)=>selfn.financier_id===fn.financier_id)?.[0]?.send_mode_arr?.length ? 'No Templates have been selected for the Financier' :""}
                  {financertemplate && financertemplate?.[0]?.email_enable && selectedfinanciers?.filter((selfn)=>selfn.financier_id===fn.financier_id)?.[0]?.send_mode_arr?.includes("EMAIL") &&
                     <div className='email-templete-inner-accordain'>
                   <AccordionItem>
                    
                      <AccordionItemHeading>
                        <AccordionItemButton>Email</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <div dangerouslySetInnerHTML={{ __html: (financertemplate && financertemplate?.[0]?.email && financertemplate?.[0]?.email.template_body) ? financertemplate?.[0]?.email?.template_body.replaceAll("\n", "<br>") : '' }} />
                      </AccordionItemPanel>
                    </AccordionItem>
                    </div>
                  
                  }

              {financertemplate && financertemplate?.[0]?.whatsapp_enable && selectedfinanciers?.filter((selfn)=>selfn.financier_id===fn.financier_id)?.[0]?.send_mode_arr?.includes("WHATSAPP") &&
                  <div className='email-templete-inner-accordain'>
                    <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>WhatsApp</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div dangerouslySetInnerHTML={{ __html: (financertemplate && financertemplate?.[0]?.whatsapp && financertemplate?.[0]?.whatsapp.template_body) ? financertemplate?.[0]?.whatsapp?.template_body.replaceAll("\n", "<br>") : '' }} />
                        </AccordionItemPanel>
                      </AccordionItem>
                  </div>
                  
                  }

              {financertemplate && financertemplate?.[0]?.viber_enable && selectedfinanciers?.filter((selfn)=>selfn.financier_id===fn.financier_id)?.[0]?.send_mode_arr?.includes("VIBER") &&
                    <div className='email-templete-inner-accordain'>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>Viber</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div dangerouslySetInnerHTML={{ __html: (financertemplate && financertemplate?.[0]?.viber && financertemplate?.[0]?.viber.template_body) ? financertemplate?.[0]?.viber?.template_body.replaceAll("\n", "<br>") : '' }} />
                        </AccordionItemPanel>
                      </AccordionItem>
                    </div>
                  }
                </AccordionItemPanel>
              </AccordionItem>)
            })}
          </Accordion>


          

          {/* {SendToFinancer.send_mode_arr.length == 0 ? (<p><br></br><br></br>
            No Templates have been selected for the Financier</p>) : null} */}
        </div>
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className={" switch-toggle"}>
            <div className="lead-form-heading send-financer-heading sent-financer-outer">
              {props.template_for === 'credit_investigation' && <h2>Credit Investigation</h2>}
              <h2>{props.template_title}</h2>
            
            </div>

            <Accordion allowMultipleExpanded="true" allowZeroExpanded className=''>
              {financiers?.map((fn, key) => {
                let financertemplate=templateList?.filter((tempdata)=>tempdata.financier_id===fn.financier_id);
                let singleSelectedfinancer=selectedfinanciers.filter((selfn)=>selfn.financier_id===fn.financier_id)?.[0];
                let soft_approval_ci_data=leadDetail?.soft_approval_ci_data?.filter((ci)=>ci.financier_id===fn.financier_id)?.[0];

                return (<AccordionItem key={key} className='acc-item-financer'>
                  <AccordionItemHeading className='acc-heading-financer'>
                    <AccordionItemButton>
                      <div className=" custom-control custom-checkbox checkbox-radius">
                        <input
                          aria-label="financier checkbox"
                          id="pending"
                          type="checkbox" value={fn.financier_id}
                          className="custom-control-input" onClick={(e) => e.stopPropagation()} onChange={(e) => selectFinancer(e)}
                        checked={selectedfinanciers.filter((selfn)=>selfn.financier_id===Number(fn.financier_id))?.length>0} disabled={singleSelectedfinancer?.datetimevalue || soft_approval_ci_data?.credit_investigation || (singleSelectedfinancer?.is_completed) || (leadDetail?.lead_status_history?.filter((ls)=> ls.sub_status_id===28)).length>0 }
                        />
                        <label className="custom-control-label"></label>
                      </div>
                      <div className={singleSelectedfinancer?.datetimevalue ? 'financer-name-no-date financer-name-date' : 'financer-name-no-date'} >
                          <h3 title= {fn?.financier?.financierlang?.financier_name}>  {fn?.financier?.financierlang?.financier_name}</h3>
                          <span className='date-time-txt'>{singleSelectedfinancer?.datetimevalue}</span>
                        </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className='acc-panel-finaner'>
                    <div className="lead-form-filed send-financer m-sm-t financer-sending-mode">
                      {!selectedfinanciers.filter((selfn)=>selfn.financier_id===fn.financier_id)?.length && 'Please select financier first'}
                      <h4>{(financertemplate && (financertemplate?.[0]?.viber_enable || financertemplate?.[0]?.email_enable || financertemplate?.[0]?.whatsapp_enable)) && selectedfinanciers.filter((selfn)=>selfn.financier_id===fn.financier_id)?.length>0 ? "Select Sending Mode" :""}</h4>
                     
                      {displayBlock === "DocumentList" ? (
                        <div>
                          <fieldset className="from-group m-b-0">

                            {financertemplate && financertemplate?.[0]?.email_enable && selectedfinanciers.filter((selfn)=>selfn.financier_id===fn.financier_id)?.length>0 &&
                          
                              <div className="select-mode-list">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    aria-label="call verified check"
                                    id="CallVerified"
                                    type="checkbox"
                                    className="custom-control-input "
                                    onClick={(e) => toggleswitch("EMAIL", e, fn.financier_id)}
                                  checked={
                                    selectedfinanciers.filter((selfn)=>selfn.financier_id===fn.financier_id)?.[0]?.send_mode_checked?.email
                                  }
                                  disabled={
                                    selectedfinanciers.filter((selfn)=>selfn.financier_id===fn.financier_id)?.[0]?.send_mode_disabled?.email || (singleSelectedfinancer?.is_completed)
                                  }
                                  />
                                  <label className="custom-control-label">Email</label>
                                </div>

                                {leadDetail?.lead_template_history?.filter((th) => th.template_type === 'email' && th.financier_id===fn.financier_id && th.page_type === props.template_for)?.length > 0 ?
                                  <div class="select-list-doc"><span class="date-time-txt">Send On {dateFormat(leadDetail?.lead_template_history?.filter((th) => th.template_type === 'email' && th.financier_id===fn.financier_id && th.page_type === props.template_for)?.at(-1).created_date, 'd mmm yyyy h:MM TT')}</span><span onClick={()=>showModalFinancerEmail(fn.financier_id)}>View</span>{(props.template_for==="credit_investigation" ? !singleSelectedfinancer?.save_button_disabled : !props.is_completed) ? <span onClick={() => sendRepeatnotification('email',fn.financier_id)}>Repeat</span> : ''}</div> : ''}

                              </div>
                            }
                            {financertemplate && financertemplate?.[0]?.whatsapp_enable && selectedfinanciers.filter((selfn)=>selfn.financier_id===fn.financier_id)?.length>0 &&
                              <div className="select-mode-list">
                                <div className=" custom-control custom-checkbox">
                                  <input
                                    aria-label="selected financiers"
                                    id="pending"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    className="custom-control-input"
                                    onClick={(e) => toggleswitch("WHATSAPP", e, fn.financier_id)}
                                    checked={
                                      selectedfinanciers.filter((selfn)=>selfn.financier_id===fn.financier_id)?.[0]?.send_mode_checked?.whatsapp
                                    }
                                    disabled={
                                      selectedfinanciers.filter((selfn)=>selfn.financier_id===fn.financier_id)?.[0]?.send_mode_disabled?.whatsapp || (singleSelectedfinancer?.is_completed)
                                    }
                                  />
                                  <label className="custom-control-label">WhatsApp</label>
                                </div>
                                {leadDetail?.lead_template_history?.filter((th) => th.template_type === 'whatsapp' && th.financier_id===fn.financier_id && th.page_type === props.template_for)?.length > 0 ?
                                  <div class="select-list-doc"><span class="date-time-txt">Send On {dateFormat(leadDetail?.lead_template_history?.filter((th) => th.template_type === 'whatsapp' && th.financier_id===fn.financier_id && th.page_type === props.template_for)?.at(-1).created_date, 'd mmm yyyy h:MM TT')}</span><span onClick={()=>showModalFinancerWhatsApp(fn.financier_id)}>View</span>{(props.template_for==="credit_investigation" ? !singleSelectedfinancer?.save_button_disabled : !props.is_completed)  ? <span onClick={() => sendRepeatnotification('whatsapp',fn.financier_id)}>Repeat</span> : ''}</div> : ''}


                              </div>
                            }
                            {financertemplate && financertemplate?.[0]?.viber_enable && selectedfinanciers.filter((selfn)=>selfn.financier_id===fn.financier_id)?.length>0 &&
                              <div className="select-mode-list">
                                <div className=" custom-control custom-checkbox">
                                  <input
                                    aria-label="selected financier for viber"
                                    id="pending"
                                    type="checkbox"
                                    name=""
                                    value=""
                                    className="custom-control-input"
                                    onClick={(e) => toggleswitch("VIBER", e, fn.financier_id)}
                                    checked={
                                      selectedfinanciers.filter((selfn)=>selfn.financier_id===fn.financier_id)?.[0]?.send_mode_checked?.viber
                                    }
                                    disabled={
                                      selectedfinanciers.filter((selfn)=>selfn.financier_id===fn.financier_id)?.[0]?.send_mode_disabled?.viber || (singleSelectedfinancer?.is_completed)
                                    }
                                  />
                                  <label className="custom-control-label">Viber</label>
                                </div>

                                {leadDetail?.lead_template_history?.filter((th) => th.template_type === 'viber' && th.financier_id===fn.financier_id && th.page_type === props.template_for)?.length > 0 ?
                                  <div class="select-list-doc"><span class="date-time-txt">Send On {dateFormat(leadDetail?.lead_template_history?.filter((th) => th.template_type === 'viber' && th.financier_id===fn.financier_id && th.page_type === props.template_for)?.at(-1).created_date, 'd mmm yyyy h:MM TT')}</span><span onClick={()=>showModalFinancerViber(fn.financier_id)}>View</span>{(props.template_for==="credit_investigation" ? !singleSelectedfinancer?.save_button_disabled : !props.is_completed) ? <span onClick={() => sendRepeatnotification('viber',fn.financier_id)}>Repeat</span> : ''} </div> : ''}
                              </div>
                            }
                          </fieldset>

                          {(financertemplate && (financertemplate?.[0]?.viber_enable || financertemplate?.[0]?.email_enable || financertemplate?.[0]?.whatsapp_enable)) ? "" : "No templates has been configured"}

                          {props.template_for === 'credit_investigation' && (financertemplate && (financertemplate?.[0]?.viber_enable || financertemplate?.[0]?.email_enable || financertemplate?.[0]?.whatsapp_enable) && selectedfinanciers?.filter((sf)=>sf.financier_id===fn.financier_id)?.length>0) && 
                            <>

                              <div className='row'>
                                <Formsy
                                  className="lead-form-filed"
                                  autoComplete="off"
                                  onValid={() => setAllowSubmit(true)}
                                  onInvalid={() => setAllowSubmit(false)}
                                  onValidSubmit={() => { }}
                                >

                              <fieldset class=" col-md-12 single-select">
                                <div class="material ">

                                    <FormsySelect
                                        name="ci_sub_status_reason"
                                        id="ci_sub_status_reason"
                                        inputProps={{
                                        options: CI_SUB_STATUS_REASON || [],
                                        placeholder: "Sub Status Reason",
                                        className: "react-select",
                                        classNamePrefix: "react-select",
                                        value: { label : selectedfinanciers?.filter((sf)=>sf.financier_id===fn.financier_id)?.[0].ci_sub_status_reason,value : selectedfinanciers?.filter((sf)=>sf.financier_id===fn.financier_id)?.[0].ci_sub_status_reason},
                                        required:true,
                                        
                                        }}
                                        required={true}
                                        
                                        value={selectedfinanciers?.filter((sf)=>sf.financier_id===fn.financier_id)?.[0]?.ci_sub_status_reason}
                                        onChange={(e) => handleChange(e,'ci_sub_status_reason',fn.financier_id)}
                                    />

                                    {/* <Select
                                    options={CI_SUB_STATUS_REASON}
                                    placeholder={"Select Status Reason"}
                                    name={'ci_sub_status_reason'}
                                    optionLabel="label"
                                    optionValue="value"
                                    value={{ value: selectedfinanciers?.filter((sf)=>sf.financier_id===fn.financier_id)?.[0]?.ci_sub_status_reason, label: selectedfinanciers?.filter((sf)=>sf.financier_id===fn.financier_id)?.[0]?.ci_sub_status_reason }}
                                    onChange={(e) => handleChange(e,'ci_sub_status_reason',fn.financier_id)}
                                    /> */}

                                </div>
                              </fieldset>

                                  <fieldset class="form-filed col-md-12 ">
                                    <div class="material ">


                                      <FormsyInputField
                                        id={"ci_name"}
                                        name={"ci_name"}
                                        type={"text"}
                                        value={selectedfinanciers?.filter((sf)=>sf.financier_id===fn.financier_id)?.[0]?.ci_name}
                                        placeholder=" "
                                        thousandSeparator={','}
                                        decimalSeparator={'.'}
                                        label={"CI Contact Person"}
                                        onChange={(data) => {
                                          handleChange(data, 'ci_name',fn.financier_id)
                                        }
                                        }
                                        disabled={(singleSelectedfinancer?.is_completed)}
                                        validations={"isWords"}
                                        validationError={
                                          ciname ?
                                            "Must only contain alphabets" : ""
                                        }
                                        
                                      />

                                    </div>
                                  </fieldset>
                                  <fieldset class="form-filed col-md-12 ">
                                    <div class="material ">


                                      <FormsyInputField
                                        id={"ci_contact"}
                                        name={"ci_contact"}
                                        type={"pattern-format"}
                                        value={selectedfinanciers?.filter((sf)=>sf.financier_id===fn.financier_id)?.[0]?.ci_contact}
                                        placeholder=" "
                                        thousandSeparator={','}
                                        decimalSeparator={'.'}
                                        label={"CI Contact Number"}
                                        onChange={(data) =>
                                          handleChange(data, 'ci_contact',fn.financier_id)}
                                        format={"+63 ### ### ####"}
                                        validations={"isLength:10,isValidPhoneNumber"}
                                        validationError={
                                          cicontact ?
                                            "Number must be of 10 digits" : ""
                                        }
                                        disabled={(singleSelectedfinancer?.is_completed)}
                                        
                                      />

                                    </div>
                                  </fieldset>
                                </Formsy>
                              </div>
                            </>
                          }
                          {selectedfinanciers?.filter((sf)=>sf.financier_id===fn.financier_id)?.[0]?.send_mode_arr?.length > 0 ?
                            <>
                              <h3>Doc Attached</h3>
                              <div className="financer-doc-list ">
                                <Tab.Container
                                  id="customer_coborrower-tabs-example"
                                  defaultActiveKey="borrower"
                                >
                                  <Nav variant="pills" className="flex-column tab-line m-lg-b doc-attach-tab">
                                    <div className="tab-list">
                                      {props.pagetab.map((pg, key) => (
                                        <Nav.Item key={key}>
                                          <Nav.Link eventKey={pg.type}>
                                            {pg.key}
                                          </Nav.Link>
                                        </Nav.Item>
                                      ))}

                                    </div>
                                  </Nav>
                                  <Tab.Content>
                                    {props.pagetab.map((pg, key) => (
                                      <Tab.Pane eventKey={pg.type} key={key}>

                                        <Accordion allowMultipleExpanded="true" allowZeroExpanded>
                                          {docList &&
                                            docList.length > 0 && availabledoc.length > 0 &&
                                            docList.map((element) => pg.doc.split(',').includes(String(element.id)) && element.child_ids_exists ?
                                              (
                                                <AccordionItem key={element.id}>
                                                  <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                      <div className=" custom-control custom-checkbox">
                                                        {selectedfinanciers.filter((fnfilter) => fnfilter.financier_id === fn.financier_id)?.[0]?.document_category_ids?.includes(Number(element.id)) ? true : false}
                                                        <input
                                                          aria-label="doc lang name"
                                                          id="pending"
                                                          type="checkbox" value={element.id}
                                                          className="custom-control-input" onChange={(e) => selectdocument(e, fn.financier_id)}
                                                          checked={selectedfinanciers.filter((fnfilter) => fnfilter.financier_id === fn.financier_id)?.[0]?.document_category_ids?.includes(Number(element.id)) ? true : false}

                                                          disabled={(singleSelectedfinancer?.is_completed)}
                                                        />
                                                        <label className="custom-control-label">
                                                          {element.doclang.name}
                                                        </label>
                                                      </div>
                                                    </AccordionItemButton>
                                                  </AccordionItemHeading>
                                                  <AccordionItemPanel>
                                                    <div className="sub-list">
                                                      <ul className="">
                                                        {element.child && element.child.length > 0 &&
                                                          element.child.map((subdoc) =>
                                                            subdoc.parent_id === element.id && availabledoc.includes(subdoc.id) ? (
                                                              <li key={subdoc.id}>
                                                                <div className=" custom-control custom-checkbox">



                                                                  <input
                                                                    aria-label="document change"
                                                                    id="pending"
                                                                    type="checkbox"
                                                                    className="custom-control-input" value={subdoc.id}
                                                                    parent_id={element.id}
                                                                    onChange={(e) => selectdocument(e, fn.financier_id)}
                                                                    checked={selectedfinanciers.filter((fnfilter) => fnfilter.financier_id === fn.financier_id)?.[0]?.document_category_ids?.includes(Number(subdoc.id)) ? true : false}

                                                                    disabled={(singleSelectedfinancer?.is_completed)}
                                                                  />
                                                                  <label className="custom-control-label">
                                                                    {subdoc.doclang.name}

                                                                  </label>
                                                                </div>
                                                              </li>
                                                            ) : null
                                                          )}
                                                      </ul>
                                                    </div>
                                                  </AccordionItemPanel>
                                                </AccordionItem>

                                              ) : null)}
                                        </Accordion>
                                      </Tab.Pane>
                                    ))}
                                  </Tab.Content>
                                </Tab.Container>
                              </div></>
                            : ''}

                          {loading ? <Loader /> : ''}
                          
                          {props.template_for==="send_to_financier" &&
                              <div className="btn-save-remarks m-md-t">
                                {<button disabled={markAsFreezed || accessCondition || singleSelectedfinancer?.save_disabled_sent_to_financer || !isApplicationCreated || (props.template_for === 'credit_investigation' && !allowSubmit ? true : (!selectedfinanciers?.filter((sf)=>sf.financier_id===fn.financier_id )?.[0]?.send_mode_arr?.length || !selectedfinanciers?.filter((sf)=>sf.financier_id===fn.financier_id )?.[0]?.document_category_ids?.length)  ? true : singleSelectedfinancer?.is_completed ? true : false  )}  type="button" onClick={(e) => { senddocumenttofinanceir(fn.financier_id) }} className="btn-primary">
                                  Send
                                </button>
                                }
                              </div>
                          }

                        </div>
                      ) : null}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>)
              })}
            </Accordion>

            {/* (selectedfinanciers?.length && selectedfinanciers?.filter((sf)=>sf?.financier_id && sf?.send_mode_arr?.length>0 && sf?.document_category_ids?.length>0).length>0) */}
            <div className="btn-save-remarks m-md-t">
              {props.template_for==="send_to_financier" && ((markAsFreezed || accessCondition || (leadDetail?.lead_status_history?.filter((ls)=>ls.sub_status_id===28)?.length>0 ? true : (leadDetail?.lead_template_history?.filter((th)=>th.page_type==="send_to_financier").length>0) ? false : true) || leadDetail?.soft_approval_ci_data?.length === 0 ) ? <span className="span-disabled">Next</span> :
                            <button type="button" disabled={markAsFreezed || accessCondition || (leadDetail?.lead_status_history?.filter((ls)=>ls.sub_status_id===28)?.length>0 ? true : (leadDetail?.lead_template_history?.filter((th)=>th.page_type==="send_to_financier").length>0) ? false : true) || leadDetail?.soft_approval_ci_data?.length === 0 } onClick={(e) => { updatestatus() }} className="btn-primary">
                               Next
                            </button>)
              }

              {props.template_for==="credit_investigation" && ((markAsFreezed || accessCondition || !allowSubmit || (!selectedfinanciers?.filter((sf)=>sf?.financier_id &&  !sf?.is_completed)?.[0]?.financier_id) || (leadDetail?.lead_status_history?.filter((ls)=>ls.sub_status_id===28)?.length>0 ? true : (selectedfinanciers?.length && selectedfinanciers?.filter((sf)=>sf?.financier_id && !sf?.is_completed && sf?.send_mode_arr?.length>0 && sf?.document_category_ids?.length>0).length>0)  ? false : true ) ) ? <span className="span-disabled">Save & Next</span> :
                 <button type="button" disabled={markAsFreezed || accessCondition || !allowSubmit || (!selectedfinanciers?.filter((sf)=>sf?.financier_id &&  !sf?.is_completed)?.[0]?.financier_id) || (leadDetail?.lead_status_history?.filter((ls)=>ls.sub_status_id===28)?.length>0 ? true : (selectedfinanciers?.length && selectedfinanciers?.filter((sf)=>sf?.financier_id && !sf?.is_completed && sf?.send_mode_arr?.length>0 && sf?.document_category_ids?.length>0).length>0)  ? false : true ) } onClick={(e) => { senddocumenttofinanceir(selectedfinanciers?.filter((sf)=>sf?.financier_id &&  !sf?.is_completed)?.[0]?.financier_id) }} className="btn-primary">
                 Save & Next
                </button>)
              }
                            <button type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                              <span className="m-xs-l">({remarksHistory?.length || 0})</span>
                            </button>
                          </div>

                          <div className="view-timeline-popup">
                            <div className="view-timeline-popup">
                              <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
                                <div className="modal-header">
                                  <h2>Remark History</h2>
                                </div>
                                <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={markAsFreezed} accessCondition={accessCondition} sectionInfo={props.sectionInfo}/>
                              </Modal>
                            </div>
                          </div>


                          <div className="view-timeline-popup image-timeline-popup financer-email-popup">
                            <Modal show={FinancerEmail} handleClose={hideModalFinancerEmail} >
                              <div className="modal-header">
                                <h2>Last Email Sent Details</h2>
                                <span className="sub-heading"></span>
                              </div>
                              <div className="modal-body">
                                <Accordion allowZeroExpanded>
                                  {
                                    leadDetail?.lead_template_history?.length && leadDetail?.lead_template_history?.map((temp, index) => (temp.template_type === 'email' && temp.financier_id===Number(currentFinancer) && temp.page_type === props.template_for &&
                                      <AccordionItem >
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            Sent on {dateFormat(temp?.created_date, 'd mmm yyyy h:MM TT')}
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <div dangerouslySetInnerHTML={{ __html: (temp && temp.send_html_template) ? temp.send_html_template.replaceAll("\n", "<br>") : '' }} />

                                          <div className="financer-email-data">
                                            <div className="email-attech-doc-list">
                                              <h3>Attached Documents</h3>
                                              <ul>
                                                {temp?.doc_ids && temp?.doc_ids?.split(',')?.length > 0 && temp?.doc_ids?.split(',').map((tag_image, key) => (
                                                  <li>
                                                    {leadDetail?.leaddocs?.filter((ld) => Number(ld.doc_id) === Number(tag_image))?.[0]?.docmaster?.doclang?.name}
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          </div>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                    ))
                                  }
                                </Accordion>
                              </div>
                            </Modal>
                            <Modal show={FinancerWhatsApp} handleClose={hideModalFinancerWhatsApp} >
                              <div className="modal-header">
                                <h2>Last WhatsApp Sent Details</h2>
                                <span className="sub-heading"></span>
                              </div>
                              <div className="modal-body">
                                <Accordion allowZeroExpanded>
                                  {
                                    leadDetail?.lead_template_history?.length && leadDetail?.lead_template_history?.map((temp, index) => (temp.template_type === 'whatsapp' && temp.financier_id===Number(currentFinancer) && temp.page_type === props.template_for &&
                                      <AccordionItem >
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            Sent on {dateFormat(temp?.created_date, 'd mmm yyyy h:MM TT')}
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <div dangerouslySetInnerHTML={{ __html: (temp && temp.send_html_template) ? temp.send_html_template.replaceAll("\n", "<br>") : '' }} />

                                          <div className="financer-email-data">
                                            <div className="email-attech-doc-list">
                                              <h3>Attached Documents</h3>
                                              <ul>
                                                {temp?.doc_ids && temp?.doc_ids?.split(',')?.length > 0 && temp?.doc_ids?.split(',').map((tag_image, key) => (
                                                  <li>
                                                    {leadDetail?.leaddocs?.filter((ld) => Number(ld.doc_id) === Number(tag_image))?.[0]?.docmaster?.doclang?.name}
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          </div>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                    ))
                                  }
                                </Accordion>
                              </div>
                            </Modal>
                            <Modal show={FinancerViber} handleClose={hideModalFinancerViber} >
                              <div className="modal-header">
                                <h2>Last Viber Sent Details</h2>
                                <span className="sub-heading"></span>
                              </div>
                              <div className="modal-body">
                                <Accordion allowZeroExpanded>
                                  {
                                    leadDetail?.lead_template_history?.length && leadDetail?.lead_template_history?.map((temp, index) => (temp.template_type === 'viber' && temp.financier_id===Number(currentFinancer) && temp.page_type === props.template_for &&
                                      <AccordionItem >
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            Sent on {dateFormat(temp?.created_date, 'd mmm yyyy h:MM TT')}
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <div dangerouslySetInnerHTML={{ __html: (temp && temp.send_html_template) ? temp.send_html_template.replaceAll("\n", "<br>") : '' }} />

                                          <div className="financer-email-data">
                                            <div className="email-attech-doc-list">
                                              <h3>Attached Documents</h3>
                                              <ul>
                                                {temp?.doc_ids && temp?.doc_ids?.split(',')?.length > 0 && temp?.doc_ids?.split(',').map((tag_image, key) => (
                                                  <li>
                                                    {leadDetail?.leaddocs?.filter((ld) => Number(ld.doc_id) === Number(tag_image))?.[0]?.docmaster?.doclang?.name}
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          </div>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                    ))
                                  }
                                </Accordion>
                              </div>
                            </Modal>
                          </div>

          </div>
        </div>
      </div>
    </div>

  )
}


export default SendDoc;