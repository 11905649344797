import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import store from './store/store'
import { Provider } from 'react-redux'
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import language_id from "./translations/id/language.json";
import language_en from "./translations/en/language.json";
import secureStorage from "./config/encrypt";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, split } from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import reportWebVitals from "./reportWebVitals";
import {API_URL, API_KEY} from './config/constants';
import { toast } from "react-toastify";

i18next.init({
	interpolation: { escapeValue: false },
	lng: secureStorage.getItem("lang") === "id" ? "id" : "en",
	resources: {
		en: {
			language: language_en,
		},
		id: {
			language: language_id,
		},
	},
});

const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
}
let uri = `${API_URL}phloan/api/v1`;
const httpLink = createHttpLink({
	uri: uri,
	fetch: (uri, options) => {
		const timeout = 15000;
		return new Promise((resolve, reject) => {
		  const timer = setTimeout(() => {
			reject(new Error('Request timeout'));
		  }, timeout);
		  fetch(uri, options)
			.then((response) => {
			  clearTimeout(timer);
			  resolve(response);
			})
			.catch((error) => {
			  clearTimeout(timer);
			  reject(error);
			});
		});
	  },
});

const authLink = setContext((_, { headers }) => {
	const user_info = JSON.parse(localStorage.getItem('user_information'));
	const token = user_info?.access_token;
	const token_data = token ? JSON.parse(window.atob(token?.split(".")[1])) : {};
	const refresh_token = user_info?.refresh_token;
	const refresh_token_data = refresh_token ? JSON.parse(window.atob(refresh_token?.split(".")[1])) : {};
	let useRefreshToken = Date.now() >= token_data?.exp * 1000;
	let isUseRefreshToken = Date.now() >= ((refresh_token_data?.exp * 1000) - (60*60*1000));
	let useRefreshTokenKey = isUseRefreshToken ? 1 : 0;
	if(useRefreshToken) {
		fetch(uri,{
			method:'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${refresh_token}`
			},
			body: JSON.stringify({
				query: `query($useRefreshTokenKey: Float!){
					generate_refresh_token(useRefreshTokenKey:$useRefreshTokenKey) {
					  access_token
					  refresh_token
				  }
				}`,
				variables: {useRefreshTokenKey:useRefreshTokenKey}
			})
		})
		.then(response => response.json())
		.then(res => {
			let tokenObj = {...user_info,access_token: res?.data?.generate_refresh_token?.access_token};
			if(useRefreshTokenKey === 1) {
				tokenObj = {...tokenObj,refresh_token: res?.data?.generate_refresh_token?.refresh_token}
			}
			localStorage.setItem('user_information', JSON.stringify(tokenObj))
		})
		.catch((err) => {
			toast.error("Error in generating refresh token");
		})
	}
	const token2send =  useRefreshToken ? refresh_token : token;
	const user_data = user_info?.user_data;
	const isUserDataSet = localStorage.getItem('isUserDataSet') || false;
	let headers2send = Object.assign({api_source:'loanbox'},headers);
	if(token) {
		headers2send.Authorization = `Bearer ${token2send}`;
		if(!isUserDataSet && user_data){
			headers2send.user_data = JSON.stringify(user_data);
			localStorage.setItem('isUserDataSet',true);
		}
	}
	return {
	  headers: headers2send
	}
  });

  const link = split(
	(operation) => {
	  const context = operation.getContext();
	  const definition = operation.query.definitions;
	  console.log("************",definition)
	  if(context.targetPath === 'dif-ph' || definition[0]?.selectionSet?.selections[0]?.name?.value === 'get_lead_count') operation.setContext({ uri: `${API_URL}dif-ph/api/v1` });
	  if(context.targetPath === 'ph-refinance/api') operation.setContext({ uri: `${API_URL}ph-refinance/api` });
	},
	authLink.concat(httpLink),
	authLink.concat(httpLink),
  );

  export const client = new ApolloClient({
	link: link,
	cache: new InMemoryCache(),
	defaultOptions: defaultOptions
  });

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	
		<Provider store={store}>
			<ApolloProvider client={client}>
				<I18nextProvider i18n={i18next}>
					<App />
				</I18nextProvider>
			</ApolloProvider>
		</Provider>
	
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();