import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import FormsySelect from '../../elements/FormsySelect';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import { executeGraphQLMutation } from '../../../services/refinance/executeGraphQLQuery';
import { UCRF_UPDATE_CI_INVESTIGATION_DATA } from '../../../services/customer.gql';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';

import DocumentGallery from '../DocsUpload/DocumentGallery';
import { getUCRFFormFillStatus, getUCRFLeadDetail, getLeadRemarkUCRF, getSavedDocuments } from '../../../store/action/allAction';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from '../../../config/constants';
import Loader from '../../elements/Loader';
import FormsyTextAreaAutosize from '../../elements/FormsyTextAreaAutosize';
import GeneralService from '../../../services/generalService';
import LeadRemarkHistory from '../lead/component/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { PH_REFINANCE_BUCKET_NAME } from '../../../config/refinance_constant';
import { CONTRACT_SIGNED, LOST_STATUS } from '../../../config/refinance/constant';

const RefinanceContractSigningForm = (props) => {
  const [contractSignedDetails, setContractSignedDetails] = useState({});
  const [basicDetails, setBasicDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedfinanciers, setSelectedfinanciers] = useState([]);
  const [isSaveDisable, setIsSaveDisabled] = useState(true)
  const [isRemarkDisable, setIsRemarkDisabled] = useState(true)
  const [isNextEnable, setIsNextEnabled] = useState(false)
  const { master_data_ucrf, remarksHistory, leadDetail } = useSelector(({ lead, masterdataucrf }) => ({
    master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
    remarksHistory: lead.remarksHistory || [],
    leadDetail: lead?.ucrfLeadDetail || {},
  }));
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { lead_id } = useParams();

  const user_information = useSelector((state) => state.user.user_information);
  const UserInfo = JSON.parse(user_information);
  const getContext = useOutletContext();
  let logged_user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

  const documentCategoryList = getContext.docList || []

  useEffect(() => {
    if (leadDetail?.basic_details) {
      let financier_data = leadDetail?.financier_loan_approval?.loan_details ? leadDetail?.financier_loan_approval?.loan_details?.filter(e => e?.additional_loan_info?.loan_approved == "yes") : []
      let contractSignedDetails = { data_filled: 0 }
      if (leadDetail?.contract_signing_data && Object.keys(leadDetail?.contract_signing_data).length) {
        contractSignedDetails = { ...leadDetail.contract_signing_data, data_filled: leadDetail?.contract_signing_data?.financier_id ? 1 : 0 };
      }
      if (financier_data?.length) {
        let selected_financier = financier_data.map((ob) => ({
          id: ob.financier_id,
          value: ob.financier_id,
          label: master_data_ucrf.financier_list.filter(e => e.id == ob.financier_id).length ? master_data_ucrf.financier_list.filter(e => e.id == ob.financier_id)[0]?.financier_name : "",
        }))
        setSelectedfinanciers(selected_financier)
      }
      setBasicDetails(leadDetail.basic_details)
      setContractSignedDetails(contractSignedDetails);
      checkSaveEnable(leadDetail)
    }
    dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: 'WEB' }, client));

  }, [leadDetail]);

  const handleInputChange = event => {
    if (event.target.name) {
      setContractSignedDetails(currentValue => ({
        ...currentValue,
        [event.target.name]: event.target.value
      }))
    }
  }

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setContractSignedDetails(currentValue => ({
        ...currentValue,
        [field_name]: date
      }))
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      let tempResidentialDetails = { ...contractSignedDetails }
      tempResidentialDetails[field_name] = data.value
      setContractSignedDetails(tempResidentialDetails)
    }
  }

  const handleSubmit = () => {
    if (contractSignedDetails.data_filled) {
      navigateToNext();
    } else {
      let lead_id = leadDetail?.lead_id || "";

      let reqBody = {
        financier_id: contractSignedDetails?.financier_id || 0,
        contract_signing_date: contractSignedDetails?.contract_signing_date || "",
        reference_notes: contractSignedDetails?.reference_notes || "",
        attachments: contractSignedDetails?.attachments?.length ? contractSignedDetails?.attachments : []
      }
      let mutation = UCRF_UPDATE_CI_INVESTIGATION_DATA,
        variables = {
          lead_data: {
            created_by: logged_user_id || 0,
            updated_by: logged_user_id || 0,
            lead_id,
            contract_signing_data: reqBody
          },
        };
      setLoading(true)
      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.updateLeadDetails || null;
        if (response && !response.lead_id) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        dispatch(getUCRFLeadDetail(lead_id, client))
        dispatch(getUCRFFormFillStatus({ lead_id }, client))
        dispatch(getSavedDocuments(lead_id, client));
        setLoading(false)
        navigateToNext();
      }).catch(err => {
        setLoading(false)
        toast.error(err.message);
      })
    }
  }

  const navigateToNext = () => {
    navigate(`/refinance/lead-detail/loan_disbursement/${lead_id}`);
  }

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const handleFileChange = async (event, max_upload) => {
    let selectedImage = event?.target?.files?.[0]?.name || "";
    let publicUrl = '', doc_path = '';
    if (!selectedImage) {
      toast.error("Please upload an image.");
      return;
    }

    const lead_doc_count = contractSignedDetails?.attachments?.length || 0
    if (selectedImage) {
      if ((max_upload - lead_doc_count) > 0) {
        const formData = new FormData();
        formData.append('upload_type', PH_REFINANCE_BUCKET_NAME);
        formData.append('lead_id', lead_id);
        formData.append('images', event.target.files[0]);
        setLoading(true)
        try {
          publicUrl = await GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' });
          doc_path = publicUrl && publicUrl.data && publicUrl.data.data && publicUrl.data.data.length && (publicUrl.data.data[0].file_url || '');
        } catch (error) {
          console.error('Error uploading file:', error);
        }
        let contract_signing_data = { ...contractSignedDetails }
        let docs = Array.isArray(contract_signing_data.attachments) ? [...contract_signing_data.attachments] : []
        docs.push(doc_path)
        contract_signing_data.attachments = docs
        setContractSignedDetails(contract_signing_data)
        setLoading(false)
      }
    }
  }

  const sectionInfo = { section: "Contract Signing", sub_section: "Contract Signing Completed" }

  const checkSaveEnable = (leadDetail) => {
    let markAsFreezed = contractSignedDetails.data_filled
    let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status === LOST_STATUS.toString() : false;
    let user_page_access = IS_USER_ACCESS_PAGE("refinanceContractSigning", "ucrf-contract-signing")?.is_edit_access
    let user_edit_access = CHECK_ASSIGN_USER(leadDetail.assigned_to)
    let nextEnable = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == CONTRACT_SIGNED) > -1 ? true : false
    if (nextEnable) {
      setIsNextEnabled(true)
      setIsSaveDisabled(true)
    } else if (!user_edit_access && user_page_access && !leadLost && !markAsFreezed) {
      setIsSaveDisabled(false)
      setIsNextEnabled(false)
    } else {
      setIsSaveDisabled(true)
      setIsNextEnabled(false)
    }
    if (!user_edit_access && user_page_access) {
      setIsRemarkDisabled(false)
    }
  }

  return (
    <div className="image-form-outer" >
      {isLoading ? <Loader /> : ''}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <span className="main-heading">Contract Signing</span>
            <h2>Contract Signing Completed</h2>
          </div>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
            aria-label="lead form field contract signing details"
          >
            <div className="row">
              <fieldset class="col-md-4 loan-detail-form-label">
                <label>Select Financier</label>
              </fieldset>
              <fieldset class="single-select col-md-8">
                <FormsySelect
                  name={"financier_id"}
                  id={"financier_id"}
                  inputProps={{
                    options: selectedfinanciers,
                    placeholder: "Financier Name",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: selectedfinanciers.filter(({ value }) => value == contractSignedDetails.financier_id?.toString()),
                    isDisabled: (contractSignedDetails.data_filled)
                  }}
                  required={true}
                  showAsterisk={true}
                  value={contractSignedDetails.financier_id}
                  onChange={(data) => handleSelectChange(data, "financier_id")}
                />
              </fieldset>

              <fieldset class="form-filed col-md-12">
                <div className="material date-filed">
                  <FormsyDatePicker
                    name="contract_signing_date"
                    selectedDate={(contractSignedDetails?.["contract_signing_date"] && new Date(contractSignedDetails["contract_signing_date"])) || ""}
                    placeholder={"Contract Signing Date"}
                    maxDate={new Date().setMonth(new Date().getMonth() + 2)}
                    minDate={new Date().setMonth(new Date().getMonth() - 12)}
                    value={contractSignedDetails.contract_signing_date ? dateFormat(contractSignedDetails.contract_signing_date, 'd mmm,yyyy, h:MM TT') : ""}
                    onDateSelect={(date) => handleDateChange(date, "contract_signing_date")}
                    dateFormat="dd MMM, yyyy h:mm aa"
                    showTimeSelect={true}
                    validationError="Enter Valid Contract Signing Date"
                    required={true}
                    showAsterisk={true}
                    readOnly={false}
                    disabled={contractSignedDetails.data_filled}
                  />
                </div>
              </fieldset>

              <fieldset className="form-filed text-area-filed col-md-12">
                <div className="material">
                  <FormsyTextAreaAutosize
                    name={"reference_notes"}
                    value={contractSignedDetails?.reference_notes || ""}
                    placeholder=" "
                    label={"Reference Notes"}
                    onChange={e => handleInputChange(e)}
                    format={""}
                    required={false}
                    readOnly={false}
                    disabled={contractSignedDetails.data_filled}
                  />
                  <label data-label={"Reference Notes"} className="form-label"></label>
                </div>
              </fieldset>
            </div>
            <div className="lead-form-heading">
              <h3><b>Documents Upload</b></h3>
            </div>
            <div className="acco-doc-list">
              <ul>
                {contractSignedDetails?.attachments?.length == 0 ?
                  <li>
                    <div className='doc-count-txt'>
                      Contract Signed Doc
                      <span className="lead-doc-count">{0} / {5}</span>
                    </div>
                    <div className="more-file-upload doc-upload-btn">
                      <input aria-label="img upload" type="file" name="" accept=".pdf,image/*" disabled={false} onChange={e => handleFileChange(e, 5)} required={false} />
                      <button aria-label="img upload btn" type="button" className="btn-line">
                        <i className='ic-upload'></i> Upload
                      </button>
                    </div>
                  </li>
                  :
                  <li>
                    <div className='doc-count-txt'>
                      {contractSignedDetails?.attachments?.length === 5 ? <span className="stepprogress"><i className="sprite ic-check"></i></span> : ""}
                      Contract Signed Doc
                      <span className="lead-doc-count">{contractSignedDetails?.attachments?.length || 0} / {5}</span>
                    </div>

                    <div className="more-file-upload doc-upload-btn">
                      {contractSignedDetails?.attachments?.length === 5 ?
                        <button type="button" className="btn-line" disabled={true}>
                          <i className='ic-upload'></i> Upload
                        </button> :
                        <>
                          <input aria-label="img upload" type="file" name="" accept=".pdf,image/*" disabled={contractSignedDetails.attachments?.length === 5} onChange={e => handleFileChange(e, 5)} required={false} />
                          <button aria-label="img upload btn" type="button" className="btn-line">
                            <i className='ic-upload'></i> Upload
                          </button>
                        </>
                      }
                    </div>

                  </li>
                }
              </ul>
            </div>
            <div className="btn-save-remarks">
              {(!allowSubmit || isSaveDisable || contractSignedDetails.data_filled) ?
                <>{isNextEnable ?
                  <button type="button" className="btn-primary" onClick={() => navigateToNext()}>Next</button>
                  :
                  <span className="span-disabled">
                    Save
                  </span>
                }
                </>
                :
                <button aria-label="save naxt" type="submit" className="btn-primary">
                  Save & Next
                </button>
              }
              <button aria-label="remarks history" type="button" className="btn-line"
                onClick={showModalViewTimeLine}
              >
                Remarks
                <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
              </button>
            </div>
          </Formsy>
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezedNewFlow}
            isRemarkDisable={isRemarkDisable} sectionInfo={sectionInfo} />
        </Modal>
      </div>
    </div>
  );
}
export default RefinanceContractSigningForm;