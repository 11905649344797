import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/customerDetailsFormFields.json";
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/client';
import { SAVE_CUSTOMER_EMPLOYMENT_DETAILS } from '../../../services/customer.gql';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { getLoanDetail } from '../../../store/action/allAction';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, SUB_STATUS_CODES} from '../../../config/constants';
import { useAiIcon } from '../../hooks/useAiIcon';
import AiIcon from '../../elements/ImageAI';
import { SAVE_VIBER_DATA } from '../../../services/leads.gql';

const FORM_FIELDS = FormFields["EMPLOYMENT_DETAILS"];

const EmploymentDetails = (props) => {
    const [employmentDetails, setEmploymentDetails] = useState({});
    const [orgemploymentDetails, setOrgEmploymentDetails] = useState({});
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const { masterdata, lead, remarksHistory } = useSelector(({masterdata, lead}) => ({
      masterdata,
      lead,
      remarksHistory:lead.remarksHistory || [],
      leadFiles: lead && (lead.leadDetail && (lead.leadDetail.leaddocs || [])),
    }));
    const client = useApolloClient(); 
    const dispatch = useDispatch();
    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || []
    const viberPersonalData = getContext?.viberData?.get_viber_column?.personal_details || [];
    const { showAiIcon } = useAiIcon(employmentDetails, viberPersonalData);

    let navigate = useNavigate();
    let { lead_id } = useParams();
    let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "customer-employment-details")?.is_edit_access;

    if(!accessCondition){
      accessCondition=CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

    let ALL_OPTIONS = masterdata?.data;

    useEffect(() => {
      if (lead && lead.leadDetail && lead.leadDetail.customer && lead.leadDetail.customer.employment) {
        let originalEmployment = { ...lead.leadDetail.customer.employment };
        let updatedEmployment = { ...originalEmployment };

        if (viberPersonalData) {
          Object.keys(viberPersonalData).forEach(key => {
            if (!updatedEmployment[key] && viberPersonalData[key]) {
              updatedEmployment[key] = viberPersonalData[key];
            }
          });
        }

        setOrgEmploymentDetails(originalEmployment);
        setEmploymentDetails(updatedEmployment);
      }
    }, [lead]);

  
    const handleInputChange = event => {
        if (event.target.id) {
            setEmploymentDetails(currentValue => ({
                ...currentValue,
                [event.target.id]: event.target.value
            }))
        }
    }

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value != undefined) {
            setEmploymentDetails(currentValue => ({
                ...currentValue,
                [field_name]: data.value
            }))
        }
    }

    const handleSelectChange = (data, field_name) => {
      if (data && field_name) {
        setEmploymentDetails(currentValue => ({
          ...currentValue,
          [field_name]: data.value
        }))
      }
    }

    const handleSubmit = () => {
      let lead_id = lead?.leadDetail?.id || "";
      let customer_id = lead?.leadDetail?.customer?.customer_id;
      let reqBody = {
        lead_id: lead_id,
        customer_id: customer_id,
        employer_name: employmentDetails.employer_name || "",
        business_address: employmentDetails.business_address || "",
        company_phone: employmentDetails.company_phone || "",
        designation: employmentDetails.designation || "",
        year_with_company: employmentDetails.year_with_company || "",
        industry: employmentDetails.industry || "",
        gross_monthly_income: parseFloat(employmentDetails.gross_monthly_income) || 0,
        telephone_number: employmentDetails.telephone_number || "",
        legal_form: employmentDetails.legal_form || "",
        updated_by: user_id || 0
      }

      let viberReqBody = {
        lead_id: lead_id,
        parent_object_name: 'personal_details',
        changed_object: {
          employer_name: employmentDetails.employer_name?.toString() || null,
          business_address: employmentDetails.business_address?.toString() || null,
          company_phone: employmentDetails.company_phone?.toString() || null,
          designation: employmentDetails.designation?.toString() || null,
          year_with_company: employmentDetails.year_with_company?.toString() || null,
          industry: employmentDetails.industry?.toString() || null,
          gross_monthly_income: employmentDetails.gross_monthly_income?.toString() || null,
          telephone_number: employmentDetails.telephone_number?.toString() || null,
          legal_form: employmentDetails.legal_form?.toString() || null
        }
      };
      
      let viberMutation = SAVE_VIBER_DATA;
      executeGraphQLMutation(viberMutation, viberReqBody, client)
        .then(resp => {
          console.log(resp);
        })
        .catch(err => console.log(err));

      let mutation = SAVE_CUSTOMER_EMPLOYMENT_DETAILS, 
          variables = { empDetailInput: reqBody };

      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.save_customer_employment_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        dispatch(getLoanDetail(lead_id,client))
        navigateToNext();
      })
      .catch(err => {
        toast.error(err.message);
      })
    }

    const navigateToNext = () => {
      navigate(`/lead-detail/customer-details/bank-details/${lead_id}`);
    }

    let EmploymentDetailsForm = FORM_FIELDS;

    // All the conditions can be combined here for button enable/disable
    const isSubmitDisabled = useMemo(() => {
      return lead.leadDetail.lead_status_history.filter(v=>v.sub_status_id===6).length ? true : false;
    }, [lead.leadDetail]);

    const showModalViewTimeLine = () => {
      setViewTimeLine(true);
      setShowremarkform(true);
      document.body.classList.add("overflow-hidden");
    };
  
    const hideModalViewTimeLine = () => {
      setViewTimeLine(false);
      document.body.classList.remove("overflow-hidden");
    };
    // const companyNumberFlag = (employmentDetails.telephone_number === '' || employmentDetails.telephone_number === null ) 
    //   && employmentDetails.company_phone === '' ? true : false;

    const sectionInfo = {section : "Customer details" , sub_section : "Employment details"}

    let { BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED} = SUB_STATUS_CODES
    let isSubStatus3Marked = [BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED].includes(lead.leadDetail?.sub_status_id) ? false : true;
    return (
      <div className="image-form-outer">
        <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList}  />
        </div>
        <div className="form-tab-right-panel">
          <div className="lead-detail-from-outer">
            <div className="lead-form-heading">
              <span className="main-heading">Customer Details</span>
              <h2>Employment Details</h2>
            </div>
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={handleSubmit}
              aria-label="lead form field employment"
            >
              <div className="row">
                
                {EmploymentDetailsForm.map((field) => (
                    ["text", "pattern-format", "number-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={(employmentDetails && employmentDetails[field.name])}
                        placeholder=" "
                        {...(field.type === "number-format") ? {
                          thousandsGroupStyle:"thousand",
                          thousandSeparator: ',',
                          decimalSeparator: '.'
                        }: {}}
                        label={field.label}
                        onChange={
                        ["pattern-format", "number-format"].includes(field.type) 
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                        employmentDetails[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                        showAsterisk={field.showAsterisk}
                        readOnly={orgemploymentDetails?.[field.name] && isSubStatus3Marked}
                    />
                    {showAiIcon(field.name) ? <AiIcon /> : null}
                  </fieldset>
                    ) : 
                    
                    field.type === "dropdown" ? (
                    <fieldset class="single-select col-md-6">
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value === (employmentDetails[field.name])),
                            isDisabled: field.readOnly || (orgemploymentDetails?.[field.name] && isSubStatus3Marked) ? true : false
                        }}
                        required={field.required}
                        showAsterisk={field.showAsterisk}
                        value={employmentDetails[field.name]}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                      {showAiIcon(field.name) ? <AiIcon /> : null}
                    </fieldset>
                    ) : null 
                ))}
                
              </div>

              <div className="btn-save-remarks">
              {isSubmitDisabled || !allowSubmit || getContext.markAsFreezedNewFlow || accessCondition ?
                <span className="span-disabled">
                  Save & Next
                </span>
                :
                <button aria-label="save and next" type="submit" className="btn-primary">
                  Save & Next
                </button>}
                <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                  <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                </button>
              </div>
            </Formsy>
          </div>
        </div>
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezedNewFlow}
            accessCondition={accessCondition} sectionInfo = {sectionInfo}/>
          </Modal>
        </div>
      </div>
    );
}
export default EmploymentDetails;