import React from 'react';
import { withFormsy, addValidationRule } from 'formsy-react';
import { PatternFormat, NumericFormat } from 'react-number-format';
import DOMPurify from 'dompurify';
import "react-datepicker/dist/react-datepicker.css";
import { COUNTRY_CODE, COUNTRY_CODE_REGEX, ROUNDOFF_TO_MAX_LIMIT } from '../../config/constants';

// Added common validation rule for all phone numbers
addValidationRule("isValidPhoneNumber", (values, value) => {
  if (value) {
    value = value.trim();
    if(value.includes(COUNTRY_CODE)) {
      value = value.replace(COUNTRY_CODE_REGEX, "").replace(/\s/g, "")
    }
    if (value[0] !== '9') return "Phone number must start with 9";
    if (value.length !== 10) return "Must be of 10 digits";
  }
  return true;
})

addValidationRule('isNegativeValue', function(values, value) {    
  if(value < 0) {
      return "Negative values are not allowed";
  }
  return true;
});
addValidationRule('isZeroValue', function(values, value) {  
  return value < 0 ? "Amount should be greater than zero" : true;
});

addValidationRule("isValidTelephonePhoneNumber", (values, value) => {
  if (value) {
    value = value.trim();
    if (value.length !== 11) return "Must be of 11 digits";
  }
  return true;
})

const FormsyInputField = (props) => {
    const changeHandler = event => {
      const { value, id } = event.target;
      if (!props.errorMessage && value) {
        document.getElementById(id).style.border = "1px solid #bbbbbb";
      }
      if (typeof props.onChange === "function") {
        props.onChange(event);
      }
      if (props?.valueLength) {
        const trimmedValue = ROUNDOFF_TO_MAX_LIMIT(event.target.value, props?.valueLength)
        props.setValue(trimmedValue);
      }
      else {
        props.setValue(event.target.value);
      }
    };
    
    const patternFormatChangeHandler = (data) => {
      if (typeof props.onChange === "function") {
        props.onChange(data);
      }
      if (props?.valueLength) {
        const trimmedValue = ROUNDOFF_TO_MAX_LIMIT(data.value, props?.valueLength)
        props.setValue(trimmedValue);
      }
      else {
        props.setValue(data.value);
      }
    }
    
    const blurHandler = event => {
      const { value, id } = event.target;
      event.target.value = DOMPurify.sanitize(value);
      let errorElement = document
        .getElementById(id)
        .parentNode.querySelector(".error");
      if (props.isRequired && value === '') {
        document.getElementById(id).style.border = "1px solid red";
      }
      let formField = document.getElementById(id).closest(".form-field");
      if (value === "") {
        if (errorElement) errorElement.classList.remove("hide");
        if (formField) formField.classList.remove("active");
      } else {
        if (errorElement) errorElement.classList.add("hide");
        if (formField) formField.classList.add("active");
      }
    }

    const focusHandler = event => {
      const { id } = event.target;
      let formField = document.getElementById(id).closest(".form-field");
      if (formField) formField.classList.add("active");
    };

    let label = props.label;
    // To elipsize long label
    let labeltxtClass= (label && label.length && label.length >=25) && props.col!=="col-md-12" ? 'label-txt':'';
    if (props.isRequired || props.showAsterisk) label += " *";

    let Formatter = props.type === "pattern-format" ? PatternFormat : NumericFormat; 
    let decimalScale= props?.decimalScale ? props?.decimalScale : 2;
    // console.log(decimalScale,props?.decimalScale,"rrrrrrrrrrrrrrrrrrrr")
    return (
        <>
            {["text", "password"].includes(props.type)? (
              <div class="material">
                <input
                  {...props}
                  autoComplete="new-off"
                  style={{ border: props.errorMessage ? "1px solid red" : "" }}
                  className={
                    props.className
                      ? props.className
                      : "form-input"
                  }
                  title={labeltxtClass ? label : ""}
                  onChange={changeHandler}
                  onBlur={blurHandler}
                  onFocus={focusHandler}
                  aria-label={label}
                />
                {props.errorMessage ? (
                  <div className="error-msg-txt">{props.errorMessage}</div>
                ) : (
                  ""
                )}
                <label data-label={label} class={`form-label ${labeltxtClass}`}></label>
              </div>
            ) : ["pattern-format", "number-format"].includes(props.type) ? (
              <div class="material">
                <Formatter
                  {...props}
                  style={{ border: props.errorMessage ? "1px solid red" : "" }}
                  className={
                      props.className
                        ? props.className
                        : "form-input"
                    }
                  format={props.type === "pattern-format" ? props.format : null}
                  title={labeltxtClass ? label : ""}
                  onValueChange={(data,custom_event) => patternFormatChangeHandler(data, custom_event)}
                  onBlur={blurHandler}
                  allowEmptyFormatting
                  onFocus={focusHandler}
                  decimalScale={decimalScale}
                />
                {props.errorMessage ? (
                  <div className="error-msg-txt">{props.errorMessage}</div>
                ) : (
                  ""
                )}
                <label data-label={label} class={`form-label ${labeltxtClass}`}></label>
              </div>
            ) : null}
        </>
    )
}

export default withFormsy(FormsyInputField);