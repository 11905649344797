import React, { useEffect, useState } from 'react';
import FormsyInputField from '../elements/FormsyInputField';
import { addValidationRule } from 'formsy-react';
import FormsySelect from '../elements/FormsySelect';
import FormsyCheckBox from '../elements/FormsyCheckBox';
import { FINANCIER_DECIMAL_LIMIT, FINANCIER_ID, PAYMENT_TYPE, PAYMENT_TYPE_AMOUNT_LABEL } from '../../config/constants';

const CUSTOM_OPTION = {id:0,label:"Custom",value:"custom"};

const TRANCHE_STATUS = {
  "paid": "Paid",
  "on_hold": "On Hold",
  "requested": "Requested"
}

const SplitPaymentForm = (props) => {
  let { splitPaymentDetails, handleInputChange, splitPaymentFields, setSplitPaymentDetails, index, handleEditChange, handleDeleteChange, leadDetail, mainDealerFlag, setDisabledbutton, isADPCase, getTranchePaymentType, financierId,
    isPayViaRecoveryInitiated,
  } = props; 

  addValidationRule('isValidDealerPayment', function(values, value) {
    let split_payment_amount = splitPaymentDetails.length ? splitPaymentDetails.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.amount), 0) : 0;
    let main_dealer = splitPaymentDetails.length ? parseFloat(splitPaymentDetails[0]["amount"]) : 0;

    if(main_dealer <=0)
     return "Main Dealer Payment should be greater than zero";
    if(Number(value) <= 0 && value)
     return "Amount should be greater than zero";
    if(parseFloat(Math.round(split_payment_amount * 100) / 100) !== parseFloat(Math.round(values.total_payment_to_dealer * 100) / 100) && !mainDealerFlag)
      return "Sum of Split Payments should be equal to Total payment to dealer";
    else
      return true;
  });

  addValidationRule('isValidAccountNumber', function(values, value) {
    const accountNumbers = splitPaymentDetails.map(details => details.account_number);
    let bank_acc_number_match =  accountNumbers.some((accountNumber, index) => {
      return accountNumbers.indexOf(accountNumber) !== index;
    });

    if(bank_acc_number_match && !mainDealerFlag)
      return "Account number of all split payment must be different";
    else
      return true;
  });
  const [bankOptions, setBankOptions] = useState([]);

  useEffect(() => {
    let bankData = [];
    if(leadDetail?.dealerData) {
      leadDetail?.dealerData?.[0]?.bank_details?.map((dealer,index) => {
        return bankData.push({
          id: index + 1,
          label: dealer.account_number + " - " + dealer.branch_name,
          value: dealer.account_number,
        })
      })
    }
    bankData.push(CUSTOM_OPTION)
    setBankOptions(bankData);
  },[leadDetail])

  const handleCheckboxChange = (event, index) => {
    const modifiedData = splitPaymentDetails.map((data, dataIndex) => {
      if (dataIndex === index) {
        return {
          ...data,
          is_tranche_marked: event.target.checked ? true : false,
        };
      }
      return data;
    });
    if(leadDetail?.is_pushed_to_pb==="1" && !splitPaymentDetails?.[index]?.is_tranche_marked) event.target.checked ? setDisabledbutton(false): setDisabledbutton(true);
    setSplitPaymentDetails(modifiedData);
  }
  const { payment_type: paymentType } = getTranchePaymentType(mainDealerFlag, index, isADPCase);
  const trancheStatus = leadDetail?.split_payment?.find(data => data.payment_type === paymentType)?.tranche_status;
  const isCheckboxChecked = () => {
    const isChecked = ['paid', 'requested'].includes(trancheStatus) || splitPaymentDetails[index]?.is_tranche_marked || splitPaymentDetails[index]?.is_tranche_marked_for_recovery;
    if (isChecked === undefined) return false;
    return isChecked;
  };
  let disableFieldsArray = ['bank_name','branch_name','account_holder_name','account_number'];
  // if(!isCheckboxMarked) disableFieldsArray = [...disableFieldsArray,'amount'];
  let disableAmountField = mainDealerFlag ? (isADPCase ? [0,1,2] : [0,1]) : [0];

  let selectCondition = leadDetail?.is_pushed_to_pb==="1" ? (leadDetail?.is_pushed_to_pb==="1" && (!splitPaymentDetails?.[index]?.is_tranche_marked )) : false;
  let split2Index = mainDealerFlag ? 2 : 1;
    return (
        <div className="row"> 
        { 
            <div className="col-md-12">
              <div className='split-act-btn'> 
              <FormsyCheckBox
                id="cb_pa_sameas_cra"
                name="cb_pa_sameas_cra"
                type="checkbox"
                className="custom-control-input"
                value="1"
                checked={isCheckboxChecked()}
                onChange={(e) => handleCheckboxChange(e,index)}
                disabled={(isPayViaRecoveryInitiated || leadDetail?.is_pushed_to_pb==="1" && ['requested','paid'].includes(leadDetail?.split_payment?.find(split => split.payment_type === splitPaymentDetails[index]?.payment_type)?.tranche_status))}
              />
                <h3>{ splitPaymentDetails?.[index]?.['payment_type'] === "main_dealer" ? "Main Dealer" : splitPaymentDetails?.[index]?.['payment_type'] === "split_dealer" ? `Split Payment 1` : splitPaymentDetails?.[index]?.['payment_type'] === "onhold_dealer" ? `On Hold Dealer Payment` : `Split Payment 2`}</h3>
                {leadDetail?.split_payment?.length > 0 &&
                <span className={trancheStatus && 'btn-primary-accent history-btn tranche-status' || ''}>{TRANCHE_STATUS[trancheStatus]}</span>}
                {leadDetail?.split_payment?.filter(data => data.payment_type === 'split_dealer2')?.length ? null
                : splitPaymentDetails?.[index]?.['payment_type'] === "split_dealer2" ?
                  <ul className='action-btn'>
                  <li className='btn-edit' onClick={e => handleEditChange(index)}><i className="ic-edit"></i></li>
                  <li className='btn-delete' onClick={e => handleDeleteChange(index)}><i class="ic-delete-icn"></i></li>
                  </ul> : null
                }
              </div>
            </div>
        }
        {splitPaymentFields.map((field) => {
        return (
            ["text", "pattern-format", "number-format"].includes(field.type) ? (
          <fieldset class="form-filed col-md-6 ">
            <FormsyInputField
                id={"split_"+field.id}
                name={field.name}
                type={field.type}
                value={splitPaymentDetails && splitPaymentDetails[index] && (splitPaymentDetails[index][field.name] || "")}
                placeholder=" "
                {...(field.type === "number-format") ? {
                  thousandsGroupStyle:"thousand",
                  thousandSeparator: ',',
                  decimalSeparator: '.'
                }: {}}
                label={field.name === "amount" ? PAYMENT_TYPE_AMOUNT_LABEL[splitPaymentDetails?.[index]?.payment_type]: field.label}
                onChange={(e) => handleInputChange(field.id, e, field.type, "split_payment", index)}
                format={field.ApplyNumberformat ? field.format : ""}
                validations={(field.name === "amount" && index !==0) ? "isValidDealerPayment" : (field.name === "account_number" && index !==0) ? "isValidAccountNumber" : field.validations}
                validationError={field.validationError}
                required={isCheckboxChecked() || (mainDealerFlag && index === 0) || (field.name === 'amount' && [PAYMENT_TYPE.SplitDealer2].includes(splitPaymentDetails?.[index]?.payment_type))}
                disabled={
                  isPayViaRecoveryInitiated || 
                  (leadDetail?.is_pushed_to_pb === "1" && !leadDetail?.title_payment?.map(data => ["requested", "on_hold", "paid"].includes(data?.tranche_status))?.length && (!splitPaymentDetails?.[index]?.is_tranche_marked) && !(index === split2Index && field.name === 'amount' && !props?.isRegenrationCase))
                    || (index === 0 && leadDetail?.dealerData?.[0]?.[field.name] !== null)
                    || (disableAmountField.includes(index) && field.name === "amount")
                    || (['on_hold'].includes(leadDetail?.split_payment?.find(split => split.payment_type === splitPaymentDetails[index]?.payment_type)?.tranche_status) && field.name === 'amount' && !props?.isRegenrationCase)
                    || (splitPaymentDetails[index]?.bank_account !== 'custom' && disableFieldsArray.includes(field.name))
                    || ['requested', 'paid'].includes(leadDetail?.split_payment?.find(split => split.payment_type === splitPaymentDetails[index]?.payment_type)?.tranche_status)
                    || (!["requested", "paid", "on_hold"].includes(splitPaymentDetails[index]?.tranche_status) && !props?.isRegenrationCase && leadDetail?.split_payment?.filter(split => split.payment_type === splitPaymentDetails[index]?.payment_type)?.length && field.name === 'amount') ? true : false
                }
                decimalScale={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_DECIMAL_LIMIT.JACCS_FINANCE) || FINANCIER_DECIMAL_LIMIT.DEFAULT}
              />
          </fieldset>
          ) :
            field.type === "dropdown" ? (
              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={field.name}
                  id={field.id}
                  inputProps={{
                    options: (isADPCase ? index > 1 : index !==0) ? [...bankOptions?.filter(data => {
                      return data.value!=='custom' && splitPaymentDetails?.every(split => split.account_number !== data.value)
                    }),CUSTOM_OPTION] : bankOptions?.filter(data => data.value !== 'custom'),
                    placeholder: field.label,
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: bankOptions?.filter(({ value }) => splitPaymentDetails?.[index]?.bank_account?.includes(value)),
                    isDisabled: isPayViaRecoveryInitiated || (['requested','paid'].includes(leadDetail?.split_payment?.find(split => split.payment_type === splitPaymentDetails[index]?.payment_type)?.tranche_status) || selectCondition ? true : false) || (isADPCase && index === 1)
                    }}
                  value={splitPaymentDetails[index]?.bank_account}
                  onChange={(e) => handleInputChange(field.id, e, field.type, "split_payment", index)}
                  required={isCheckboxChecked() || (mainDealerFlag && index === 0)}
                />
              </fieldset>
              ) : null
        )})}
        
      </div>
    );
}
export default SplitPaymentForm;