import React, { useEffect, useMemo, useState } from 'react';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, PAYMENT_STATUS, PAYMENT_TYPE_LABEL, PAYMENT_TYPES, PAYMENT_TYPES_ALTERNATE, TRANCHE_STATUS, TRANCHE_STATUS_LABEL } from '../../config/constants';
import { useOutletContext } from 'react-router-dom';
import noTitlePayment from "../../webroot/images/no-title-payment.svg";
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import Modal from '../elements/Modal';
import Formsy, { addValidationRule } from 'formsy-react';
import FormsyInputField from '../elements/FormsyInputField';
import FormsyCheckBox from '../elements/FormsyCheckBox';
import { tt_payment_details } from './formFields/titleTransferFormFields.json';
import FormsySelect from '../elements/FormsySelect';
import { useSelector } from 'react-redux';
import generalfnction from '../../services/generalService';
import { ADDITIONAL_PAYMENT_FORM_FIELDS } from './formFields/AdditionalPaymentFormFields.json';
import { CREATE_ADDITIONAL_PAYMENT } from '../../services/leads.gql';
import { toast } from 'react-toastify';
const AdditionalPaymentsFormFields = ({ additionalPaymentDetails, setAdditionalPaymentDetails, payment, handleInputChange, ALL_OPTIONS, index, splitTrancheKeyPair }) => {
    addValidationRule("betweenPaidAmount", (values, value, { payment }) => {
        const { payment_type } = payment;
        const currentPaidTranche = splitTrancheKeyPair?.[PAYMENT_TYPES_ALTERNATE?.[payment_type]] || {};
        const maxAmount = +currentPaidTranche?.amount;        
        if (value < 1 || maxAmount < value) return `Amount should be beteen 1 to ${maxAmount}`;
        return true;
    })
    return ADDITIONAL_PAYMENT_FORM_FIELDS?.map((field) => {
        const isEditAllowed = (payment, index) => {
            return !payment?.is_edit_allowed;
        };
        if (["dropdown"].includes(field?.type)) {
            return (<fieldset class="single-select col-md-6">
                <FormsySelect
                    name={field?.name}
                    id={field?.id}
                    inputProps={{
                        options: (["payment_type"]?.includes(field?.name) && !payment?.id) ? ALL_OPTIONS["additionalPaymentRemOptions"] || [] : ALL_OPTIONS?.[field?.options] || [],
                        placeholder: field?.label,
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value: ALL_OPTIONS?.[field?.options]?.filter(({ value }) => payment?.[field?.name] === value),
                        isDisabled: isEditAllowed(payment, index) || [TRANCHE_STATUS.paid, TRANCHE_STATUS.requested].includes(payment.tranche_status)
                    }}
                    onChange={(e) => handleInputChange(e, index, field?.name)}
                    required={field?.required}
                    showAsterisk={field?.showAsterisk}
                />
            </fieldset>);
        } else if (["text", "number-format"].includes(field?.type)) return (<fieldset class="form-filed col-md-6 ">
            <FormsyInputField
                id={field?.id}
                name={field?.name}
                type={field?.type}
                value={payment?.[field?.name] || null}
                placeholder={""}
                label={field?.label}
                onChange={(e) => handleInputChange(e, index, field?.name)}
                format={field?.ApplyNumberformat ? field?.format : ""}
                validations={["amount"]?.includes(field?.name) ? {"betweenPaidAmount":{ payment }} :field?.validations || null}
                validationError={
                    payment?.[field?.name] ? field?.validationError : ""
                }
                required={field?.required}
                disabled={isEditAllowed(payment, index)}
            />
        </fieldset>);
        else (<></>);
    });
};

const AdditionalPayments = (props) => {
    let { bankList, numPayments, ttPaymentDetails, handleEditChange, handleDeleteChange, setTtPaymentDetails, disabledbutton, setDisabledbutton, isADPCase, handleSubmit, setIsLoading,
        savedAdditionalPayments, setSavedAdditionalPayments, updateAdditionalPayment, deleteAdditionalPayment, handleReupload, showDocs, setShowDocs, showEditDocs, setShowEditDocs,
        additionalPaymentDetails, setAdditionalPaymentDetails, handleAdditionalPaymentSubmit, splitTrancheKeyPair, additionalPaymentOptions, additionalPaymentRemOptions
    } = props;
    const [isValidSubmit, setIsValidSubmit] = useState(false);
    const ALL_OPTIONS = useMemo(() => {
        return { additionalPaymentOptions, additionalPaymentRemOptions, bankList };
    }, [additionalPaymentOptions, bankList]);

    const handleInputChange = (event, index, field) => {
        const newValue = {};
        
        if (["payment_type"].includes(field)) { 
            const isPresent = additionalPaymentDetails?.find(item => item?.payment_type === event?.value);
            if (isPresent) {
                toast.error(`${PAYMENT_TYPE_LABEL[event?.value]} is already selected!`)
                return;                
            }
        }
        if (["payment_type"].includes(field)) {
            newValue[field] = event?.value
        } else {
            newValue[event?.target?.id] = event?.target?.value
        }
        additionalPaymentDetails[index] = { ...additionalPaymentDetails[index], ...newValue };
        setAdditionalPaymentDetails([...additionalPaymentDetails]);
    }
    
    const { lead, remarksHistory, leadDetail } = useSelector(({masterdata, lead}) => ({
      masterdata,
      lead,
      remarksHistory:lead.remarksHistory || [],
      leadDetail: lead.leadDetail
    }));
    const [showremarkform,setShowremarkform]=useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const getContext = useOutletContext();
    let accessCondition = !IS_USER_ACCESS_PAGE("payment_verification", "payment_verification")?.is_edit_access;
    if(!accessCondition){
        accessCondition=CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }
    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add("overflow-hidden");
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove("overflow-hidden");
    };

    const sectionInfo = {section:"Payment verification" , sub_section:""}

    const allowEditing = (payment, index) => {
        console.log("index", index, additionalPaymentDetails);
        additionalPaymentDetails[index].is_edit_allowed = !additionalPaymentDetails?.[index]?.is_edit_allowed;
        setAdditionalPaymentDetails([...additionalPaymentDetails])
    }

    const handleEditDocUpload = (e, index) => {
        
        if ((additionalPaymentDetails?.[index]?.docs?.length || 0) + (showEditDocs?.[index]?.length || 0) >= 5) {
            toast.error(`Max 5 docs can be uploaded`)
            return;
        }

        if (additionalPaymentDetails?.[index]?.id) {
            handleReupload(e, { id: 0 }, { payment_type: additionalPaymentDetails?.[index]?.payment_type || "" });
            return;
        }

        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('upload_type', 'finance_docs');
        formData.append("lead_id", "lead_id");
        formData.append('images', file);
        e.target.value = '';
        generalfnction.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }).then(async (res) => {
            if (res?.data?.data && res?.data?.data?.[0].file_url) {
                additionalPaymentDetails[index].docs = additionalPaymentDetails?.[index]?.docs || [];
                additionalPaymentDetails[index].new_docs = additionalPaymentDetails?.[index]?.new_docs || [];
                additionalPaymentDetails[index].new_docs?.push(res?.data?.data?.[0]?.file_url);
                setAdditionalPaymentDetails([...additionalPaymentDetails]);            
                if (file) {
                    const localPreview = URL.createObjectURL(file);
                    setShowEditDocs(prevDocs => {
                        const updatedDocs = [...prevDocs];
                        if (updatedDocs[index]) {
                            updatedDocs[index] = [...updatedDocs[index], localPreview];
                        } else {
                            updatedDocs[index] = [localPreview];
                        }
                        return updatedDocs;
                    });
                }
            }
        })
    }
    

    const handleCheckbox = (payment, index) => {
        // Create a new copy of the savedAdditionalPayments array
        additionalPaymentDetails[index].is_checked = !additionalPaymentDetails?.[index]?.is_checked;
        setAdditionalPaymentDetails([...additionalPaymentDetails]);
    };

    const isTranchSelected = (payment, index) => {
        return [TRANCHE_STATUS.paid, TRANCHE_STATUS.requested].includes(payment?.tranche_status) || payment?.is_checked || false;
    }

    const handleReuploadClick = (payment, index) => {
        const fileInput = document.getElementById(`reupload-${payment.id}-${index}`);
        if (fileInput) {
            fileInput.click();
        }
    }

  return (
    <div className="image-form-outer">
            <div className="lead-detail-from-outer w-100">
                { !additionalPaymentDetails?.length  ?  
                    <div className="row">
                        <div className="verfication-img-no-image no-payment-bg col-md-12">
                            <div className='round-bg'>
                            <img alt="No Payment Requested" src={noTitlePayment} className="" />
                            </div>
                            <span className="no-img-txt">No Payment Requested</span>
                        </div>
                    </div>
                  :

                <>
                <Formsy
                className="lead-form-filed"
                          autoComplete="off"
                          onValid={() => setIsValidSubmit(false)}
                          onInvalid={() => setIsValidSubmit(true)}
                          onValidSubmit={() => handleAdditionalPaymentSubmit()}
                      >
                    {
                        additionalPaymentDetails?.map((payment,index)=>(<div class="row">
                            <div className="col-md-12">
                              <div className='split-act-btn heading-tranches'> 
                              <FormsyCheckBox
                                  id="cb_pa_sameas_cra"
                                  name="cb_pa_sameas_cra"
                                  type="checkbox"
                                  className="custom-control-input"
                                  value="1"
                                  checked={isTranchSelected(payment, index) || null}
                                onChange={(e) => handleCheckbox(payment, index)}
                                disabled={[TRANCHE_STATUS.paid, TRANCHE_STATUS.requested].includes(payment.tranche_status)}
                              />
                                <h3>{PAYMENT_TYPE_LABEL[payment.payment_type] || ""}</h3>
                                    <span className={payment.tranche_status && 'btn-primary-accent history-btn tranche-status' || ''}>{TRANCHE_STATUS_LABEL[payment.tranche_status]}</span>
                                    {![TRANCHE_STATUS.paid, TRANCHE_STATUS.requested].includes(payment.tranche_status) && 
                                        <ul className='action-btn'>
                                            {payment?.is_edit_allowed && <li className='edit-icon check-icon' onClick={(e) => { payment?.id && updateAdditionalPayment(payment); allowEditing(payment,index)}}><i class="ic-check"></i></li>}
                                            {!payment?.is_edit_allowed && <li className='btn-edit' onClick={e => allowEditing(payment, index)}><i className="ic-edit"></i></li>}
                                            <li className='btn-delete' onClick={e => deleteAdditionalPayment(payment, index)}><i class="ic-delete-icn"></i></li>
                                        </ul> || <></>
                                    }
                              </div>
                            </div>
                            {<AdditionalPaymentsFormFields index={index} additionalPaymentDetails={additionalPaymentDetails} setAdditionalPaymentDetails={setAdditionalPaymentDetails} handleInputChange={handleInputChange} payment={payment} ALL_OPTIONS={ALL_OPTIONS} splitTrancheKeyPair={splitTrancheKeyPair} />}
                                <div className='col-md-12 doc-count-txt additonal-doc-section'>
                                    <div className='additionl-doc-heading'>
                                        <label>
                                            Extra Payment {index+1} Approval Proof
                                        </label>
                                            <span className="lead-doc-count">{(payment?.docs?.length || 0) + (showEditDocs?.[index]?.length || 0)} / {5}</span>
                                    </div>
                                    <div className="more-file-upload doc-upload-btn">
                                        <input aria-label="img upload" type="file" name="" accept=".pdf,image/*" onChange={(e) => handleEditDocUpload(e, index)} required={false} />
                                        <button aria-label="img upload btn" type="button" className="btn-line">
                                            <i className='ic-upload'></i> Upload
                                        </button>
                                    </div>
                                </div>
                            {payment?.docs?.map((doc, index) =>
                                <div className="col-md-4 ">
                                    <div className='add-doc-img-view'>
                                        <img src={doc.doc_path} />
                                    </div>
                                    <h5 onClick={()=>handleReuploadClick(payment,index)} style={{cursor: 'pointer'}}>Re-upload Doc</h5>
                                    <input
                                        type="file"
                                        id={`reupload-${payment.id}-${index}`}
                                        style={{ display: "none" }}
                                        onChange={(e)=>handleReupload(e, doc, payment)}
                                    />
                                    <br />
                                </div>)}
                            {showEditDocs?.[index] && showEditDocs[index].map((doc) =>
                                <div className="col-md-4 ">
                                    <div className='add-doc-img-view'>
                                        <img src={doc} />
                                    </div>
                                </div>)}

                        </div>
                            )) 
                    }
                          <div className="btn-save-remarks">
                              <button type="submit" className="btn-primary" disabled={getContext.markAsFreezed || isValidSubmit}>
                                Save & Next
                            </button>
                        <button type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                            <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                        </button>
                    </div>
                </Formsy>
                </>
                }
            </div>
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezed} accessCondition={accessCondition} sectionInfo={sectionInfo}/>
          </Modal>
        </div>
    </div>
  );
}
export default AdditionalPayments;