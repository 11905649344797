import React, { useEffect, useMemo } from "react";
import * as moment from "moment";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  DisputeType,
  PAYMENT_TYPE,
  PAYMENT_TYPE_LABEL,
  PAYMENT_VERIFICATION_SCREENS,
  STATUS_CODES,
  SUB_STATUS_CODES,
} from "../../../config/constants";
import { toast } from "react-toastify";

const PaymentSection = ({
  title,
  amount,
  amount_to_be_paid,
  disputedCases,
  lostCases,
  handleChange,
  payment_type,
  paymentRecoveryPresent,
}) => {
  return (
    <div className="payment-section payment-recovery-outer">
      <Accordion allowZeroExpanded className="accordion">
        <AccordionItem className="acc-item-financer">
          <AccordionItemHeading className="acc-heading-financer">
            <AccordionItemButton>
              <div className="section-header">
                <h3>{PAYMENT_TYPE_LABEL?.[payment_type] || ""} Payment</h3>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="acc-panel-finaner">
            <div className="dealer-payment-acc-sec">
              <div className="dealer-amt-txt">
                <label>{title} Amount</label>
                <span>{amount}</span>
              </div>
              {(disputedCases?.length && (
                <DisputedCases
                  title="Disputed Cases"
                  payment_type={payment_type}
                  cases={disputedCases}
                  case_type={"disputedCases"}
                  handleChange={handleChange}
                  paymentRecoveryPresent={paymentRecoveryPresent}
                />
              )) || <></>}
              {(lostCases?.length && (
                <DisputedCases
                  title="Lost After Disbursal Cases"
                  payment_type={payment_type}
                  cases={lostCases}
                  case_type={"lostCases"}
                  handleChange={handleChange}
                  paymentRecoveryPresent={paymentRecoveryPresent}
                />
              )) || <></>}
              <br />
              <div className="dealer-amt-txt">
                <label>Amount To be paid</label>
                <span>{amount_to_be_paid}</span>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

const DisputedCases = ({
  title,
  cases,
  handleChange,
  payment_type,
  case_type,
  paymentRecoveryPresent,
}) => (
  <div className="disputed-cases p-lg-t">
    <span className="dispute-case-id-heading">
      {title} ({cases?.length})
    </span>
    {cases?.map((loan, index) => (
      <LoanDetails
        key={index}
        loan={loan}
        handleChange={handleChange}
        payment_type={payment_type}
        case_type={case_type}
        paymentRecoveryPresent={paymentRecoveryPresent}
      />
    ))}
  </div>
);

const LoanDetails = ({
  loan,
  handleChange,
  payment_type,
  case_type,
  paymentRecoveryPresent,
}) => (
  <div className="dealer-payment-detail-outer">
    <h5>Disputed Loan ID - {loan?.lead_id}</h5>
    {loan?.disputed_payment_type?.map((payment, index) => (
        
        <div className="dealer-payment-list" key={index}>
            {console.log("payment>>>", payment)}
        <div className="checkbox-txt">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input "
              id={`payment-${loan.id}-${index}`}
              checked={payment?.isChecked || false}
              disabled={paymentRecoveryPresent || payment?.isDisabled || false}
              onChange={(e) =>
                handleChange({
                  payment_type,
                  case_type,
                  case_payment_type: payment?.payment_type,
                  lead_id: loan?.lead_id,
                  e,
                })
              }
            />
            <label className="custom-control-label"></label>
          </div>
          <div
            className="payment-type-date"
            htmlFor={`payment-${loan.id}-${index}`}
          >
            <span className="payment-title">
              {PAYMENT_TYPE_LABEL?.[PAYMENT_TYPE?.[payment?.payment_type]]}{" "}
              Amount
            </span>
                    <span className="payment-date">Disputed Date - { moment(payment?.created_date)?.format("YYYY-MM-DD")}</span>
          </div>
        </div>
        <input
          type="number"
          className="payment-amount-input"
          value={+payment?.amount_to_recovered || 0}
          disabled={paymentRecoveryPresent || payment?.isDisabled || false}
          onChange={(e) =>
            handleChange({
              payment_type,
              case_type,
              case_payment_type: payment?.payment_type,
              lead_id: loan?.lead_id,
              e,
            })
          }
        />
      </div>
    ))}
  </div>
);

const PaymentRecovery = (props) => {
  const {
    screenToShow,
    setScreenToShow,
    leadDetail,
    paymentVerificationDetails,
    setPaymentVerificationDetails,
    splitPaymentDetails,
    setSplitPaymentDetails,
    disputeData,
    setDisputeData,
    tranchData,
    setTranchData,
    recoveryData,
    setRecoveryData,
    savePaymentRecovery,
  } = props;
  const paymentRecoveryPresent = useMemo(() => {
    const statusIds =
      leadDetail?.lead_status_history?.map(
        (history) => history?.sub_status_id
      ) || [];
    return statusIds?.includes(SUB_STATUS_CODES.PAYMENT_RECOVERY);
  }, [leadDetail?.lead_status_history?.length]);

  useEffect(() => {
    const recovery_data = {};
    disputeData?.[0]?.disputed_lead?.map((item) => {
      item?.disputed_payment_type?.map((case_item) => {
        recovery_data[
          `disputedCases-${case_item?.lead_id}-${case_item?.payment_type}`
        ] = { ...case_item };
        case_item.amount_to_recovered = case_item?.amount_to_be_recovered;
        return case_item;
      });
    });
    disputeData?.[1]?.disputed_lead?.map((item) => {
      item?.disputed_payment_type?.map((case_item) => {
        recovery_data[
          `lostCases-${case_item?.lead_id}-${case_item?.payment_type}`
        ] = { ...case_item };
        case_item.amount_to_recovered = case_item?.amount_to_be_recovered;
        return case_item;
      });
    });
    const sections = splitPaymentDetails?.map((split) => {
      split.title = PAYMENT_TYPE_LABEL?.[split?.payment_type] || "";
      if (paymentRecoveryPresent) {
        const { disputedCases, lostCases } = split?.dispute_recoverd?.reduce(
          (result, split) => {
            if (split?.dispute_type === DisputeType.Disputed) {
              const index = result?.disputedCases?.findIndex(
                (item) => item?.lead_id === split?.lead_id
              );
              if (index >= 0) {
                result.disputedCases[index].disputed_payment_type.push({
                  ...split,
                  amount_to_recovered: +split?.amount,
                  isChecked: true,
                });
              } else {
                const objToPush = {
                  lead_id: split?.lead_id,
                  disputed_payment_type: [
                    {
                      ...split,
                      amount_to_recovered: +split?.amount,
                      isChecked: true,
                    },
                  ],
                };
                result.disputedCases?.push(objToPush);
              }
            } else if (split?.dispute_type === DisputeType.LostAfterDisbursal) {
              const index = result?.lostCases?.findIndex(
                (item) => item?.lead_id === split?.lead_id
              );
              if (index >= 0) {
                result.lostCases[index].disputed_payment_type.push({
                  ...split,
                  amount_to_recovered: +split?.amount,
                  isChecked: true,
                });
              } else {
                const objToPush = {
                  lead_id: split?.lead_id,
                  disputed_payment_type: [
                    {
                      ...split,
                      amount_to_recovered: +split?.amount,
                      isChecked: true,
                    },
                  ],
                };
                result.lostCases?.push(objToPush);
              }
            }
            return result;
          },
          { disputedCases: [], lostCases: [] }
        ) || { disputedCases: [], lostCases: [] };
        split.disputedCases = disputedCases || [];
        split.lostCases = lostCases || [];
      } else {
        split.amount_to_be_paid = split?.amount;
        split.disputedCases = JSON.parse(
          JSON.stringify(disputeData?.[0]?.disputed_lead || [])
        );
        split.lostCases = JSON.parse(
          JSON.stringify(disputeData?.[1]?.disputed_lead || [])
        );
      }
      return split;
    });
    setTranchData([...sections]);
    setRecoveryData({ ...recovery_data });
  }, [
    splitPaymentDetails,
    disputeData?.[0]?.disputed_lead,
    disputeData?.[1]?.disputed_lead,
    paymentRecoveryPresent,
  ]);

  const handleChange = ({
    payment_type,
    case_type,
    lead_id,
    case_payment_type,
    e,
  }) => {
    // Find the current tranche
    const currentTranche = tranchData?.find(
      (item) => item?.payment_type === payment_type
    );
    if (!currentTranche) return;

    // Find the specific case for the lead ID
    const caseData = currentTranche?.[case_type]?.find(
      (item) => item?.lead_id === lead_id
    );
    if (!caseData) return;

    // Find the payment entry for the specific case payment type
    const paymentData = caseData?.disputed_payment_type?.find(
      (item) => item?.payment_type === case_payment_type
    );
    if (!paymentData) return;
    const current_tranche_amount_to_recovered = getCurrentAmountToRecovered({
      tranchData,
      payment_type,
    });
    const { amount_to_recovered, amount_to_be_recovered } =
      calculateRequiredData({
        tranchData,
        lead_id,
        case_payment_type,
        case_type,
      });
    if (e?.target?.type === "checkbox") {
      paymentData.isChecked = !paymentData?.isChecked;
      if (paymentData?.isChecked) {
        const efective_current_amount_to_recovered =
          current_tranche_amount_to_recovered +
          parseInt(paymentData?.amount_to_recovered);
        const efective_amount_to_recovered =
          amount_to_recovered + parseInt(paymentData?.amount_to_recovered);
        if (currentTranche?.amount < efective_current_amount_to_recovered) {
          toast.error(
            `Total recovery selected amount cannot be greater than tranch amount!`
          );
          paymentData.isChecked = !paymentData?.isChecked;
        } else if (
          amount_to_be_recovered < efective_amount_to_recovered &&
          amount_to_be_recovered > amount_to_recovered
        ) {
          paymentData.amount_to_recovered =
            amount_to_be_recovered - amount_to_recovered;
          toast.warning(
            `Amount to be recovered for lead id ${lead_id} and ${
              PAYMENT_TYPE_LABEL?.[PAYMENT_TYPE?.[case_payment_type]]
            } is ${amount_to_be_recovered} only, so remaining value is selected!`
          );
        } else if (amount_to_be_recovered < efective_amount_to_recovered) {
          paymentData.isChecked = !paymentData?.isChecked;
          toast.error(
            `Amount to be recovered for lead id ${lead_id} and ${
              PAYMENT_TYPE_LABEL?.[PAYMENT_TYPE?.[case_payment_type]]
            } is ${amount_to_be_recovered} only!`
          );
        }
      }
    } else {
      if (paymentData?.isChecked) {
        const efective_current_amount_to_recovered =
          current_tranche_amount_to_recovered -
          parseInt(paymentData?.amount_to_recovered) +
          parseInt(e?.target?.value);
        const efective_amount_to_recovered =
          amount_to_recovered -
          parseInt(paymentData?.amount_to_recovered) +
          parseInt(e?.target?.value);
        if (currentTranche?.amount < efective_current_amount_to_recovered) {
          toast.error(
            `Total recovery selected amount cannot be greater than tranch amount!`
          );
        } else if (
          amount_to_be_recovered < efective_amount_to_recovered &&
          amount_to_be_recovered > amount_to_recovered
        ) {
          paymentData.amount_to_recovered =
            amount_to_be_recovered - amount_to_recovered;
          toast.warning(
            `Amount to be recovered for lead id ${lead_id} and ${
              PAYMENT_TYPE_LABEL?.[PAYMENT_TYPE?.[case_payment_type]]
            } is ${amount_to_be_recovered} only, so remaining value is selected!`
          );
        } else if (amount_to_be_recovered < efective_amount_to_recovered) {
          toast.error(
            `Amount to be recovered for lead id ${lead_id} and ${
              PAYMENT_TYPE_LABEL?.[PAYMENT_TYPE?.[case_payment_type]]
            } is ${amount_to_be_recovered} only!`
          );
        } else {
          paymentData.amount_to_recovered = +e?.target?.value;
        }
      } else {
        paymentData.amount_to_recovered = +e?.target?.value;
      }
    }
    let current_tranche_amount_to_recovered_again = getCurrentAmountToRecovered(
      { tranchData, payment_type }
    );
    currentTranche.amount_to_be_paid =
      currentTranche?.amount - current_tranche_amount_to_recovered_again;
    setTranchData([...tranchData]);
  };
  const getCurrentAmountToRecovered = ({ tranchData, payment_type }) => {
    let current_tranche_amount_to_recovered = 0;
    const currentTranche = tranchData?.find(
      (item) => item?.payment_type === payment_type
    );
    currentTranche?.disputedCases?.reduce((result, lead_item) => {
      // Find the payment entry for the specific case payment type
      lead_item?.disputed_payment_type?.reduce((result, case_item) => {
        if (case_item?.isChecked)
          current_tranche_amount_to_recovered +=
            +case_item?.amount_to_recovered || 0;
        return result;
      }, 0);
      return result;
    }, 0);
    currentTranche?.lostCases?.reduce((result, lead_item) => {
      // Find the payment entry for the specific case payment type
      lead_item?.disputed_payment_type?.reduce((result, case_item) => {
        if (case_item?.isChecked)
          current_tranche_amount_to_recovered +=
            +case_item?.amount_to_recovered || 0;
        return result;
      }, 0);
      return result;
    }, 0);
    return current_tranche_amount_to_recovered;
  };

  const calculateRequiredData = ({
    tranchData,
    lead_id,
    case_payment_type,
    case_type,
  }) => {
    const { amount_to_recovered } = tranchData?.reduce(
      (result, item) => {
        // Find the specific case for the lead ID
        const caseData = item?.[case_type]?.find(
          (lead_item) => lead_item?.lead_id === lead_id
        );
        if (!caseData) return result;

        // Find the payment entry for the specific case payment type
        const paymentData = caseData?.disputed_payment_type?.find(
          (case_item) =>
            case_item?.payment_type === case_payment_type &&
            case_item?.isChecked
        );
        if (!paymentData) return result;

        result.amount_to_recovered += +paymentData?.amount_to_recovered || 0;
        return result;
      },
      { amount_to_recovered: 0 }
    );
    return {
      amount_to_recovered,
      amount_to_be_recovered:
        recoveryData?.[`${case_type}-${lead_id}-${case_payment_type}`]
          ?.amount_to_be_recovered,
    };
  };

  return (
    <div className="app">
      <div className="payment-container">
        {tranchData
          ?.filter(
            (item) =>
              item?.is_tranche_marked || item?.is_tranche_marked_for_recovery
          )
          ?.map((section, index) => (
            <PaymentSection
              key={index}
              {...section}
              handleChange={handleChange}
              paymentRecoveryPresent={paymentRecoveryPresent}
            />
          ))}
        <div className="btn-save-remarks">
          <br></br>
          <button
            type="submit"
            className="btn-primary"
            onClick={() => savePaymentRecovery()}
            disabled={paymentRecoveryPresent}
          >
            Send & Next
          </button>
          {/* <button type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                        <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                    </button> */}
        </div>
      </div>
    </div>
  );
};

export default PaymentRecovery;
