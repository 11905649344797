import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import { executeGraphQLMutation } from '../../../services/refinance/executeGraphQLQuery';
import { UCRF_UPDATE_CI_INVESTIGATION_DATA } from '../../../services/customer.gql';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getUCRFFormFillStatus, getUCRFLeadDetail, getLeadRemarkUCRF, getSavedDocuments } from '../../../store/action/allAction';
import Loader from '../../elements/Loader';
import GeneralService from '../../../services/generalService';
import FormsyInputField from '../elements/FormsyInputField';
import DocumentGallery from '../DocsUpload/DocumentGallery';
import LeadRemarkHistory from '../lead/component/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from '../../../config/constants';
import { PH_REFINANCE_BUCKET_NAME } from '../../../config/refinance_constant';
import { LOAN_DISBURSED, LOST_STATUS } from '../../../config/refinance/constant';

const MAX_DECIMAL = 14;
const RefinanceLoanDisbursementForm = (props) => {
  const [loanDisbursementDetails, setLoanDisbursementDetails] = useState({});
  const [originalDetails, setOriginalDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSaveDisable, setIsSaveDisabled] = useState(true)
  const [isRemarkDisable, setIsRemarkDisabled] = useState(true)
  const [isNextEnable, setIsNextEnabled] = useState(false)
  const { leadDetail, remarksHistory } = useSelector(({ lead, masterdataucrf }) => ({
    leadDetail: lead?.ucrfLeadDetail || {},
    remarksHistory: lead.remarksHistory || [],
  }));
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { lead_id } = useParams();

  const user_information = useSelector((state) => state.user.user_information);
  const UserInfo = JSON.parse(user_information);
  let logged_user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;
  const getContext = useOutletContext();

  const documentCategoryList = getContext.docList || []

  useEffect(() => {
    if (leadDetail?.basic_details) {
      let loanDisbursementDetails = { data_filled: 0 }
      if (leadDetail?.loan_disbursement_data && Object.keys(leadDetail?.loan_disbursement_data).length) {
        loanDisbursementDetails = { ...leadDetail.loan_disbursement_data, data_filled: leadDetail?.loan_disbursement_data?.amount ? 1 : 0 };
      }
      setLoanDisbursementDetails(loanDisbursementDetails);
      setOriginalDetails(loanDisbursementDetails)
      checkSaveEnable(leadDetail)
    }
    dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: 'WEB' }, client));

  }, [leadDetail]);

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setLoanDisbursementDetails(currentValue => ({
        ...currentValue,
        [field_name]: date
      }))
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value !== undefined && field_name) {
      setLoanDisbursementDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleSubmit = () => {
    if (loanDisbursementDetails.data_filled) {
      navigateToNext();
    } else {
      let lead_id = leadDetail?.lead_id || "";

      let reqBody = {
        loan_disbursement_date: loanDisbursementDetails?.loan_disbursement_date || "",
        amount: loanDisbursementDetails?.amount || "",
        attachments: loanDisbursementDetails?.attachments?.length ? loanDisbursementDetails?.attachments : []
      }
      let mutation = UCRF_UPDATE_CI_INVESTIGATION_DATA,
        variables = {
          lead_data: {
            created_by: logged_user_id || 0,
            updated_by: logged_user_id || 0,
            lead_id,
            loan_disbursement_data: reqBody
          },
        };
      setLoading(true)
      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.updateLeadDetails || null;
        if (response && !response.lead_id) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        dispatch(getUCRFLeadDetail(lead_id, client))
        dispatch(getUCRFFormFillStatus({ lead_id }, client))
        dispatch(getSavedDocuments(lead_id, client));
        setLoading(false)
        navigateToNext();
      }).catch(err => {
        setLoading(false)
        toast.error(err.message);
      })
    }
  }

  const navigateToNext = () => {
    navigate(`/refinance/lead-detail/computation-generated/${lead_id}`);
  }

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const handleFileChange = async (event, max_upload) => {
    let selectedImage = event?.target?.files?.[0]?.name || "";
    let publicUrl = '', doc_path = '';
    if (!selectedImage) {
      toast.error("Please upload an image.");
      return;
    }
    const lead_doc_count = loanDisbursementDetails?.attachments?.length || 0
    if (selectedImage) {
      if ((max_upload - lead_doc_count) > 0) {
        const formData = new FormData();
        formData.append('upload_type', PH_REFINANCE_BUCKET_NAME);
        formData.append('lead_id', lead_id);
        formData.append('images', event.target.files[0]);
        setLoading(true)
        try {
          publicUrl = await GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' });
          doc_path = publicUrl && publicUrl.data && publicUrl.data.data && publicUrl.data.data.length && (publicUrl.data.data[0].file_url || '');
        } catch (error) {
          console.error('Error uploading file:', error);
        }
        let loan_disbursement_data = { ...loanDisbursementDetails }
        let docs = Array.isArray(loan_disbursement_data.attachments) ? [...loan_disbursement_data.attachments] : []
        docs.push(doc_path)
        loan_disbursement_data.attachments = docs
        setLoanDisbursementDetails(loan_disbursement_data)
        setLoading(false)
      }
    }
  }

  const checkSaveEnable = (leadDetail) => {
    let markAsFreezed = loanDisbursementDetails.data_filled
    let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status === LOST_STATUS.toString() : false;
    let user_page_access = IS_USER_ACCESS_PAGE("refinanceLoanDisbursement", "ucrf-loan-disbursement")?.is_edit_access
    let user_edit_access = CHECK_ASSIGN_USER(leadDetail.assigned_to)
    let nextEnable = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == LOAN_DISBURSED) > -1 ? true : false
    if (nextEnable) {
      setIsNextEnabled(true)
      setIsSaveDisabled(true)
    } else if (!user_edit_access && user_page_access && !leadLost && !markAsFreezed) {
      setIsSaveDisabled(false)
      setIsNextEnabled(false)
    } else {
      setIsSaveDisabled(true)
      setIsNextEnabled(false)
    }
    if (!user_edit_access && user_page_access) {
      setIsRemarkDisabled(false)
    }
  }

  const sectionInfo = { section: "Loan Disbursement", sub_section: "Loan Disbursement Details" }


  return (
    <div className="image-form-outer" >
      {isLoading ? <Loader /> : ''}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <span className="main-heading">Loan Disbursement</span>
            <h2>Loan Disbursement Details</h2>
          </div>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
            aria-label="lead form field contract signing details"
          >
            <div className="row">
              <fieldset class="form-filed col-md-12">
                <div className="material date-filed">
                  <FormsyDatePicker
                    name="loan_disbursement_date"
                    selectedDate={(loanDisbursementDetails?.["loan_disbursement_date"] && new Date(loanDisbursementDetails["loan_disbursement_date"])) || ""}
                    placeholder={"Loan Disbursement Date"}
                    maxDate={new Date().setMonth(new Date().getMonth() + 2)}
                    minDate={new Date().setMonth(new Date().getMonth() - 12)}
                    value={loanDisbursementDetails.loan_disbursement_date ? dateFormat(loanDisbursementDetails.loan_disbursement_date, 'd mmm,yyyy, h:MM TT') : ""}
                    onDateSelect={(date) => handleDateChange(date, "loan_disbursement_date")}
                    dateFormat="dd MMM, yyyy h:mm aa"
                    showTimeSelect={true}
                    validationError="Enter Valid Loan Disbursement Date"
                    required={true}
                    showAsterisk={true}
                    readOnly={false}
                    disabled={loanDisbursementDetails.data_filled}
                  />
                </div>
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="amount"
                  name="amount"
                  type="number-format"
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  decimalSeparator="."
                  decimalScale={MAX_DECIMAL}
                  value={loanDisbursementDetails?.["amount"]}
                  placeholder=" "
                  label={"Amount"}
                  validations={"ucrfMaxAmountLength"}
                  onChange={(data) => handlePatternFormatChange(data, "amount")}
                  validationError="Enter Valid Amount"
                  readOnly={(originalDetails?.["amount"]) ? true : false}
                  required={true}
                  showAsterisk={true}
                  disabled={loanDisbursementDetails.data_filled}
                />
              </fieldset>
            </div>
            <div className="lead-form-heading">
              <h3><b>Documents Upload</b></h3>
            </div>
            <div className="acco-doc-list">
              <ul>
                {loanDisbursementDetails?.attachments?.length == 0 ?
                  <li>
                    <div className='doc-count-txt'>
                      Disbursement Proof
                      <span className="lead-doc-count">{0} / {5}</span>
                    </div>
                    <div className="more-file-upload doc-upload-btn">
                      <input aria-label="img upload" type="file" name="" accept=".pdf,image/*" disabled={false} onChange={e => handleFileChange(e, 5)} required={false} />
                      <button aria-label="img upload btn" type="button" className="btn-line">
                        <i className='ic-upload'></i> Upload
                      </button>
                    </div>
                  </li>
                  :
                  <li>
                    <div className='doc-count-txt'>
                      {loanDisbursementDetails?.attachments?.length === 5 ? <span className="stepprogress"><i className="sprite ic-check"></i></span> : ""}
                      Disbursement Proof
                      <span className="lead-doc-count">{loanDisbursementDetails?.attachments?.length || 0} / {5}</span>
                    </div>

                    <div className="more-file-upload doc-upload-btn">
                      {loanDisbursementDetails?.attachments?.length === 5 ?
                        <button type="button" className="btn-line" disabled={true}>
                          <i className='ic-upload'></i> Upload
                        </button> :
                        <>
                          <input aria-label="img upload" type="file" name="" accept=".pdf,image/*" disabled={loanDisbursementDetails.attachments?.length === 5 || loanDisbursementDetails.data_filled} onChange={e => handleFileChange(e, 5)} required={false} />
                          <button aria-label="img upload btn" type="button" className="btn-line">
                            <i className='ic-upload'></i> Upload
                          </button>
                        </>
                      }
                    </div>

                  </li>
                }
              </ul>
            </div>
            <div className="btn-save-remarks">
              {(!allowSubmit || isSaveDisable || loanDisbursementDetails.data_filled) ?
                <>{isNextEnable ?
                  <button type="button" className="btn-primary" onClick={() => navigateToNext()}>Next</button>
                  :
                  <span className="span-disabled">
                    Save
                  </span>
                }
                </>
                :
                <button aria-label="save naxt" type="submit" className="btn-primary">
                  Save & Next
                </button>
              }
              <button aria-label="remarks history" type="button" className="btn-line"
                onClick={showModalViewTimeLine}
              >
                Remarks
                <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
              </button>
            </div>
          </Formsy>
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezedNewFlow}
            isRemarkDisable={isRemarkDisable} sectionInfo={sectionInfo} />
        </Modal>
      </div>
    </div>
  );
}
export default RefinanceLoanDisbursementForm;