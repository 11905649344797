import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocsUpload/DocumentGallery';
import FormsyInputField from '../elements/FormsyInputField';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import LeadRemarkHistory from '../lead/component/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { executeGraphQLMutation } from '../../../services/refinance/executeGraphQLQuery';
import { UCRF_UPDATE_CUSTOMER_BASIC_DETAILS } from '../../../services/customer.gql';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getUCRFFormFillStatus, getUCRFLeadDetail, getLeadRemarkUCRF, getSavedDocuments } from '../../../store/action/allAction';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, NAME_WITH_LATIN_CHARACTER_REGEX, SUB_STATUS_CODES } from '../../../config/constants';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import acceptImg from "../../../webroot/images/acceptImg.svg"
import rejectImg from "../../../webroot/images/rejectImg.svg"
import GeneralService from '../../../services/generalService';
import Loader from '../../elements/Loader';
import { CONTRACT_SIGNED, LOST_STATUS, SENT_TO_FINANCIER, SOFT_APPROVAL_RECEIVED } from '../../../config/refinance/constant';
import { PH_REFINANCE_BUCKET_NAME } from '../../../config/refinance_constant';

const RefinanceSoftApprovalForm = (props) => {
  const [softApprovalData, setSoftApprovalData] = useState([]);
  const [basicDetails, setBasicDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isSaveDisable, setIsSaveDisabled] = useState(true)
  const [isRemarkDisable, setIsRemarkDisabled] = useState(true)
  const [isNextEnable, setIsNextEnabled] = useState(false)
  const [isLoading, setLoading] = useState(false);
  const { master_data_ucrf, leadDetail, remarksHistory } = useSelector(({ lead, masterdataucrf }) => ({
    master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
    lead,
    remarksHistory: lead.remarksHistory || [],
    leadDetail: lead?.ucrfLeadDetail || {},
  }));
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { lead_id } = useParams();

  const user_information = useSelector((state) => state.user.user_information);
  const UserInfo = JSON.parse(user_information);
  let logged_user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []
  let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "customer-basic-details")?.is_edit_access;
  if (!accessCondition) {
    accessCondition = CHECK_ASSIGN_USER(leadDetail?.assign_to);
  }

  useEffect(() => {
    if (leadDetail?.basic_details) {
      if (leadDetail?.select_financier?.length) {
        let softApprovalData = leadDetail.soft_approval_status?.length ? [...leadDetail.soft_approval_status] : [];
        if (master_data_ucrf?.financier_list?.length) {
          softApprovalData = leadDetail.select_financier.map((ob) => ({
            financier_id: ob.financier_id,
            attachments: softApprovalData.filter(e => e.financier_id == ob.financier_id).length ? softApprovalData.filter(e => e.financier_id == ob.financier_id)[0]?.attachments : [],
            receive_date: softApprovalData.filter(e => e.financier_id == ob.financier_id).length ? softApprovalData.filter(e => e.financier_id == ob.financier_id)[0]?.receive_date : "",
            reject_reason: softApprovalData.filter(e => e.financier_id == ob.financier_id).length ? softApprovalData.filter(e => e.financier_id == ob.financier_id)[0]?.reject_reason : "",
            soft_approval_status: softApprovalData.filter(e => e.financier_id == ob.financier_id).length ? softApprovalData.filter(e => e.financier_id == ob.financier_id)[0]?.soft_approval_status : 0,
            financier_name: master_data_ucrf.financier_list.filter(e => e.id == ob.financier_id).length ? master_data_ucrf.financier_list.filter(e => e.id == ob.financier_id)[0]?.financier_name : "",
            data_exist: softApprovalData.filter(e => e.financier_id == ob.financier_id).length ? 1 : 0,
          }))
          let data_filled = softApprovalData?.some(e => e.soft_approval_status == 1 || e.soft_approval_status == 2)
          setIsDataChanged(data_filled)
        }
        setSoftApprovalData(softApprovalData);
        checkSaveEnable(leadDetail)
      }
      let basicDetails = { ...leadDetail.basic_details };
      setBasicDetails(basicDetails)
    }
    dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: 'WEB' }, client));

  }, [leadDetail]);

  const handleInputChange = (event, financier_id) => {
    if (event.target.name && financier_id) {
      let updatedSoftApprovalData = softApprovalData?.map((obj) => {
        let obj1 = { ...obj };
        if (obj.financier_id === financier_id) {
          obj1[event.target.name] = event.target.value
        }
        return obj1;
      })
      setSoftApprovalData(updatedSoftApprovalData)
      setIsDataChanged(true)
    }
  }

  // const handlePatternFormatChange = (data, field_name) => {
  //   if (data.value !== undefined && field_name) {
  //     setPersonalDetails(currentValue => ({
  //       ...currentValue,
  //       [field_name]: data.value
  //     }))
  //   }
  // }

  const handleDateChange = (date, field_name, financier_id) => {
    if (date && field_name) {
      let updatedSoftApprovalData = softApprovalData?.map((obj) => {
        let obj1 = { ...obj };
        if (obj.financier_id === financier_id) {
          obj1[field_name] = date
        }
        return obj1;
      })
      setIsDataChanged(true)
      setSoftApprovalData(updatedSoftApprovalData)
    }
  }

  const handleSubmit = () => {
    let lead_id = leadDetail?.lead_id || "";

    let reqBody = [...softApprovalData]
    reqBody = reqBody.map((ob) => ({
      financier_id: ob.financier_id,
      attachments: ob.attachments,
      receive_date: ob.receive_date,
      reject_reason: ob.reject_reason,
      soft_approval_status: ob.soft_approval_status
    }))
    let mutation = UCRF_UPDATE_CUSTOMER_BASIC_DETAILS,
      variables = {
        lead_data: {
          created_by: logged_user_id || 0,
          updated_by: logged_user_id || 0,
          tab_id: 2,
          sub_tab_id: 2,
          lead_id,
          auto_save: 0,
          soft_approval_status: reqBody
        },
      };
    setIsDataChanged(false)
    setLoading(true)
    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.updateLeadDetails || null;
      if (response && !response.lead_id) {
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      dispatch(getUCRFLeadDetail(lead_id, client))
      dispatch(getUCRFFormFillStatus({ lead_id }, client))
      dispatch(getSavedDocuments(lead_id, client));
      setLoading(false)
      navigateToNext();
    })
      .catch(err => {
        setLoading(false)
        toast.error(err.message);
      })
  }

  const navigateToNext = () => {
    navigate(`/refinance/lead-detail/personal-details/${lead_id}`);
  }

  // All the conditions can be combined here for button enable/disable
  const isSubmitDisabled = useMemo(() => {
    return basicDetails.case_sub_status === "Contract Signing" ? true : false;
  }, [basicDetails]);

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const nameWithLatinCharacterValidation = (rule, value) => {
    return NAME_WITH_LATIN_CHARACTER_REGEX.test(value);
  }

  const handleFileChange = async (event, financier_id, max_upload, lead_doc_count) => {
    let selectedImage = event?.target?.files?.[0]?.name || "";
    let publicUrl = '', doc_path = '';
    if (!selectedImage) {
      toast.error("Please upload an image.");
      return;
    }
    if (selectedImage) {
      if ((max_upload - lead_doc_count) > 0) {
        const formData = new FormData();
        formData.append('upload_type', PH_REFINANCE_BUCKET_NAME);
        formData.append('lead_id', lead_id);
        formData.append('images', event.target.files[0]);
        try {
          publicUrl = await GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' });
          doc_path = publicUrl && publicUrl.data && publicUrl.data.data && publicUrl.data.data.length && (publicUrl.data.data[0].file_url || '');
        } catch (error) {
          console.error('Error uploading file:', error);
        }
        let soft_approval_data = [...softApprovalData]
        soft_approval_data = soft_approval_data.map((obj, i) => {
          if (obj.financier_id == financier_id) {
            let docs = Array.isArray(obj.attachments) ? [...obj.attachments] : []
            docs.push(doc_path)
            obj.attachments = docs
          }
          return obj;
        })
        setIsDataChanged(true)
        setSoftApprovalData(soft_approval_data)
      }
    }
  }

  const handleRadioChange = (target_name, e, financier_id) => {
    let softApprovalDataTemp = [...softApprovalData];
    let index = softApprovalDataTemp?.findIndex((sa) => sa.financier_id === financier_id)
    if (index > -1) {
      softApprovalDataTemp[index][target_name] = e.target.value == "yes" ? 1 : 2
    }
    setSoftApprovalData(softApprovalDataTemp);
    setIsDataChanged(true)
  }

  const sectionInfo = { section: "Soft Approval", sub_section: "Soft Approval Status" }

  const checkSaveEnable = (leadDetail) => {
    let markAsFreezed = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == SENT_TO_FINANCIER || e == CONTRACT_SIGNED) > -1 ? true : false
    let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status === LOST_STATUS.toString() : false;
    let user_page_access = IS_USER_ACCESS_PAGE("refinanceSoftApproval", "ucrf-soft-approval-received")?.is_edit_access
    let user_edit_access = CHECK_ASSIGN_USER(leadDetail.assigned_to)
    let data_filled = leadDetail?.soft_approval_status?.some(e => e.soft_approval_status == 1 || e.soft_approval_status == 2)
    let nextEnable = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == SOFT_APPROVAL_RECEIVED) > -1 ? true : false
    if (!user_edit_access && user_page_access && !leadLost && !markAsFreezed) {
      setIsSaveDisabled(false)
      setIsNextEnabled(false)
    } else if (nextEnable) {
      setIsNextEnabled(true)
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(true)
      setIsNextEnabled(false)
    }
    if (!markAsFreezed && !user_edit_access && user_page_access) {
      setIsRemarkDisabled(false)
    }
  }

  let { BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED } = SUB_STATUS_CODES
  const isLeadHistoryExists = leadDetail?.basic_details?.refinance_sub_status > 2;

  return (
    <div className="image-form-outer" >
      {isLoading ? <Loader /> : ''}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className={" switch-toggle"}>
            <div className="lead-form-heading">
              <span className="main-heading">Soft Approval</span>
              <h2>Soft Approval Status</h2>
            </div>
            <Formsy
              className="lead-form-filed soft-approval-filled-outer"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={handleSubmit}
            // aria-label="lead form field soft approval status"
            >
              {softApprovalData?.map((tab) => (

                <Accordion allowZeroExpanded>
                  <div
                    className=""

                  >
                    <AccordionItem className='acc-item-financer'>
                      <AccordionItemHeading className='acc-heading-financer'>
                        <AccordionItemButton>
                          <div className='financer-name-no-date financer-name-date financer-name-no-date'>
                            <img alt='' src={tab['soft_approval_status'] == 1 ? acceptImg : tab['soft_approval_status'] == "2" ? rejectImg : ""} className="check-uncheck-img" />
                            <h3 title={tab.financier_name}>  {(tab?.financier_name || ("Financier Id - " + tab.financier_id))}</h3>
                            {tab.soft_approval_status == 1 && <span className='date-time-txt'>{dateFormat(tab.receive_date, 'd mmm,yyyy')}</span>}
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className='acc-panel-finaner'>
                        <div className="soft-approvel-sec">
                          <div className="row">
                            <fieldset className="form-filed col-md-12 radio-btn radio-type-txt">
                              <label className="form-label">Soft Approval Status</label>
                              <div className="custom-control custom-checkbox" key={"yes"}>
                                <input
                                  id="yes"
                                  aria-label={`for Yes`}
                                  type="radio"
                                  className="custom-control-input"
                                  name={`soft_approval_status_${tab.financier_id}`}
                                  value={"yes"}
                                  checked={tab['soft_approval_status'] == 1}
                                  onChange={(e) => handleRadioChange('soft_approval_status', e, tab.financier_id)}
                                  disabled={tab.data_exist}
                                  required={true}
                                />
                                <label htmlFor={"soft_approval_status"} className="custom-control-label">Yes</label>
                              </div>
                              <div className="custom-control custom-checkbox" key={"no"}>
                                <input
                                  id="no"
                                  aria-label={`for No`}
                                  type="radio"
                                  className="custom-control-input"
                                  name={`soft_approval_status_${tab.financier_id}`}
                                  value={"no"}
                                  checked={tab['soft_approval_status'] == 2}
                                  onChange={(e) => handleRadioChange('soft_approval_status', e, tab.financier_id)}
                                  disabled={tab.data_exist}
                                  required={true}
                                />
                                <label htmlFor={"soft_approval_status"} className="custom-control-label">No</label>
                              </div>
                            </fieldset>
                            {tab.soft_approval_status == 1 &&
                              <fieldset class="form-filed col-md-12">
                                <div className="material date-filed">
                                  <FormsyDatePicker
                                    name="receive_date"
                                    selectedDate={(tab?.["receive_date"] && new Date(tab["receive_date"])) || ""}
                                    placeholder={"Soft Approval Received Date"}
                                    maxDate={new Date()}
                                    minDate={new Date().setMonth(new Date().getMonth() - 12)}
                                    value={tab.receive_date ? dateFormat(tab.receive_date, 'd mmm,yyyy') : ""}
                                    onDateSelect={(date) => handleDateChange(date, "receive_date", tab.financier_id)}
                                    dateFormat="dd MMM, yyyy"
                                    validationError="Enter Valid Soft Approval Received Date"
                                    required={true}
                                    showAsterisk={true}
                                    readOnly={tab.data_exist}
                                  />
                                </div>
                              </fieldset>
                            }
                            {tab.soft_approval_status == 2 &&
                              <fieldset class="form-filed col-md-12">
                                <FormsyInputField
                                  id="reject_reason"
                                  name="reject_reason"
                                  type="tb"
                                  value={tab?.["reject_reason"] || ""}
                                  placeholder=" "
                                  label={"Soft Approval Rejection Reason"}
                                  onChange={(e) => handleInputChange(e, tab.financier_id)}
                                  validations={nameWithLatinCharacterValidation}
                                  validationError="Enter Valid Soft Approval Rejection Reason"
                                  readOnly={(tab.data_exist) ? true : false}
                                  required={true}
                                  showAsterisk={true}
                                />
                              </fieldset>
                            }
                          </div>
                          {tab.soft_approval_status == 1 &&
                            <>
                              <div className="lead-form-heading">
                                <h3><b>Documents Upload</b></h3>
                              </div>
                              <div className="acco-doc-list">
                                <ul>
                                  {tab.attachments.length == 0 ?
                                    <li>
                                      <div className='doc-count-txt'>
                                        Soft Approval Proof
                                        <span className="lead-doc-count">{0} / {5}</span>
                                      </div>
                                      <div className="more-file-upload doc-upload-btn">
                                        <input aria-label="img upload" type="file" name="" accept=".pdf,image/*" disabled={tab.data_exist} onChange={e => handleFileChange(e, tab.financier_id, 5, tab.attachments?.length)} required={false} />
                                        <button aria-label="img upload btn" type="button" className="btn-line">
                                          <i className='ic-upload'></i> Upload
                                        </button>
                                      </div>
                                    </li>
                                    :
                                    <li>
                                      <div className='doc-count-txt'>
                                        {tab.attachments?.length === 5 ? <span className="stepprogress"><i className="sprite ic-check"></i></span> : ""}
                                        Soft Approval Proof
                                        <span className="lead-doc-count">{tab.attachments?.length || 0} / {5}</span>
                                      </div>

                                      <div className="more-file-upload doc-upload-btn">
                                        {tab.attachments?.length === 5 || tab.data_exist ?
                                          <button type="button" className="btn-line" disabled={true}>
                                            <i className='ic-upload'></i> Upload
                                          </button> :
                                          <>
                                            <input aria-label="img upload" type="file" name="" accept=".pdf,image/*" disabled={tab.data_exist || tab.attachments?.length === 5} onChange={e => handleFileChange(e, tab.financier_id, 5, tab.attachments?.length)} required={false} />
                                            <button aria-label="img upload btn" type="button" className="btn-line">
                                              <i className='ic-upload'></i> Upload
                                            </button>
                                          </>
                                        }
                                      </div>

                                    </li>
                                  }

                                </ul>
                              </div>
                            </>
                          }
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </div>
                </Accordion>
              ))}

              <div className="btn-save-remarks">
                {(!allowSubmit || isSaveDisable || !isDataChanged) ?
                  <>{isNextEnable ?
                    <button type="button" className="btn-primary" onClick={() => navigateToNext()}>Next</button>
                    :
                    <span className="span-disabled">
                      Save
                    </span>
                  }
                  </>
                  :
                  <button aria-label="save naxt" type="submit" className="btn-primary">
                    Save & Next
                  </button>
                }
                <button aria-label="remarks history" type="button" className="btn-line"
                  onClick={showModalViewTimeLine}
                >
                  Remarks
                  <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                </button>
              </div>
            </Formsy>
          </div>
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezedNewFlow}
            isRemarkDisable={isRemarkDisable} sectionInfo={sectionInfo} />
        </Modal>
      </div>
    </div>
  );
}
export default RefinanceSoftApprovalForm;