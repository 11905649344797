import { gql } from '@apollo/client';

export const GET_DEALER_DISPUTE_DATA = gql`
  mutation Get_dealer_dispute_data($dealer_id: Float!) {
    get_dealer_dispute_data(api_called_by: web, dealer_id: $dealer_id) {
      total
      disputed_ids
      gmv_recovery_amount
      di_recovery_amount
      label
      dispute_type
      disputed_lead {
        lead_id
        disputed_payment_type {
          id
          lead_id
          payment_type
          amount
          amount_paid
          amount_recovered
          amount_to_be_recovered
          dispute_status
          created_date
        }
      }
    }
  }`;

export const GET_DEALER_DISPUTED_DATA = gql`
  mutation Get_dealer_disputed_data($dealer_id: Float!) {
    get_dealer_disputed_data(api_called_by: web, dealer_id: $dealer_id) {
      label
        disputed_lead {
          lead_id
          disputed_payment_type {
            id
            payment_type
            amount
            amount_paid
            amount_recovered
            amount_to_be_recovered
            dispute_status
          }
        }
    }
  }`;

export const SAVE_PAYMENT_VERIFICATION_ONE = gql`
  mutation SavePaymentVerificationOne(
    $save_payment_verification_one_input: SavePaymentVerificationOneInput!) {
      savePaymentVerificationOne(
        api_called_by: web
        save_payment_verification_one_input: $save_payment_verification_one_input
      ) {
        message
      }
    }`;

export const SAVE_PAYMENT_RECOVERY = gql`
  mutation SavePaymentRecovery(
    $save_payment_recovery_input: SavePaymentRecoveryInput!) {
      savePaymentRecovery(
        api_called_by: web
        save_payment_recovery_input: $save_payment_recovery_input
      ) {
        message
      }
    }`;

export const SAVE_PAYMENT_VERIFICATION_TWO = gql`
  mutation SavePaymentVerificationTwo($save_payment_verification_two_input: SavePaymentVerificationTwoInput!) {
    savePaymentVerificationTwo(
      api_called_by: web
      save_payment_verification_two_input: $save_payment_verification_two_input
    ) {
      message
    }
  }`;

export const UPDATE_PAYMENT_DISPUTE = gql`
  mutation UpdatePaymentDispute($updateDisputeInput: UpdateDisputeInput!, $user_id: Float!, $role_id: Float!) {
    updatePaymentDispute(
      user_id: $user_id
      role_id: $role_id
      updateDisputeInput: $updateDisputeInput
    ) {
        message
    }
}`;