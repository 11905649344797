import React from 'react';

const DisputedPaymentView = (props) => {
  return (
    <div className="dispute-card-outer">
      <div className="card-title">
        <h3>Settle the outstanding disputed amount on this case ??</h3>
        <label className="toggle-switch">
          <input
            type="checkbox"
            checked={props?.isPaidViaRecoverySelected || props?.payViaDisputed || null}
            onChange={() => props?.setPayViaDisputed(!props?.payViaDisputed)}
            disabled={props?.isTranchRequested || props?.isPaidViaRecoverySelected}
          />
          <span className="slider"></span>
        </label>
      </div>
      <div className="card-content">
        {props?.disputeData?.map((item) => (
          <div className="card-list">
            <ul>
              <li className='total-dispute-txt'>
              <label>{item?.total || 0}</label>
              <span>{item?.label || ""}</span>
              </li>
              <li className='gmv-txt'>
              <label>PHP {(item?.gmv_recovery_amount && parseFloat(item?.gmv_recovery_amount).toFixed(2)) || 0}</label>
              <span>GMV Recovery Amount in Progress</span>
              </li>
              <li className='di-recovery-txt'>
              <label>PHP {(item?.di_recovery_amount && parseFloat(item?.di_recovery_amount).toFixed(2)) || 0}</label>
              <span>DI Recovery Amount in Progress</span>
              </li>
            </ul>
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default DisputedPaymentView;
